import React, { useEffect, useState } from 'react'
import { FormGroup, Grid, makeStyles, TableFooter } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CONFIG} from '../config'
import RightBarDataForOtherPages from '../Components/RightBarDataForOtherPages';
import Tooltip from '@mui/material/Tooltip'
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Draggable from 'react-draggable'; 
import { CheckCircleRounded, Close, Delete, Edit, SendOutlined } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import userDetailsPageStyle from '../Css/UserDetailsPageCss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import RightBarDataForUserPages from '../Components/RightBarDataForUserPages';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UserDetailsPage = () => {
  
    const [waitingOpen, setWaitingOpen] = React.useState(false);
    
    const classes = userDetailsPageStyle();
    const Input = styled('input')({
        display: 'none',
    });

    function compareUserDesc( a, b ) {
        var nameA = a.createdDate;
        var nameB = b.createdDate;
        if ( nameA > nameB ){ return -1;}
        if ( nameA < nameB ){ return 1;}
        return 0;
    }

    React.useEffect(() => {
        getData();
    }, []);

    const [golobalCentreId, setGolobalCentreId] = React.useState();
    function getData () {
        let data = sessionStorage.getItem('sessionstorage');
        data = JSON.parse(data);
        setGolobalCentreId(data.centreId); 
        getAllUsers(data.centreId);
        getCentreData(data.centreId);
        getCentreDelartments(data.centreId);
        getEntityData(data.centreId);
        console.log(data)
    }

    const [centreEntityData, setCentreEntityData] = React.useState([]);  
    function getEntityData(centerId){
      var data = '{allEntityList(centreId:\"' + centerId + '\"){ entityDataList{entityName,entityId,isActive,createdBy,lastModifiedBy,centreId,basicEntity{basicEntityId,entityName,iamRole,forceIamRole}}}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          var entityList = response.data.data.allEntityList.entityDataList;
          console.log("All entity List");
          console.log(entityList);
          var tmpEntityData = [];
          entityList.forEach((item, index)=>{
              if (item.isActive && item.centreId === centerId) {  
                  var be = item.basicEntity
                  var arrayData = {}
                  arrayData["entityId"] = item.entityId;
                  arrayData["entityName"] = item.entityName;
                  arrayData["isActive"] = item.isActive;
                  arrayData["createdBy"] = item.createdBy;
                  arrayData["centreId"] = item.centreId;
                  arrayData["basicEntityId"] = item.basicEntity.basicEntityId;
                  arrayData["basicEntityName"] = item.basicEntity.entityName;
                  arrayData["basicIamRole"] = item.basicEntity.iamRole;
                  arrayData["basicForceIamRole"] = item.basicEntity.forceIamRole;
                  tmpEntityData.push(arrayData);
              }
          })
          console.log(tmpEntityData);
          setCentreEntityData(tmpEntityData);
      })
      .catch(function (error) {
          console.log("Entity Loding error" + error);
      });
    }
    
    
    const [centreName, setCentreName] = React.useState('');
    function getCentreData(centreId){
      var data = '{getCentreData(centreId:\"' + centreId + '\"){centreId,centreName}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
      method: 'post',
      headers: { 
          'Content-Type': 'text/plain',
        },
      data : data,  
      })
      .then(function (response) {
          console.log("++++++++++++++++Return centreData++++++++++++++++");
          var centreData = response.data.data.getCentreData;
          console.log(centreData);
          setCentreName(centreData.centreName);
  
      })
      .catch(function (error) {
          console.log("Centre Data Loding error" + error);
      });
    }

    /** Get All Volunteer Users*/
    const [allUserData, setAllUserData] = React.useState([]);  
    function getAllUsers(centerId){
        var data = '{getAllUsers(centreId:\"' + centerId + '\",roleId:\"' + 'volunteer' + '\"){userId,name,middleName,lastName,poBox,addressLineOne,addressLineTwo,addressLineThree,city,state,postalCode,country,email,phoneNumber,mobileNumber,createdDate,isActive,lastLoginDate,centreId,firstLogin,department{departmentId,departmentName,isActive},userRole{roleId,isActive,entity{entityId,entityName}}}}';
        axios(CONFIG.MAIN_URL+'/graphql',{
            method: 'post',
            headers: { 
                'Content-Type': 'text/plain',
            },
            data : data,  
        })
        .then(function (response) {
          console.log("+++++++++++++++++All User List++++++++++++++++++");
          console.log(response);
          var userList = response.data.data.getAllUsers;
            
            console.log(userList);
            userList = userList.sort (compareUserDesc);
            var tmpUserList = [];
            userList.forEach((item, index)=>{
                item["id"] = index + 1;
                var entityStr = '';
                var count = 0;
                item.userRole.forEach((data, index1)=>{
                  if (data.isActive && data.entity != null){
                    if (count === 0){
                      entityStr += data.entity.entityName;
                    }else{
                      entityStr += ', ' + data.entity.entityName;
                    }
                    count ++;
                  }
                })
                item["entityNameStr"] = entityStr;
                count = 0;
                entityStr = '';
                item.department.forEach((data, index1)=>{
                  if (data.isActive){
                    if (count === 0){
                      entityStr += data.departmentName;
                    }else{
                      entityStr += ', ' + data.departmentName;
                    }
                    count ++;
                  }
                })  
                item["departmentStr"] = entityStr; // Should be taken

            })
            setAllUserData(userList);
        })
        .catch(function (error) {
            console.log("Error Loding User List" + error);
        });

    }
    const [allDelartmentData, setAllDelartmentData] = React.useState([]);  
    function getCentreDelartments(centreId){
      var data = '{getDepartments(centreId:\"' + centreId + '\"){departmentId,departmentName,description,departmentLocation,centreId,isActive}}';
      axios(CONFIG.MAIN_URL+'/graphql',{
          method: 'post',
          headers: { 
              'Content-Type': 'text/plain',
          },
          data : data,  
      })
      .then(function (response) {
        console.log("+++++++++++++++++All Delartments in a centre++++++++++++++++++");
        console.log(response);
        var deptList = response.data.data.getDepartments;
        setAllDelartmentData(deptList);
      })
      .catch(function (error) {
          console.log("Error Loding Departments" + error);
      });

    }

    const renderTextId = (params) => {
      return (
          <Tooltip title={params.row.id}>
          <span className={classes.fontZise10}>{params.row.id}</span>        
          </Tooltip>
      )
    }
    const renderTextName = (params) => {
      return (
          <Tooltip title={params.row.name}>
          <span className={classes.fontZise10}>{params.row.name}</span>        
          </Tooltip>
      )
    }
    const renderTextLastName = (params) => {
      return (
          <Tooltip title={params.row.lastName}>
          <span className={classes.fontZise10}>{params.row.lastName}</span>        
          </Tooltip>
      )
    }
    const renderTextPoBox = (params) => {
      return (
          <Tooltip title={params.row.postalCode}>
          <span className={classes.fontZise10}>{params.row.postalCode}</span>        
          </Tooltip>
      )
    } 
    const renderTextPhoneNumber = (params) => {
      return (
          <Tooltip title={params.row.phoneNumber}>
          <span className={classes.fontZise10}>{params.row.phoneNumber}</span>        
          </Tooltip>
      )
    }
    const renderTextDepartmentStr = (params) => {
      return (
          <Tooltip title={params.row.departmentStr}>
          <span className={classes.fontZise10}>{params.row.departmentStr}</span>        
          </Tooltip>
      )
    }
    const renderTextEntityNameStr = (params) => {
      return (
          <Tooltip title={params.row.entityNameStr}>
          <span className={classes.fontZise10}>{params.row.entityNameStr}</span>        
          </Tooltip>
      )
    }
    const renderTextFirstLogin = (params) => {
      return (
          <Tooltip title={params.row.firstLogin}>
          <span className={classes.fontZise10}>{params.row.firstLogin}</span>        
          </Tooltip>
      )
    }  

    const renderEditUserButton = (params) => {
      return (
          <>
          <strong>
              <Button
                  id={'btn'+params.row.id}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.fontZise9AndBlue}
                  onClick={() => {
                      setShowUserData(params.row.id, params.row.userId)
                  }}
              >
                  <Edit/>
              </Button>
          </strong>

          </>
        )
    }
    function setActiveDeactive(no, userId, activeStatus,name){
      var activeStr = '';
      var updateFlag = false;
      if (activeStatus){
        activeStr = 'Deactivating'
        updateFlag = false;
      }else{
        activeStr = 'Activating'
        updateFlag = true;
      }
      let text = activeStr + " \"" + name + "\"";
      text += "\n\nPress 'OK' to confirm.";
      if (window.confirm(text) == true) {
        let formdata = new FormData();
        allUserData.forEach((item)=>{
          if (item.userId === userId){
            formdata.append('userId',item.userId);
            formdata.append('schoolId',item.centreId);
            formdata.append('name',item.name);
            formdata.append('middleName',item.middleName);
            formdata.append('lastName',item.lastName);
            formdata.append('addressLineOne',item.addressLineOne);
            formdata.append('addressLineTwo',item.addressLineTwo);
            formdata.append('addressLineThree',item.addressLineThree);
            formdata.append('city',item.city);
            formdata.append('postalCode',item.postalCode);
            formdata.append('country',item.country);
            formdata.append('email',item.email);
            formdata.append('phoneNumber',item.phoneNumber);
            formdata.append('isActive',updateFlag);
          }
        })
        setWaitingOpen(true);
        axios(CONFIG.SERVICES_SYSTEM_URL+'/updateuserprofile',{
          method: 'post',
          contentType: false,
          data: formdata,
        })
        .then(function (response) {
          setWaitingOpen(false);
          console.log(response.data);
          var msg = response.data;
          alert(activeStr + ' Success');
          getAllUsers(golobalCentreId);
        })
        .catch(function (error) {
          setWaitingOpen(false);
          alert(error);
          console.log(error + "GGGGGGGGGGGGGGGGGGGGG");
        })
     }



    }
    const renderActiveUserButton = (params) => {
      return (
          <>
          {params.row.isActive?
            <strong>
              <Button
                  id={'btn'+params.row.id}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.fontZise9AndGreen}
                  onClick={() => {
                      setActiveDeactive(params.row.id, params.row.userId,params.row.isActive,params.row.name)
                  }}
              >
                  <CheckCircleRounded/>
              </Button>
            </strong>
          :
            <strong>
              <Button
                  id={'btn'+params.row.id}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.fontZise9AndRed}
                  onClick={() => {
                    setActiveDeactive(params.row.id, params.row.userId,params.row.isActive,params.row.name)
                  }}
              >
                  <Delete/>
              </Button>
            </strong>
          }

          </>
        )
    }


    const columns = [
      { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 10, hide: true },
      { field: 'userId', headerName: 'userId', width: 15, hide: true },
      { field: 'isActive', headerName: 'isActive', width: 15, hide: true },
      { field: 'name', headerName: 'First name', renderCell: renderTextName, resizable: true, cellClassName: 'super-app-theme--cell', width: 140 },
      { field: 'lastName', headerName: 'Last Name', renderCell: renderTextLastName, resizable: true, cellClassName: 'super-app-theme--cell', width: 140 },
      { field: 'postalCode', headerName: 'Post Code', renderCell: renderTextPoBox, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      { field: 'phoneNumber', headerName: 'Mobile', renderCell: renderTextPhoneNumber, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      { field: 'departmentStr', headerName: 'Department', renderCell: renderTextDepartmentStr, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      { field: 'entityNameStr', headerName: 'Registered As', renderCell: renderTextEntityNameStr, resizable: true, cellClassName: 'super-app-theme--cell', width: 160 },
      { field: 'firstLogin', headerName: 'First Login', renderCell: renderTextFirstLogin, resizable: true, cellClassName: 'super-app-theme--cell', width: 120 },
      {
        field: 'action',
        headerName: 'Edit',
        sortable: false,
        renderCell: renderEditUserButton,
        disableClickEventBubbling: true,
        width: 100,
      },
      {
        field: 'action1',
        headerName: 'Active Status',
        sortable: false,
        renderCell: renderActiveUserButton,
        disableClickEventBubbling: true,
        width: 100,
      }

    
    ];

    /*******Edit User Data Section************/
    const [openUserData, setOpenUserData] = useState(false);
    const [openUserDataTopPos, setOpenUserDataTopPos] = useState(0);
    
    const [uAddressLineOne, setUAddressLineOne] = useState('');
    const [uAddressLineThree, setUAddressLineThree] = useState('');
    const [uAddressLineTwo, setUAddressLineTwo] = useState('');
    const [uCentreId, setUCentreId] = useState('');
    const [uCity, setUCity] = useState('');
    const [uCountry, setUCountry] = useState('');
    const [uCreatedDate, setUCreatedDate] = useState('');
    const [uEmail, setUEmail] = useState('');
    const [uFirstLogin, setUFirstLogin] = useState('');
    const [uIsActive, setUIsActive] = useState('');
    const [uLastLoginDate, setULastLoginDate] = useState('');
    const [uLastName, setULastName] = useState('');
    const [uMiddleName, setUMiddleName] = useState('');
    const [uMobileNumber, setUMobileNumber] = useState('');
    const [uName, setUName] = useState('');
    const [uPhoneNumber, setUPhoneNumber] = useState('');
    const [uPoBox, setUPoBox] = useState('');
    const [uPostalCode, setUPostalCode] = useState('');
    const [uState, setUState] = useState('');
    const [uUserId, setUUserId] = useState('');
    
    const handleUAddressLineOne = (event) => {
          setUAddressLineOne(event.target.value);
    };
    const handleUAddressLineThree = (event) => {
          setUAddressLineThree(event.target.value);
    };
    const handleUAddressLineTwo = (event) => {
          setUAddressLineTwo(event.target.value);
    };
    const handleUCentreId = (event) => {
          setUCentreId(event.target.value);
    };
    const handleUCity = (event) => {
          setUCity(event.target.value);
    };
    const handleUCountry = (event) => {
          setUCountry(event.target.value);
    };
    const handleUCreatedDate = (event) => {
          setUCreatedDate(event.target.value);
    };
    const handleUEmail = (event) => {
          setUEmail(event.target.value);
    };
    const handleUFirstLogin = (event) => {
          setUFirstLogin(event.target.value);
    };
    const handleUIsActive = (event) => {
          setUIsActive(event.target.value);
    };
    const handleULastLoginDate = (event) => {
          setULastLoginDate(event.target.value);
    };
    const handleULastName = (event) => {
          setULastName(event.target.value);
    };
    const handleUMiddleName = (event) => {
          setUMiddleName(event.target.value);
    };
    const handleUMobileNumber = (event) => {
          setUMobileNumber(event.target.value);
    };
    const handleUName = (event) => {
          setUName(event.target.value);
    };
    const handleUPhoneNumber = (event) => {
          setUPhoneNumber(event.target.value);
    };
    const handleUPoBox = (event) => {
          setUPoBox(event.target.value);
    };
    const handleUPostalCode = (event) => {
          setUPostalCode(event.target.value);
    };
    const handleUState = (event) => {
          setUState(event.target.value);
    };
    const handleUUserId = (event) => {
          setUUserId(event.target.value);
    };
    

    function setShowUserData(no, userId){
        var btn = document.getElementById("btn"+no);
        var posBtn = btn.offsetTop;
        var tableEndId = document.getElementById("tableEndId");
        var pos = tableEndId.offsetTop;
        var userDataPos = ((posBtn + 215) - pos); 
        setOpenUserDataTopPos(userDataPos);
        allUserData.forEach((item)=>{
          if (item.userId === userId){
            console.log(item);
            setUAddressLineOne(item.addressLineOne);
            setUAddressLineThree(item.addressLineThree);
            setUAddressLineTwo(item.addressLineTwo);
            setUCentreId(item.centreId);
            setUCity(item.city);
            setUCountry(item.country);
            setUCreatedDate(item.createdDate);
            setUEmail(item.email);
            setUFirstLogin(item.firstLogin);
            setUIsActive(item.isActive);
            setULastLoginDate(item.lastLoginDate);
            setULastName(item.lastName);
            setUMiddleName(item.middleName);
            setUMobileNumber(item.mobileNumber);
            setUName(item.name);
            setUPhoneNumber(item.phoneNumber);
            setUPoBox(item.poBox);
            setUPostalCode(item.postalCode);
            setUState(item.state);
            setUUserId(item.userId);
          }
        })
        setOpenUserData(true);
    }
    
    
    function updateFunction() {
      if (uCentreId === "") {
          alert("Please select Centre ID");
          return;
      }
      if (uName === "") {
          alert('Empty  Name \nPlease enter your name');
          return;
      }
      if (uPostalCode === "") {
          alert('Empty postal code\nPlease enter postal code');
          return;
      }
  
      if (uPhoneNumber === "") {
          alert('Empty Phone Number\nPlease enter phone number');
          return;
      }
  
      if(uEmail === ""){
          alert('Empty Email\nEnter your email address');
          return;
      }
  
      if (uEmail != "") {
          var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if (!regex.test(uEmail)) {
              alert('Email is Invalid\nPlease enter a valid email address');
              return;
          }
      }

      var duplicatePhone = false;
      allUserData.forEach((item)=>{
        if (item.userId != uUserId){
          if (item.phoneNumber === uPhoneNumber){
            duplicatePhone = true;
          }
        }
      })    

      if(duplicatePhone){
        alert('Duplicate Mobile Number\nPlease enter the different mobile no');
        return;
      }
      var userData = {};
      /*
      allUserData.forEach((item)=>{
        if (item.userId === uUserId){
          userData["userId"] = uUserId;
          userData["schoolId"] = uCentreId;
          userData["name"] = uName;
          userData["middleName"] = uMiddleName;
          userData["lastName"] = uLastName;
          userData["addressLineOne"] = uAddressLineOne;
          userData["addressLineTwo"] = uAddressLineTwo;
          userData["addressLineThree"] = uAddressLineThree;
          userData["city"] = uCity;
          userData["postalCode"] = uPostalCode;
          userData["country"] = uCountry;
          userData["email"] = uEmail;
          userData["phoneNumber"] = uPhoneNumber;
          userData["createdDate"] = item.createdDate;
          userData["isActive"] = true;
        }
      })
      */
      let formdata = new FormData();
      allUserData.forEach((item)=>{
        if (item.userId === uUserId){
          formdata.append('userId',uUserId);
          formdata.append('schoolId',uCentreId);
          formdata.append('name',uName);
          formdata.append('middleName',uMiddleName);
          formdata.append('lastName',uLastName);
          formdata.append('addressLineOne',uAddressLineOne);
          formdata.append('addressLineTwo',uAddressLineTwo);
          formdata.append('addressLineThree',uAddressLineThree);
          formdata.append('city',uCity);
          formdata.append('postalCode',uPostalCode);
          formdata.append('country',uCountry);
          formdata.append('email',uEmail);
          formdata.append('phoneNumber',uPhoneNumber);
          //formdata.append('createdDate',item.createdDate);
          formdata.append('isActive',true);
        }
      })

      var sendJson = JSON.stringify(userData);
      console.log(sendJson);
      setWaitingOpen(true);
      axios(CONFIG.SERVICES_SYSTEM_URL+'/updateuserprofile',{
        method: 'post',
        contentType: false,
        data: formdata,
      })
      .then(function (response) {
        setWaitingOpen(false);
        console.log(response.data);
        var msg = response.data;
        if (msg == "This user is Already Exist") {
          alert('Error\nThis user is Already Exist');
        } else {
          alert('Save Success');
        }
        getAllUsers(golobalCentreId);
        
      })
      .catch(function (error) {
        setWaitingOpen(false);
        alert(error);
        console.log(error + "GGGGGGGGGGGGGGGGGGGGG");
      })
      
      
  }
  function clearFunction(){
    setUAddressLineOne('');
    setUAddressLineThree('');
    setUAddressLineTwo('');
    setUCity('');
    setUCountry('');
    setUEmail('');
    setULastName('');
    setUMiddleName('');
    setUMobileNumber('');
    setUName('');
    setUPhoneNumber('');
    setUPostalCode('');
  }
  /**Contact Type Protocole */
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  const [contactInfoOpen, setContactInfoOpen] = React.useState(false);
  const [contactInfoScroll, setContactInfoScroll] = React.useState('paper');

  const contactInfoHandleClickClose = () => {
    setContactInfoOpen(false);
  };

  const [cpEmailType, setcpEmailType] = useState(true);
  const [protocolType, setProtocolType] = useState('');
  const [cpEmail, setcpEmail] = useState('');
  const [cpMobile, setcpMobile] = useState('');
  const [cpFormatType, setcpFormatType] = useState('');
  const [cpLableName, setcpLableName] = useState('Email');

  const protocolChange = (event) => {
    setProtocolType(event.target.value);
    if (event.target.value === 'emailCon'){
      setcpLableName('Email');
    }else{
      setcpLableName('SMS Number');
    }  
  };  
    
  const handlecpEmail = (event) => {
    setcpEmail(event.target.value);
  };
  const handlecpMobile = (event) => {
    setcpMobile(event.target.value);
  };
  const handlecpFormatType = (event) => {
    setcpFormatType(event.target.value);
  };
  
  function contactTypeFuction(){
    setWaitingOpen(true);
    var data = '{getUserInformation(userId:\"' + uUserId + '\"){userId,email,mobileNumber,formatType}}';
    console.log(data);
    axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
        },
        data : data,  
    })
    .then(function (response) {
      console.log("+++++++++++++++++User Infor Return++++++++++++++++++");
      console.log(response);
      setWaitingOpen(false);
      var rtnData = response.data.data.getUserInformation;
      var userId = rtnData.userId;
      var email = rtnData.email;
      var mobileNumber = rtnData.mobileNumber;
      var formatType = rtnData.formatType;
      var anyOption = false;
      if (email != null && email != '' && email != 'null'){
        setcpEmail(email);
        setcpFormatType(formatType);
        setContactInfoOpen(true);
        setProtocolType('emailCon');
        setcpLableName('Email');
        anyOption = true;
      }
      if (mobileNumber != null && mobileNumber != '' && mobileNumber != 'null'){
        setcpMobile(mobileNumber);
        setcpFormatType('');
        setProtocolType('mobileNumber');
        setcpLableName('SMS Number');
        anyOption = false
      }
      setContactInfoOpen(true);
      setContactInfoScroll('paper');
      if (anyOption){
        setTimeout(() => 
          setProtocolType('emailCon')
        ,500)
      }

    })
    .catch(function (error) {
      setWaitingOpen(false);
      console.log("Error Loding User Info " + error);
    });

  }   

  function saveContactPointFunction(){
    var userId = uUserId;
    var saveOk = true;
    if (protocolType === ''){
      alert("Empty Communication Protocol\nPlease select the Communication Protocol");
      return;
    }
    if (protocolType === 'mobileNumber'){
      if (cpMobile === ''){
        alert("Empty mobile number\nPlease enter a mobile number");
        return;
      }
    }
    if (protocolType === 'emailCon'){
      if (cpEmail === ''){
        alert("Empty Email\nPlease Enter an  Email address");
        return;
      }
    }
    if (cpFormatType === '') {
      alert("Empty format type\nPlease select the format type");
      return;
    }
    var mobileNo = null;
	  var emailAdd = null;
	  if (protocolType == "emailCon") {
		  emailAdd = cpEmail;
	  }
    if (protocolType == "mobileNumber") {
		  mobileNo = cpMobile;
	  }
    let formdata = new FormData();
    formdata.append('email',emailAdd);
    formdata.append('mobileNumber',mobileNo);
    formdata.append('formatType',cpFormatType);
    formdata.append('userId',uUserId);

    setWaitingOpen(true);
    axios(CONFIG.SERVICES_SYSTEM_URL+'/saveuserendpoint',{
      method: 'post',
      contentType: false,
      data: formdata,
    })
    .then(function (response) {
      setWaitingOpen(false);
      console.log(response.data);
      var msg = response.data;
      alert('Protocol Save Status');
      setContactInfoOpen(false);
    })
    .catch(function (error) {
      setWaitingOpen(false);
      alert(error);
      console.log(error);
    })
  }

  /**** User depatments select */
  const [userDepartmentOpen, setUserDepartmentOpen] = React.useState(false);
  const [userDepartmentScroll, setUserDepartmentScroll] = React.useState('paper');

  const userDepartmentHandleClickClose = () => {
    setUserDepartmentOpen(false);
  };


  const [allDepartmentWithUserData, setAllDepartmentWithUserData] = React.useState([]);
  const [userDepartmentData, setUserDepartmentData] = React.useState([]);
  function userDepartmentFunction(){
    var data = '{getDepartments(userId:\"' + uUserId + '\"){departmentId,departmentName,description,departmentLocation,centreId,isActive}}';
    axios(CONFIG.MAIN_URL+'/graphql',{
        method: 'post',
        headers: { 
            'Content-Type': 'text/plain',
        },
        data : data,  
    })
    .then(function (response) {
      console.log("+++++++++++++++++Get Delartments in a User++++++++++++++++++");
      console.log(response);
      var deptList = response.data.data.getDepartments;
      setUserDepartmentData(deptList);
      var arr = [];
      allDelartmentData.forEach((item)=>{
        var exits = false;
        deptList.forEach((data)=>{
          if (data.departmentId === item.departmentId){
            exits = true;
            item["userDepatment"] = true;
          }
        })
        if (!exits){
          item["userDepatment"] = false;
        }
        arr.push(item);
      })
      console.log(arr);
      setAllDepartmentWithUserData(arr);
      setUserDepartmentOpen(true);
      setUserDepartmentScroll('paper');
      
      setTimeout(() => 
          arr.forEach((data)=>{
            if (data.userDepatment){
              document.getElementById("chk"+ data.departmentId).checked = true
            }
          })
      ,500)
  
    })
    .catch(function (error) {
        console.log("Error Loding Departments" + error);
    });
  }

  function saveUserDepartmentFunction(){
    var userId = uUserId
    var departmentAll = '';
    allDepartmentWithUserData.forEach((data)=>{
      var ans = document.getElementById("chk"+ data.departmentId).checked;
      if (ans){
        departmentAll += data.departmentId + '_';
      }  
    })  
    departmentAll = departmentAll.replace(/_([^_]*)$/, '$1')
    
    let formdata = new FormData();
    formdata.append('userId',uUserId);
    formdata.append('departmentIdList',departmentAll);

    setWaitingOpen(true);
    axios(CONFIG.SERVICES_SYSTEM_URL+'/savedepartment',{
      method: 'post',
      contentType: false,
      data: formdata,
    })
    .then(function (response) {
      setWaitingOpen(false);
      console.log(response.data);
      var msg = response.data;
      alert('Save Successful');
      setUserDepartmentOpen(false);
      getAllUsers(golobalCentreId);
    })
    .catch(function (error) {
      setWaitingOpen(false);
      alert(error);
      console.log(error);
    })

    
  }

  /*** entityUpdates */

  const [entityUpdateOpen, setEntityUpdateOpen] = React.useState(false);
  const [entityUpdateScroll, setEntityUpdateScroll] = React.useState('paper');

  const entityUpdateHandleClickClose = () => {
    setEntityUpdateOpen(false);
  };

  
  const [allEntityWithUserEntity, setAllEntityWithUserEntity] = React.useState([]);
  
  function entityUpdatesFunction(){
    var userEntityArr = [];
    
    allUserData.forEach((data)=>{
      if (data.userId === uUserId){
        console.log(data);
        data.userRole.forEach((item, index1)=>{
          var name = item.entity.entityName;
          var id = item.entity.entityId; 
          var obj = {};
          obj["entityId"] = id;
          obj["entityName"] = name; 
          obj["isActive"] = item.isActive;
          userEntityArr.push(obj);
        })           
      }
    })
    var arr = [];
    console.log("++++++++++++++++++centreEntityData+++++++++++++++++++");
    console.log(centreEntityData);
    centreEntityData.forEach((data)=>{
      var exits = false;
      userEntityArr.forEach((item)=>{
        if (data.entityId === item.entityId){
          exits = true;
          data["userEntity"] = true;
          data["userEntityActive"] = item.isActive;
        }
      })
      if (!exits){
        data["userEntity"] = false;
      }
      var canTake = true;
      if ((data.basicIamRole === "ADMIN" && data.basicForceIamRole === "ADMIN") || (data.basicIamRole === "supplier" && data.basicForceIamRole === "supplier") || (data.basicIamRole === "serviceprovider" && data.basicForceIamRole === "serviceprovider")){
        canTake = false;
      }
      if (canTake) {
        arr.push(data);
      } 

    })
    setAllEntityWithUserEntity(arr);
    console.log("++++++++++++++++++++++arr++++++++++++++++++++++++++++++");
    console.log(arr);
    setEntityUpdateOpen(true);
    setEntityUpdateScroll('paper');
    setTimeout(() => 
      arr.forEach((data)=>{
        if (data.userEntity && data.userEntityActive){
          document.getElementById("chk"+ data.entityId).checked = true
        }
      })
    ,500)
  }


  function saveUserEntityFunction(){
    var isSelected=false;
    var userId = uUserId;
    var entityRoleAll="";
    var entityRoleStr = "{";
    allEntityWithUserEntity.forEach((data)=>{
      var ans = document.getElementById("chk"+ data.entityId).checked;
      if (ans){
        entityRoleAll+="\""+data.entityId+"\""+','; 
        isSelected=true;
      }  
    })
    if (!isSelected){
      alert("Empty registration Type\nPlease select at least one registration type");
      return;
    }
    entityRoleAll = entityRoleAll.replace(/,\s*$/, "");
    entityRoleStr += "\""+"entityRole"+"\""+":" + "[" + entityRoleAll + "]";
    entityRoleStr += "}";
    console.log(entityRoleStr);

    let formdata = new FormData();
    formdata.append('userId',uUserId);
    formdata.append('entityRole',entityRoleStr);
    formdata.append('isApproved',true);
    formdata.append('isActive',true);
    
    setWaitingOpen(true);
    axios(CONFIG.SERVICES_SYSTEM_URL+'/saveuserrole',{
      method: 'post',
      contentType: false,
      data: formdata,
    })
    .then(function (response) {
      setWaitingOpen(false);
      console.log(response.data);
      var msg = response.data;
      console.log("+++++++++++++++++++msg+++++++++++++++++++++");
      console.log(msg);
      if (msg === "Success") {
        alert('Save Successful');
        setEntityUpdateOpen(false);
      } else {
        alert('Save Not Successful');
      }
      getAllUsers(golobalCentreId);

    })
    .catch(function (error) {
      setWaitingOpen(false);
      alert(error);
      console.log(error);
    })
    
  }



const [PageName, setPageName] = useState('Show and Edit User Details');
  return (
    <>
        <RightBarDataForUserPages PageName={PageName} CentreName={centreName}/>
        <div className={classes.pageTop}>
            <Grid container spacing={2}>
                <Grid container spacing={2} className={classes.pageBodyStyle}>

                    <Grid item xs={12}>
                    <div role="presentation" onClick={handleClick}>
                      <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/login">
                          Home
                        </Link>
                        <Typography color="text.primary">User Details</Typography>
                      </Breadcrumbs>
                    </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Box    
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    '& .super-app-theme--cell': {
                                    whiteSpace: 'normal !important',
                                    lineHeight: '1',
                                    },
                                    '& .super-app-theme--Open': {
                                        fontSize: '10px !important',
                                        backgroundColor: 'red !important'
                                    }, 
                                }}
                                >
                                <div style={{ height: 400, width: '100%', fontSize: '9px !important' }}>
                                <DataGrid
                                    rows={allUserData}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowClassName={`super-app-theme--Open`}
                                    disableSelectionOnClick
                                />
                                </div>

                            </Box>
                        </Grid>
                    </Grid> 
                    <Grid item xs={12} id="tableEndId">
                    </Grid>

                </Grid>
            </Grid>  
        </div>

        {
            openUserData?
              <Draggable>
                <Grid item xs={12} style={{marginLeft: '-330px', marginTop: + `${openUserDataTopPos}`+ 'px'}}>
                  <Card className={classes.cardDivUserData}>
                    <Typography variant="h6" style={{textAlign:'right', marginTop:'-5px', marginRight: '25px'}}><Tooltip title="Close"><Close onClick={()=>setOpenUserData(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.cardTitleDB}></Typography>
                        </Grid>  
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="First Name"
                                        value={uName}
                                        onChange={handleUName}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userMiddleName"
                                        label="Middle Name"
                                        value={uMiddleName}
                                        onChange={handleUMiddleName}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userLastName"
                                        label="Last Name"
                                        value={uLastName}
                                        onChange={handleULastName}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Address Line one"
                                        value={uAddressLineOne}
                                        onChange={handleUAddressLineOne}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Address Line Two"
                                        value={uAddressLineTwo}
                                        onChange={handleUAddressLineTwo}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Address Line Three"
                                        value={uAddressLineThree}
                                        onChange={handleUAddressLineThree}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="City"
                                        value={uCity}
                                        onChange={handleUCity}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Postal Code"
                                        value={uPostalCode}
                                        onChange={handleUPostalCode}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Country"
                                        value={uCountry}
                                        onChange={handleUCountry}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Email Address"
                                        value={uEmail}
                                        onChange={handleUEmail}
                                    />
                                </div>
                              </Box>
                            </Grid>  
                            <Grid item xs={3}>
                              <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                                <div>
                                    <TextField
                                        id="userFirstName"
                                        label="Mobile Number"
                                        value={uPhoneNumber}
                                        onChange={handleUPhoneNumber}
                                    />
                                </div>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Button 
                                  variant="contained" 
                                  //className={classes.btnBorderGreenEntityMap}
                                  onClick={() => updateFunction()} 
                                  style={{marginTop: '17px'}}
                                  >
                                  Update
                              </Button>&nbsp;
                              <Button 
                                  variant="contained" 
                                  //className={classes.btnBorderGreenEntityMap}
                                  onClick={() => clearFunction()} 
                                  style={{marginTop: '17px'}}
                                  >
                                  Clear
                              </Button>

                            </Grid>

                            <Grid item xs={5}>
                              <Typography variant="h6" className={classes.cardTitleUserData}>
                                {'If you want to change Contact Point & Protocol'}
                              </Typography>
                            </Grid>  
                            <Grid item xs={3}>
                              <Button 
                                  variant="contained" 
                                  //className={classes.btnBorderGreenEntityMap}
                                  onClick={() => contactTypeFuction()} 
                                  //style={{float:'right'}}
                                  >
                                  Please Click Here
                              </Button>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant="h6" className={classes.cardTitleUserData}>
                                {'If you want to change Department'}
                              </Typography>
                            </Grid>  
                            <Grid item xs={3}>
                              <Button 
                                  variant="contained" 
                                  //className={classes.btnBorderGreenEntityMap}
                                  onClick={() => userDepartmentFunction()} 
                                  //style={{float:'right'}}
                                  >
                                  Please Click Here
                              </Button>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant="h6" className={classes.cardTitleUserData}>
                                {'If you want to change the Registration Type'}
                              </Typography>
                            </Grid>  
                            <Grid item xs={3}>
                              <Button 
                                  variant="contained" 
                                  //className={classes.btnBorderGreenEntityMap}
                                  onClick={() => entityUpdatesFunction()} 
                                  //style={{float:'right'}}
                                  >
                                  Please Click Here
                              </Button>
                            </Grid>


                          </Grid>
                        </Grid>

                      </Grid> 
                    </CardContent>
                  </Card>
                </Grid>
              </Draggable>
            :null
        }

        {/************************ */}

        <Dialog
          maxWidth='md'
          fullWidth={true}
          open={contactInfoOpen}
          onClose={contactInfoHandleClickClose}
          scroll={contactInfoScroll}
          //PaperComponent={PaperComponent}
          //aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle>
            <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setContactInfoOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
            <Grid container spacing={1}>                    
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                  {'Contact Point & Protocol'} 
                </Typography>
              </Grid>                  
            </Grid>    
          </DialogTitle>
          <DialogContent dividers={contactInfoScroll === 'paper'}>
            <DialogContentText id="risk-eval-type-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Box sx={{ minWidth: 120}}>    
                    <FormControl fullWidth>
                      <TextField
                          label="Communication Protocol"
                          sx={{ width: '100%' }}
                          select
                          value={protocolType}
                          defaultValue={'emailCon'}
                          onChange={protocolChange}
                          >
                          <MenuItem value={'mobileNumber'}>Common.SMS</MenuItem>
                          <MenuItem value={'emailCon'}>Email</MenuItem>
                      </TextField>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box component="form" sx={{'& .MuiTextField-root': { width: '100%' },}} noValidate autoComplete="off">
                    <div>
                      <TextField
                          id="email"
                          label={cpLableName}
                          value={cpEmail}
                          onChange={handlecpEmail}
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ minWidth: 120}}>    
                    <FormControl fullWidth>
                      <TextField
                          label="Format"
                          sx={{ width: '100%' }}
                          select
                          defaultValue={cpFormatType}
                          onChange={handlecpFormatType}
                          >
                          <MenuItem value={'Text'}>Text</MenuItem>
                          <MenuItem value={'attachment'}>Attachment</MenuItem>
                      </TextField>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                      variant="contained" 
                      //className={classes.btnBorderGreenEntityMap}
                      onClick={() => saveContactPointFunction()} 
                      style={{float: 'right'}}
                      >
                      Update
                  </Button>
                </Grid>  


              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={contactInfoHandleClickClose}>Close</Button>
          </DialogActions>
        </Dialog>
      {/** End of Contact Infor*/}

        {/************************ */}

        <Dialog
          maxWidth='md'
          fullWidth={true}
          open={userDepartmentOpen}
          onClose={userDepartmentHandleClickClose}
          scroll={userDepartmentScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setUserDepartmentOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
            <Grid container spacing={1}>                    
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                  {'Department'} 
                </Typography>
              </Grid>                  
            </Grid>    
          </DialogTitle>
          <DialogContent dividers={contactInfoScroll === 'paper'}>
            <DialogContentText id="risk-eval-type-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                    {allDepartmentWithUserData.map((row,index) => (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{width: '15%'}}>
                            <Typography sx={{ fontSize: '1.1rem !important' }}>{row.departmentName}</Typography>
                          </TableCell>
                          <TableCell component="th" scope="row" style={{width: '85%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                            <input type="checkbox" id={"chk"+ row.departmentId} />
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                      </TableBody>
                    </Table>

 
                  

                </Grid>
                <Grid item xs={12}>
                  <Button 
                      variant="contained" 
                      //className={classes.btnBorderGreenEntityMap}
                      onClick={() => saveUserDepartmentFunction()} 
                      style={{float: 'right'}}
                      >
                      Update
                  </Button>
                </Grid>  


              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={userDepartmentHandleClickClose}>Close</Button>
          </DialogActions>
        </Dialog>
      {/** End of Contact Infor*/}



        {/************************ */}

        <Dialog
          maxWidth='md'
          fullWidth={true}
          open={entityUpdateOpen}
          onClose={entityUpdateHandleClickClose}
          scroll={entityUpdateScroll}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Typography variant="h6" style={{textAlign:'right', marginTop:'-22px', marginRight: '-24px'}}><Tooltip title="Close"><Close onClick={()=>setEntityUpdateOpen(false)} style={{cursor: 'pointer'}}/></Tooltip></Typography>
            <Grid container spacing={1}>                    
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">
                  {'Can Register As'} 
                </Typography>
              </Grid>                  
            </Grid>    
          </DialogTitle>
          <DialogContent dividers={contactInfoScroll === 'paper'}>
            <DialogContentText id="risk-eval-type-scroll-dialog-description" tabIndex={-1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                    {allEntityWithUserEntity.map((row,index) => (
                      <>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{width: '35%'}}>
                          <Typography sx={{ fontSize: '1.1rem !important' }}>{row.entityName}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width: '65%', paddingLeft: '0px !important', paddingRight: '0px !important'}} align="left">
                          <input type="checkbox" id={"chk"+ row.entityId} />
                        </TableCell>
                      </TableRow>
                      </>
                    ))} 
                    </TableBody>
                  </Table>

                </Grid>
                <Grid item xs={12}>
                  <Button 
                      variant="contained" 
                      //className={classes.btnBorderGreenEntityMap}
                      onClick={() => saveUserEntityFunction()} 
                      style={{float: 'right'}}
                      >
                      Update
                  </Button>
                </Grid>  


              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={entityUpdateHandleClickClose}>Close</Button>
          </DialogActions>
        </Dialog>
      {/** End of Contact Infor*/}




  {/** Dialog Waiting */}
  <Dialog
    maxWidth={'md'}
    open={waitingOpen}
    PaperProps={{style:{backgroundColor: 'transparent', boxShadow: 'none'}}} 
    >
    <CircularProgress disableShrink />
  </Dialog>










    </>
  )
}

export default UserDetailsPage