import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import { ArrowDropDownSharp, CheckBoxOutlineBlankSharp, CheckBoxSharp, LinearScale, QuestionAnswerRounded, RadioButtonChecked, SelectAllRounded, SelectAllSharp } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { color, fontSize } from '@mui/system';
import {CONFIG} from '../config'
import axios from 'axios';
import surveryQuestionScale from '../ServeyQuestionScaleJson';
import useStyle from '../Css/MainCss';
import RiskLevelSlider from './RiskLevelSlider';

const useStyles = makeStyles((theme) => ({ 
    right: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    tableHeadStyle: {
      backgroundColor: '#ece9fa',
      color: '#ffffff',
    },
    tableAnsConfirmHeadStyle: {
        backgroundColor: '#6666FF',
        color: '#ffffff',
        height: '100px !important',
        fontSize: '14px !important',
        fontWeight: '600'
    },
    tableAnsConfrnThStyle: {
        lineHeight: '0.50rem !important',
        fontSize: '22px !important',
        fontWeight: '600',
        color: '#ffffff !important'
    },
    tableAnsConfirmTrStyle: {
        lineHeight: '0.50rem !important',
        backgroundColor: '#FFFFFF',
        borderBottom: '0px !important'
    },
    tableAnsConfirmTrStyle1: {
        lineHeight: '0.25rem !important',
        backgroundColor: '#FFFFFF',
        borderBottom: '0px !important',
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
    },  
    mousePointer: {
        cursor: 'pointer'
    },
    tableThStyle: {
       lineHeight: '0.25rem !important'
    },
     tableTrStyle: {
       lineHeight: '0.25rem !important',
       paddingTop: '0px !important',
       fontSize: '10px !important',
       paddingBottom: '0px !important'
    },
    closeEndedCardHeader:{
        backgroundColor: '#ece9fa !important',
        fontSize:'13px',
        height:'44px',
        padding:'0px',
        marginTop: '-7px',
    },
    avStyle: {
        width: '25px',
        height: '25px',
    },
    paddingStyle: {
        padding:'-3px 14px'
    },
    btnBorderRed:{
        border: '1px solid red !important',
        color: 'red !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },
      btnBorderGreen:{
        border: '1px solid green !important',
        color: 'green !important',
        padding:'5px 10px !important',
        height: '40px !important',
        fontSize: '11px !important',
      },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));  
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '15px'
    },
  }));

 


const QuestionerPageCloseEnded = ({closeEndedvalue, handleCloseEndedChange, ansArray, setAnsArray, ansMethod,setAnsMethod,minNumber,setMinNumber,maxNumber,setMaxNumber,scalingMethod,setScalingMethod,scallingMgs,setScallingMsg, closeEndedTypeName, setCloseEndedTypeName, closeEndedTypeStatus, setCETypeStatus, ansArrayTmp, setAnsArrayTmp, closeEndedScoreValue, handleCloseEndedScoreChange, answerOrderNumber, setAnswerOrderNumber, selectedRiskName, selectedSuggestedValue, setSelectedSuggestedValue, optArray, setOptArray, closeEndedTypeMgs, setCloseEndedTypeMsg,
    questionSliderMethods,sliderTypeCloseEnded,handleChangeCloseEnded,riskClearFunctionCloseEnded,showSelectBarCloseEnded,sliderValueCloseEnded,setSliderValueCloseEnded,
    nameChangeCloseEnded,sliderValueNameCloseEnded,questionLableNameCloseEnded,setRiskLvlEditFunctionCloseEnded,sliderValueCloseEndedStyle,databClr,scaleData,
    scalingMethodExamples,setScalingMethodExamples,scalingMethodExampleId,setScalingMethodExampleId,scalingMethodExampleName,setScalingMethodExampleName,setSelectedColorForAppetiteSlider,selectedColorForAppetiteSlider,setAppetiteColorForSaveToDb,appetiteColorForSaveToDb,colorPatterns,databClrAns}) => {

      console.log("++++++++++++++++++++++colorPatterns+++++++++++++++++++++++++++")
      console.log(colorPatterns)

      console.log(sliderValueCloseEndedStyle);
      

      const [databClr1, setDatabClr1] = useState([])

      const classe = useStyle();
   
    const classes = useStyles();

    /** Sort abject array */
    function compare( a, b ) {
        if ( a.orderNumber < b.orderNumber ){
            return -1;
        }
        if ( a.orderNumber > b.orderNumber ){
            return 1;
        }
        return 0;
    }

    /** Find Duplicates values in a array */
    function toFindDuplicates(arry) {
        let toMap = {};
        let resultToReturn = false;
        for (let i = 0; i < arry.length; i++) {
            if (toMap[arry[i]]) {
                resultToReturn = true;
                break;
            }
            toMap[arry[i]] = true;
        }
        if (resultToReturn) {
        return true;
        }else{
        return false;
        }  
    }

    if (ansMethod != ""){
        setAnsMethod(ansMethod);
        console.log("ansMethod : " + ansMethod);
    }
    if (minNumber != 0){
        setMinNumber(minNumber);
    }
    if (maxNumber != 0){
        setMaxNumber(maxNumber);
    }
    if (scalingMethod != ""){
        setScalingMethod(scalingMethod);
    }
    
    
    // useEffect(()=> {
    //     getColors()
    // })
    
    
    // const [databClr, setDatabClr] = useState([])

    // function getColors(){
    //     axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR+"/all",{
    //       method: 'get',
    //       headers: { 
    //           'Content-Type': 'text/plain', 
    //         },     
    //       })
    //       .then(function (response) {
    //         console.log("All colors");
    //         console.log(response.data);
    
    //         setDatabClr(response.data)
    
           
    //       })
    //       .catch(function (error) {
    //       console.log(error);
    //       });
    //   }

    
    const questionTypeHandler = (status) => {
        if (status === 1){
            setCETypeStatus(1);
            setCloseEndedTypeName("Nominal");
            setCloseEndedTypeMsg("Nominal scale is a naming scale, where Answers are simply \“named\” or labeled, with no specific order");
        }
        if (status === 2){
            setCETypeStatus(2);
            setCloseEndedTypeName("Ordinal");
            setCloseEndedTypeMsg("Ordinal scale has all its Answers in a specific order, beyond just naming them");
        }
        if (status === 3){
            setCETypeStatus(3);
            setCloseEndedTypeName("Interval");
            setCloseEndedTypeMsg("Interval scale offers labels, order, as well as, a specific interval between each of its variable options");
        }
        if (status === 4){
            setCETypeStatus(4);
            setCloseEndedTypeName("Ratio");
            setCloseEndedTypeMsg("Ratio scale bears all the characteristics of an interval scale, in addition to that, it can also accommodate the value of “zero” on any of its variables");
        }

    };
    const ansMethodArr = [
        {
            name: "Question with Multiple Answers",
            value: "checkBoxType"
        },
        {
            name: "Question with Only one Answer",
            value: "radioButtonType"
        },        
        {
            name: "Question with Preferential Order Answers",
            value: "orderNumberType"
        },
        /*
        {
            name: "Linear Scale Type Answers",
            value: "linearType"
        },
        */
        {
            name: "User Input Response",
            value: "answerTypeType"
        },
    ]
    
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const handleChangeAnsMethod = (e) => {
        //orderNumberType, checkBoxType, radioButtonType
        setAnsMethod(e.target.value);
        if (e.target.value != "orderNumberType"){
            setMinNumber(1); 
            setMaxNumber(5);
        }
    };

   

    const handleChangeMinNumber = (e) => {
        setMinNumber(e.target.value);
        if (maxNumber > 0){
            if (e.target.value > 0) {
                let a1 = parseInt(e.target.value);   
                let a2 = parseInt(maxNumber);    
                setOptArray(range(a1,a2)) ;
            }
        }
    };
    const handleChangeMaxNumber = (e) => {
        setMaxNumber(e.target.value);
        if (minNumber > 0){
            if (e.target.value > 0) {
                let a1 = parseInt(minNumber);   
                let a2 = parseInt(e.target.value);    
                setOptArray(range(a1,a2)) ;
            }
        }
    };

    function range(start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }





    const ansScallingMethodArr = [
        {
            name: "Likert",
            value: "likert"
        },
        {
            name: "Semantic Differential",
            value: "semanticdifferential"
        },        
        {
            name: "Dichotomous",
            value: "dichotomous"
        },
    ]
    

    console.log(scaleData)



    const handleChangeScalingMethod = (e) => {
        //likert, semanticdifferential, dichotomous
        var tmpMethod = e.target.value;
        setScalingMethod(tmpMethod);

     
        scaleData.forEach((data)=>{
            if(e.target.value === data.id){
                setScallingMsg(data.description);
                setScalingMethodExamples(data.examples)
            }
        })



        // var arr = [];

        
        // if (tmpMethod === "likert"){


        //     setScallingMsg("It is a psychometric scale questions. Typically a five, seven, or nine-point agreement scale are used");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "likert") {
        //             console.log("+++++++++++++++++data.examples+++++++++++++++++");
        //             console.log(data.examples);
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }
        // if (tmpMethod === "semanticdifferential"){
        //     setScallingMsg("Answering options are grammatically on opposite adjectives at each end like love-hate, satisfied-unsatisfied, and likely to return-unlikely to return with intermediate options in between");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "semanticdifferential") {
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }
        // if (tmpMethod === "dichotomous"){
        //     setScallingMsg("It can have two possible answers like Yes/No, True/False, Fair/Unfair or Agree/Disagree answers");
        //     var arr = surveryQuestionScale.scales;
        //     arr.forEach((data)=> {
        //         if (data.name === "dichotomous") {
        //             setScalingMethodExamples(data.examples);
        //         }
        //     })
        // }

    };

    const [scalingMethodExampleData, setScalingMethodExampleData] = React.useState([]);
    const [scalingMethodExampleDataName, setScalingMethodExampleDataName] = React.useState([]);

    const handleChangeScalingMethodExample = (e) => {
        var tmpMethodId = e.target.value;
        setScalingMethodExampleId(tmpMethodId);
        scalingMethodExamples.forEach((data)=> {
            if (tmpMethodId === data.exampleId){
                setScalingMethodExampleName(data.name);
                setScalingMethodExampleData(data.types);
            }
        })

    };

    const handleChangeScalingMethodExampleData = (e) => {
        var tmpExampleName = e.target.value;
        setScalingMethodExampleDataName(tmpExampleName);
        setAnsValue(tmpExampleName);

    };

    
    
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [ansOpen, setAnsOpen] = React.useState(false);
    const [ansScroll, setAnsScroll] = React.useState('paper');
  
    const ansHandleClickOpen = (scrollType) => () => {
        if (closeEndedTypeStatus > 0 && closeEndedvalue != "" && sliderValueCloseEnded != "") {
            setAnsOpen(true);
            setAnsScroll(scrollType);
            setAnsValue('');
            setRiskValue();
        } else{
            if (closeEndedvalue === ""){
                alert("Please Type the close ended question"); 
            }else{
                if (sliderValueCloseEnded === ""){
                    alert("Please Enter Question Score");
                }else{
                    alert("Please select one of the measurement scale type above");
                }
            }
            
        }   
    };
    const ansHandleClickClose = () => {
      setAnsOpen(false);
      setRiskValueStyle()
      riskClearFunctionScalingAnswer()


    };
    const ansElementRef = React.useRef(null);
    React.useEffect(() => {
      if (ansOpen) {
        const { current: ansElement } = ansElementRef;
        if (ansElement !== null) {
          ansElement.focus();
        }
      }
      getAllQuestionMethods()
      
    }, [ansOpen]);



    const [ansValue, setAnsValue] = React.useState('');
    const handleChangeAns = (event) => {
        setAnsValue(event.target.value);
    };



    //malindu answer slider section

    const [scalingScoreMethods, setScalingScoreMethods] = useState([])

    const [answerCategoryId, setAnswerCategoryId] = useState()

    function getAllQuestionMethods(){
        axios(CONFIG.MAIN_URL+CONFIG.CONTROLLER_NAME_FOR_SLIDER_ANSWER+"/all",{
          method: 'get',
          headers: { 
              'Content-Type': 'text/plain', 
            },     
          })
          .then(function (response) {
           
              console.log("All Risk Returns");
              console.log(response.data[0]);
              setScalingScoreMethods(response.data[0].methods)
              setAnswerCategoryId(response.data[0].id)
              console.log(response.data[0].methods);
      
          })
          .catch(function (error) {
          console.log(error);
          });
      }

  //  const [questionSliderMethods, setQuestionSliderMethods] = useState([])
        
  //  const [questionCategoryId, setQuestionCategoryId] = useState()


    
    const [sliderTypeScalingAnswer, setSliderTypeScalingAnswer] = React.useState();

    const [showSelectBarScalingAnswer, setShowSelectBarScalingAnswer] = React.useState(true);

    const [sliderValueScalingAnswer, setSliderValueScalingAnswer] = React.useState();

    const [sliderValueNameScalingAnswer, setSliderValueNameScalingAnswer] = React.useState('');

    const [riskValue, setRiskValue] = React.useState();

    const [riskValueStyle, setRiskValueStyle] = React.useState();

    const [databClrAns1, setDatabClrAns1] = useState(databClrAns);

    const handleChangeScalingAnswer = (event) => {  

        //scalingScoreMethods
        //alert("CCCCCC")
        setSliderTypeScalingAnswer(event.target.value);
        setShowSelectBarScalingAnswer(false);

        setDatabClrAns1([]);
        scalingScoreMethods.forEach((data)=> {
            if (data.id === event.target.value){
              var colorPatId = data.colorPatternId;
              if (colorPatId != undefined && colorPatId != null){
                colorPatterns.forEach((item)=> {
                  if (item.id === colorPatId){
                    setDatabClrAns1(item.colors);
                  }
                })
              }
            }
          })
        
      };
    
    
      function riskClearFunctionScalingAnswer(){
        setShowSelectBarScalingAnswer(true);
        setSliderTypeScalingAnswer('');
        setRiskValue();
        setSliderValueNameScalingAnswer('');
        setSelectedColorForAppetiteSlider(1)

      }

      function nameChangeScalingAnswer(sVal){
        scalingScoreMethods.forEach((data)=> {
          if(sliderTypeScalingAnswer === data.id){
            data.riskTypes.forEach((data1)=> {
              if (data1.startingNumber <= sVal && sVal <= data1.endingNumber) {
                setSliderValueNameScalingAnswer(data1.riskTypesName)
                
              }
            })
          }
        })
        
      
      }

  
    const handleChangeRisk = (event) => {
        const value = event.target.value.replace(/\D/g, "");
        setRiskValue(value);
    };


    // end of the answer scaling section 
   
    const [editAnswer, setEditAnswer] = React.useState(false);
    const [editAnswerOrderNumber, setEditAnswerOrderNumber] = React.useState(0);
    
    function setAnswerAndRisk(){

        if (ansMethod === ""){
            alert("Please select an Answer Method");
            return
        }
        if ( ansValue === ""){
            alert("Please enter Answer type");
            return
        }
        if ( riskValue === ""){
            alert("Please enter Risk Level");
            return
        }
        
        var canTake = true;
        ansArrayTmp.forEach((data)=>{
            if (data.answer === ansValue) {    
                alert(data.answer + " is already in the Answer list");
                canTake = false
                return;
            }
        })
        if (scalingMethod === "dichotomous"){
            if (ansArrayTmp.length === 2){
                alert("Do not allow more that 2 answers for Dichotomous question scale");
                canTake = false
                return;
            }
        }
        if (canTake){
            var arrayData = {}
            arrayData["answer"] = ansValue;
            arrayData["risk"] = riskValue;
            var tmpArray1 = {}

            tmpArray1["riskLevel"] = riskValue
            tmpArray1["riskCategoryId"] = answerCategoryId
            tmpArray1["riskMethodId"] = sliderTypeScalingAnswer
            if (selectedColorForAppetiteSlider === 1) {
                tmpArray1["color"] = appetiteColorForSaveToDb
                tmpArray1["secondaryColor"] = ""
              }
              else{
                tmpArray1["color"] = ""
                tmpArray1["secondaryColor"] = appetiteColorForSaveToDb
              }

            arrayData["riskLevelData"] = tmpArray1;

            if (editAnswer){
                arrayData["orderNumber"] = editAnswerOrderNumber;
                setEditAnswer(false);
            }else{
                arrayData["orderNumber"] = answerOrderNumber;
                setAnswerOrderNumber(answerOrderNumber + 1);
            }
            ansArrayTmp.push(arrayData);
            ansArrayTmp.sort(compare);
            setAnsArrayTmp(ansArrayTmp);
            console.log(ansArrayTmp)
            setAnsValue("");
            setRiskValue();
            riskClearFunctionScalingAnswer()

        }

        setRiskValueStyle()
    }


    console.log(sliderTypeCloseEnded)
    /** Edit Answer*/
    function editItemArrary(answer){
        if (editAnswer) {
            alert("You have not edited the previous edited Answer. Please edit the previous one and add to list before edit an another Entity");
            return;
        }
        ansArrayTmp.forEach((data, index)=>{
            if (data.answer === answer) {  
                console.log(data)
                setAnsValue(data.answer);
                setRiskValue(data.risk);
                setShowSelectBarScalingAnswer(false)
                setSliderTypeScalingAnswer(data.riskLevelData.riskMethodId)
                var colorScheme = 1;
                if (data.riskLevelData.color != null && data.riskLevelData.color != undefined && data.riskLevelData.color != "" ) {
                  setSelectedColorForAppetiteSlider(1)
                }else if(data.riskLevelData.secondaryColor != null && data.riskLevelData.secondaryColor != undefined && data.riskLevelData.secondaryColor != ""){
                  setSelectedColorForAppetiteSlider(2)
                  colorScheme = 2;
                }

                var min = 0;
                var max = 0
                var colors = [];
                scalingScoreMethods.forEach((item)=> {
                    if(data.riskLevelData.riskMethodId === item.id){
                        var colorPatId = item.colorPatternId
                        colorPatterns.forEach((colorItem)=>{
                            if (colorItem.id === colorPatId){
                              setDatabClrAns1(colorItem.colors);
                              colors = colorItem.colors;
                            }
                        })
                        item.riskTypes.forEach((data1)=> {
                            if (data1.startingNumber <= data.risk && data.risk <= data1.endingNumber) {
                            setSliderValueNameScalingAnswer(data1.riskTypesName)
                            min=item.minimumWeightingNumber;
                            max=item.maximumWeightingNumber;
                            }
                        })
                    }
                })

                var posNumber = data.risk - min;
                var totalNumber = max - min;
                var percentageVal = (posNumber/totalNumber) * 100
                var cCode = getSubColorCodeRiskValueEditNew(data.risk,colorScheme,colors);
                var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
                setRiskValueStyle(cssStr);




                setEditAnswer(true);
                setEditAnswerOrderNumber(data.orderNumber);
                ansArrayTmp.splice(index, 1);  
                ansArrayTmp.sort( compare );  
                setAnsArrayTmp(ansArrayTmp);
                console.log(ansArrayTmp)
            }
        })
    }

    /** Delete Answer*/
    function deleteItemArrary(answer){
        ansArrayTmp.forEach((data, index)=>{
            if (data.answer === answer) {  
                ansArrayTmp.splice(index, 1);
                setAnsValue(answer);
                alert("The answer '" + answer + "' Was deleted")
                ansArrayTmp.sort( compare );
                setAnsArrayTmp(ansArrayTmp);
                setRiskValue();
                setAnsValue("");
            }
        })
        /*
        var tmpArr = [];
        var num = 1;
        ansArrayTmp.forEach((data2, index2)=>{
            var arrayData = {}
            arrayData["answer"] = data2.answer;
            arrayData["risk"] = data2.risk;
            arrayData["orderNumber"] = num;
            tmpArr.push(arrayData);
            num ++;
        })
        setAnsArrayTmp([]);
        setAnswerOrderNumber(num);
        tmpArr.sort( compare );
        setAnsArrayTmp(tmpArr);
        setRiskValue("");
        setAnsValue("");
        */


    }

    function confirmChangePositionFunction() {
        var tmpObjList = [];
        var newArr = [];
        var canTake = true;
        ansArrayTmp.forEach((data, index)=>{
            var newVal = parseInt(document.getElementById("newAnsPos" + index).value);
            if (newVal != "" && !isNaN(newVal)) {
                newArr.push(newVal);
                let objObject = {};
                objObject["answer"] = data.answer;
                objObject["risk"] = data.risk;
                objObject["orderNumber"] = newVal;
                tmpObjList.push(objObject);
            }else{
                alert("Please Check the input position again. There may be empty value or non numeric value entered");
                canTake = false;
            }
        })
        var duplicateElements = toFindDuplicates(newArr);
        if (duplicateElements != undefined && duplicateElements != null && duplicateElements){
            alert("Please Check the input position again. There may be duplicated values entered");
            canTake = false;
         }
        if (tmpObjList != null && tmpObjList.length > 0 && canTake){
            tmpObjList.sort( compare );
            setAnsArrayTmp(tmpObjList);
            alert("Order Numbers are set Correctly");
        }
    }

    function confirmFunction(){
        riskClearFunctionScalingAnswer()
        if (ansArrayTmp.length > 0){
            var totalVal = 0.00;
            ansArrayTmp.forEach((data, index)=>{
                totalVal = totalVal + parseFloat(data.risk);
            })
            var canConfirm = true;
            var tmpCloseEndedScoreValue = 0.00;
            if (sliderValueCloseEnded === ""){
                canConfirm = false;
                alert("Please enter Question Score before entering answers");
                return;  
            }else{
                tmpCloseEndedScoreValue = parseFloat(sliderValueCloseEnded);
            }
           
            /*
            if (totalVal > tmpCloseEndedScoreValue){
                canConfirm = false;
                alert("Total Answers Scalling values should not exceed Question Score");
                return;    
            }
            if (totalVal < tmpCloseEndedScoreValue){
                canConfirm = false;
                alert("Total Answers Scalling values should be equeals to Question Score");
                return;    
            }
            */
            
            if (canConfirm){
                setAnsArray(ansArrayTmp);
                setAnsOpen(false);
            }
            
        }else{
            alert("Please fill the Answer Scalling Table");
        }   
        
        setRiskValueStyle()
    } 

    //add color to slider



    function getSubColorCodeRiskValue(number) {
        var cCode = "#ffffff";
        databClrAns1.forEach((data)=> {
            if (selectedColorForAppetiteSlider === 1) {
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.color;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
              else{
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.secondaryColor;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
        })
        return cCode;
      }

      function getSubColorCodeRiskValueNew(number, colors) {
        var cCode = "#ffffff";
        colors.forEach((data)=> {
            if (selectedColorForAppetiteSlider === 1) {
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.color;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
              else{
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.secondaryColor;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
        })
        return cCode;
      }


      function getSubColorCodeRiskValueEdit(number,schema) {
        var cCode = "#ffffff";
        databClr1.forEach((data)=> {
            if (schema === 1) {
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.color;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
              else{
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.secondaryColor;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
        })
        return cCode;
      }

      function getSubColorCodeRiskValueEditNew(number,schema,colors) {
        var cCode = "#ffffff";
        colors.forEach((data)=> {
            if (schema === 1) {
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.color;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
              else{
                if (parseInt(number) === parseInt(data.scoreNumber)) {
                  cCode = data.secondaryColor;
                  setAppetiteColorForSaveToDb(cCode)
                }
              }
        })
        return cCode;
      }





      function setRiskLvlEditFunctionRiskValue(val, min, max, type){
        var posNumber = val - min;
        var totalNumber = max - min;
        var percentageVal = (posNumber/totalNumber) * 100
        var cCode = getSubColorCodeRiskValue(val);
        var cssStr = 'linear-gradient(90deg, ' + cCode + ' ' + percentageVal + '%, #d3d3d3 0%)';
        
        setRiskValue(val);
        setRiskValueStyle(cssStr);
        
      }  





    const orderNumberSelect = (
        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="select-simple-select-label">Select</InputLabel>
            <Select
                labelId="select-simple-select-label"
                id="select-simple-select"
                label="Select"
                >
                {optArray.map((item)=>(
                    <MenuItem 
                        value={item}
                    >
                    {item}
                    </MenuItem>
                ))}    
            </Select>
        </FormControl>
    )    

    const [selectedValueTmp, setSelectedValueTmp] = React.useState(0);
    const handleChangeTmp = (event) => {
      setSelectedValueTmp(event.target.value);
    };
    const tableRowData = (
        <TableBody>
        {ansMethod === "radioButtonType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <Radio
                        checked={selectedValueTmp === `${index}`}
                        onChange={handleChangeTmp}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <Checkbox {...label} />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && optArray.length > 0 && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {orderNumberSelect}
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArrayTmp.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    <TextField
                        id={"newAnsPos" + index}
                        //label="position"
                        defaultValue={row.orderNumber}
                        size="small"
                        style={{width: '32px !important'}}
                    />
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id="ansTypeType"
                            label="Answer"
                            size="small"
                        />
                    </div>
                </Box> 
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle}>{row.risk}</StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <EditIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{editItemArrary(row.answer)}}
                    />
                </StyledTableCell>
                <StyledTableCell 
                    align="right"
                    className={classes.tableTrStyle}
                    >
                    <DeleteIcon 
                        className={classes.mousePointer}
                        onClick ={ ()=>{deleteItemArrary(row.answer)}}
                    />
                </StyledTableCell>
            </StyledTableRow>
        ))}



    </TableBody>
    )

    

    const tableSetRowData = (
        <TableBody>
        {ansMethod === "radioButtonType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <Radio
                        checked={selectedValueTmp === `${index}`}
                        onChange={handleChangeTmp}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <Checkbox {...label} />
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && optArray.length > 0 && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {orderNumberSelect}
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} className={classes.tableTrStyle}>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id="ansTypeType"
                            label="Answer"
                            size="small"
                        />
                    </div>
                </Box> 
                </StyledTableCell>
                <StyledTableCell className={classes.tableTrStyle} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>{row.risk}</StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
    )

    
    const handleChangeSuggestedValue = (event) => {
        console.log(event.target.value);
        setSelectedSuggestedValue(event.target.value);
    };
    const suggestedAnsTable = (
    <>
        {ansMethod === "radioButtonType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    <Radio
                       
                        checked={selectedSuggestedValue === `${index}`}
                        onChange={handleChangeSuggestedValue}
                        value={index}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    {row.answer}              
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "checkBoxType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell copSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important' }}>
                    <FormControlLabel 
                        control={<Checkbox id={"chk"+index} checked={row.expectedAnswerName}/>} label={row.answer} />
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "orderNumberType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                    &nbsp;
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                    {row.answer}              
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                    <FormControl sx={{ m: 0.5, minWidth: 100 }} size="small">
                        <InputLabel id="select-select-label">Select</InputLabel>
                        <Select
                            labelId="select-select-label"
                            id={"select" + index}
                            label="Select"
                            defaultValue={row.expectedAnswerName}
                            >
                            {optArray.map((item)=>(
                                <MenuItem 
                                    value={item}
                                >
                                {item}
                            </MenuItem>
                            ))}    
                        </Select>
                    </FormControl>
                </StyledTableCell>
            </StyledTableRow>
        ))}
        {ansMethod === "answerTypeType" && ansArray.map((row,index) => (
            <StyledTableRow key={row.answer} >
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'5%' }}>
                &nbsp;
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important', width:'40%' }}>
                {row.answer}              
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle1} style={{paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                <Box
                    component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '10ch' },
                        }}
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            id={"txt" + index}
                            defaultValue={row.expectedAnswerName}
                            size="small"
                        />
                    </div>
                </Box> 
            </StyledTableCell>
        </StyledTableRow>
        ))}
    </>  
    )


    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
    }

    /** Answer Confirm */
    const [ansConfirmOpen, setAnsConfirmOpen] = React.useState(false);
    const [ansConfirmScroll, setAnsConfirmScroll] = React.useState('paper');
    
    const ansConfirmHandleClickOpen = (scrollType) => () => {
        setAnsConfirmOpen(true);
        setAnsConfirmScroll(scrollType);
    };

    const ansConfirmHandleClickClose = () => {
        setAnsConfirmOpen(false);
    };

    function confirmExpertAnswer(){
        var tmpArry = [];
        if (ansMethod === "radioButtonType") {
            ansArray.forEach((data,index)=>{
                console.log("+++++++++++++++++++Expert Answer Data+++++++++++++++++++++++++");
                console.log(data);
                if (index === parseInt(selectedSuggestedValue)) {   
                    var arrayData = {}
                    arrayData["answer"] = data.answer;
                    arrayData["risk"] = data.risk;
                    arrayData["orderNumber"] = data.orderNumber;
                    arrayData["expectedAnswerName"] = "Yes";
                    arrayData["riskLevelData"] = data.riskLevelData;
                    tmpArry.push(arrayData);
                }else{
                    var arrayData = {}
                    arrayData["answer"] = data.answer;
                    arrayData["risk"] = data.risk;
                    arrayData["orderNumber"] = data.orderNumber;
                    arrayData["expectedAnswerName"] = null;
                    arrayData["riskLevelData"] = data.riskLevelData;
                    tmpArry.push(arrayData);
                }
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "checkBoxType") {
            ansArray.forEach((data,index)=>{
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                if (document.getElementById('chk' + index).checked){
                    arrayData["expectedAnswerName"] = "checked";
                }else{
                    arrayData["expectedAnswerName"] = "";
                }
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "orderNumberType") {
            ansArray.forEach((data,index)=>{
                var select = document.getElementById('select' + index);
                var value = select.innerText;
                if (value === undefined || value === null){
                    value = "";
                }
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                arrayData["expectedAnswerName"] = value;
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
        if (ansMethod === "answerTypeType") {
            ansArray.forEach((data,index)=>{
                var value = document.getElementById("txt" + index).value;
                if (value === undefined || value === null){
                    value = "";
                }
                var arrayData = {}
                arrayData["answer"] = data.answer;
                arrayData["risk"] = data.risk;
                arrayData["orderNumber"] = data.orderNumber;
                arrayData["expectedAnswerName"] = value;
                arrayData["riskLevelData"] = data.riskLevelData;
                tmpArry.push(arrayData);
            })
            setAnsArray(tmpArry);
            setAnsConfirmOpen(false);
        }
    }



    const radioHandler = (status) => {
        setSelectedColorForAppetiteSlider(status);
         if (selectedColorForAppetiteSlider != status){
            setRiskValue('')
            setSliderValueNameScalingAnswer('')
            setRiskValueStyle('')

         }
    };

return (
    <>
    <Grid item xs={7}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                    id="closeEndedQuest"
                    label={questionLableNameCloseEnded}
                    multiline
                    maxRows={3}
                    value={closeEndedvalue}
                    onChange={handleCloseEndedChange}
                />
            </div>
        </Box>
    </Grid>
    <Grid item xs={5}>
    <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <RiskLevelSlider
                                                                    MethodDropdown={'Select Score method'}
                                                                    SliderName={'Question Score'}
                                                                    showSelectBar={showSelectBarCloseEnded}
                                                                    handleChange={handleChangeCloseEnded}
                                                                    sliderType={sliderTypeCloseEnded}
                                                                    riskCategoryMethodData={questionSliderMethods}
                                                                    nameChange={nameChangeCloseEnded}
                                                                    sliderValue={sliderValueCloseEnded}
                                                                    sliderValueStyle={sliderValueCloseEndedStyle}
                                                                    sliderValueName={sliderValueNameCloseEnded}
                                                                    riskClearFunction={riskClearFunctionCloseEnded}
                                                                    setSliderValueFunction={setRiskLvlEditFunctionCloseEnded}
                                          
                                          />
                                                               
                                                                </div>
                                                            </Box> 




    </Grid>
    <Grid item xs={12}>
        <FormLabel id="radio-buttons-group-close-ended-label">Measurement scale for Answers</FormLabel>
        <RadioGroup
            row
            aria-labelledby="radio-buttons-group-close-ended-label"
            name="radio-buttons-close-ended-group"
            >
	    <BootstrapTooltip title="Many named responses but user is allowed only one response. When you ask someone to select a meal from a menu, you're using a nominal scale. Yes/no questions, thumbs up/down, or multiple-choice questions are also nominal scale. Nominal-minded questions are also sometimes open-ended (allowing the person to write in a response)."> 	
            <FormControlLabel 
                value="closeEndedNominal" 
                control={<Radio />} 
                label="Nominal" 
                checked={closeEndedTypeStatus === 1}
                onClick={(e) => questionTypeHandler(1)}  
            />
            </BootstrapTooltip>
            <BootstrapTooltip title='User allowed many responses in a specific order. The answers can include: “very satisfied,” “satisfied,” “dissatisfied,” and “very dissatisfied”. Ranking of high school students – 1st, 3rd, 4th, 10th… Nth. A student scoring 99/100 would be the 1st rank, another student scoring 92/100 would be 3rd and so on and so forth. '>
            <FormControlLabel 
                value="closeEndedOrdinal" 
                control={<Radio />} 
                label="Ordinal" 
                checked={closeEndedTypeStatus === 2}
                onClick={(e) => questionTypeHandler(2)}    
            />
            </BootstrapTooltip>
            <BootstrapTooltip title="User allowed many responses in a specific order as well as a specific interval between each of it's options. Celsius and Fahrenheit are examples of interval scales. When you ask someone to rate their meal on a scale of one to ten, you're using an interval scale.">
            <FormControlLabel 
                value="closeEndedInterval" 
                control={<Radio />} 
                label="Interval" 
                checked={closeEndedTypeStatus === 3}
                onClick={(e) => questionTypeHandler(3)}  
            />
            </BootstrapTooltip>
            <BootstrapTooltip title="Same as Interval but with a 'Zero' as the difference. A ratio scale is a quantitative scale where there is a true zero and equal intervals between neighboring points. Unlike on an interval scale, a zero on a ratio scale means there is a total absence of the variable you are measuring. Length, area, and population are examples of ratio scales.">
            <FormControlLabel 
                value="closeEndedRatio"  
                control={<Radio />} 
                label="Ratio" 
                checked={closeEndedTypeStatus === 4}
                onClick={(e) => questionTypeHandler(4)}    
            />
            </BootstrapTooltip>
        </RadioGroup>
    </Grid>
    

    <Grid item xs={3}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={ansHandleClickOpen('paper')}>
            Expected Answers
        </Button>
        <Dialog
            maxWidth='xl'
            open={ansOpen}
            onClose={ansHandleClickClose}
            scroll={ansScroll}
            aria-labelledby="ans-scroll-dialog-title"
            aria-describedby="ans-scroll-dialog-description"
            >
            <DialogTitle id="draggable-dialog-title">
                Add A New Answer Type and Risk Level for "{closeEndedTypeName}" Measurement Scale
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <span>Question : </span><span style={{fontSize: '10px !important', fontWeight:'normal'}}>{closeEndedvalue}</span>
                     </Grid>  
                     <Grid item xs={12} style={{marginTop: '-5px'}}>
                        <span>Score : </span><span style={{fontSize: '10px !important', fontWeight:'normal'}}>{sliderValueCloseEnded}</span>
                     </Grid>   
                </Grid>
            </DialogTitle>
            <DialogContent dividers={ansScroll === 'paper'}>
                <DialogContentText
                    id="ans-scroll-dialog-description"
                    ref={ansElementRef}
                    tabIndex={-1}
                    >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="info">{closeEndedTypeMgs}<br></br>
                            </Alert>
                        </Grid>

                        {CONFIG.EXPERT_LOGIN?
                        <Grid item xs={5}>
                            <Box sx={{ minWidth: 120 }}>    
                                <FormControl fullWidth>
                                    <InputLabel id="ans-method-select-label">Select a survey question scale</InputLabel>
                                    <Select 
                                        labelId="scale-method-select-label" 
                                        id="scale-method-select"
                                        label="Select a survey question scale"
                                        value={scalingMethod}
                                        className={classes.paddingStyle}
                                        onChange={handleChangeScalingMethod}
                                        >
                                                {scaleData.map((item)=>(
                                                    <MenuItem 
                                                        value={item.id}
                                                    >
                                                    {item.name}
                                                    </MenuItem>
                                                ))}

                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :''
                        }
                      
                        {CONFIG.EXPERT_LOGIN && scalingMethod != "" && (
                            <Grid item xs={7}>
                                <Alert severity="info"><span style={{fontSize:'9px'}}>{scallingMgs}</span>
                                </Alert>
                            </Grid>
                        )}

                        
                        <Grid item xs={12} spacing={2}>
                            <Card className={classes.qusCard}>
                                <CardHeader
                                    avatar={
                                    <Avatar sx={{ bgcolor: '#9155FD', width:'25px', height:'25px' }}>
                                        +
                                    </Avatar>
                                    } 
                                    className={classes.closeEndedCardHeader} 
                                    title="Add Expected Answers and Scale them"
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Box sx={{ minWidth: 120 }}>    
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label="Select a Answer Method for All Answers"
                                                        sx={{ width: '100%' }}
                                                        className={classes.paddingStyle}
                                                        select
                                                        value={ansMethod}
                                                        onChange={handleChangeAnsMethod}
                                                        >
                                                        { closeEndedTypeStatus != 1 && scalingMethod != "dichotomous" && 
                                                            ansMethodArr.map((item)=>(
                                                                <MenuItem 
                                                                    value={item.value}
                                                                >
                                                                { (item.value === "checkBoxType") && 
                                                                    <>
                                                                        <CheckBoxSharp/>&nbsp;&nbsp;{item.name}
                                                                    </>
                                                                }
                                                                { (item.value === "radioButtonType") && 
                                                                    <>
                                                                        <RadioButtonChecked/>&nbsp;&nbsp;{item.name}
                                                                    </>
                                                                }
                                                                { (item.value === "orderNumberType") && 
                                                                    <>
                                                                        <ArrowDropDownSharp/>&nbsp;&nbsp;{item.name}
                                                                    </>
                                                                }
                                                                { (item.value === "answerTypeType") && 
                                                                    <>
                                                                        <QuestionAnswerRounded/>&nbsp;&nbsp;{item.name}
                                                                    </>
                                                                }
                                                                
                                                                </MenuItem>
                                                            ))
                                                        }    
                                                        { closeEndedTypeStatus === 1 && (
                                                            <MenuItem 
                                                                value="radioButtonType"
                                                                >
                                                                <RadioButtonChecked/>&nbsp;&nbsp;Question with Only one Answer
                                                            </MenuItem>
                                                        )}
                                                        { scalingMethod === "dichotomous" && (
                                                            <MenuItem 
                                                                value="radioButtonType"
                                                                >
                                                                <RadioButtonChecked/>&nbsp;&nbsp;Question with Only one Answer
                                                            </MenuItem>
                                                        )}


                                                    </TextField>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        {CONFIG.EXPERT_LOGIN?
                                        <Grid item xs={4}>
                                            <Box sx={{ minWidth: 120 }}>    
                                                <FormControl fullWidth>
                                                    <InputLabel id="example-select-label">Select an example for selected question scale if needed</InputLabel>
                                                    <Select 
                                                        labelId="example-select-label" 
                                                        id="example-select"
                                                        label="Select an example for selected question scale if needed"
                                                        value={scalingMethodExampleId}
                                                        className={classes.paddingStyle}
                                                        onChange={handleChangeScalingMethodExample}
                                                        >
                                                        {scalingMethodExamples.map((item)=>(
                                                            <MenuItem 
                                                                value={item.exampleId}
                                                                >
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    :''
                                    }    
                                    {CONFIG.EXPERT_LOGIN?

                                        <Grid item xs={4}>
                                            <Box sx={{ minWidth: 120 }}>    
                                                <FormControl fullWidth>
                                                    <InputLabel id="example-data-select-label">Select an example</InputLabel>
                                                    <Select 
                                                        labelId="example-data-select-label" 
                                                        id="example-data-select"
                                                        label="Select an example"
                                                        value={scalingMethodExampleDataName}
                                                        className={classes.paddingStyle}
                                                        onChange={handleChangeScalingMethodExampleData}
                                                        >
                                                        {scalingMethodExampleData.map((item)=>(
                                                            

                                                                <MenuItem 
                                                                value={item.name}
                                                                >
                                                                {item.name}
                                                            </MenuItem>
                                                            


                                                        //   item.types.map((data)=>{
                                                               
                                                                
                                                        //             {scalingMethodExampleId == item.exampleId ? 
                                                        //                 <>
                                                        //                     <MenuItem 
                                                        //                         value={data.name}
                                                        //                         >
                                                        //                         {data.name}
                                                        //                     </MenuItem>

                                                        //                     </>
                                                        //             :''
                                                        //             }
                                                                
                                                               
                                                                
                                                          
                                                        //         })
                                                            
                                                            
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        :''
                                        }

                                            {ansMethod === "orderNumberType" && (
                                                <>                        
                                                    <Grid item xs={3} spacing={2}>
                                                        <Box
                                                            component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { width: '100%' },
                                                                }}
                                                                validate
                                                                autoComplete="off"
                                                            >
                                                            <div>
                                                                <TextField
                                                                    id="minNumber"
                                                                    label="Min. Number"
                                                                    value={minNumber}
                                                                    select
                                                                    className={classes.paddingStyle}
                                                                    onChange={handleChangeMinNumber}
                                                                    >
                                                                    <MenuItem value={0}>0</MenuItem>
                                                                    <MenuItem value={1}>1</MenuItem>
                                                                </TextField>    
                                                            </div>
                                                        </Box> 
                                                    </Grid>
                                                    <Grid item xs={3} spacing={2}>
                                                        <Box
                                                            component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { width: '100%' },
                                                                }}
                                                                validate
                                                                autoComplete="off"
                                                            >
                                                            <div>
                                                                <TextField
                                                                    id="maxNumber"
                                                                    label="Max. Number"
                                                                    value={maxNumber}
                                                                    select
                                                                    className={classes.paddingStyle}
                                                                    onChange={handleChangeMaxNumber}
                                                                    >
                                                                    <MenuItem value={1}>1</MenuItem>
                                                                    <MenuItem value={2}>2</MenuItem>
                                                                    <MenuItem value={3}>3</MenuItem>
                                                                    <MenuItem value={4}>4</MenuItem>
                                                                    <MenuItem value={5}>5</MenuItem>
                                                                    <MenuItem value={6}>6</MenuItem>
                                                                    <MenuItem value={7}>7</MenuItem>
                                                                    <MenuItem value={8}>8</MenuItem>
                                                                    <MenuItem value={9}>9</MenuItem>
                                                                    <MenuItem value={10}>10</MenuItem>

                                                                </TextField>
                                                            </div>
                                                        </Box> 
                                                    </Grid>
                                                </>
                                            )}
                                            {ansMethod != "orderNumberType" && (
                                                <>                        
                                                    <Grid item xs={6} spacing={2}>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5} >
                                            <Box
                                                component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { width: '100%' },
                                                    }}
                                                    validate
                                                    autoComplete="off"
                                                >
                                                <div>
                                                    <TextField
                                                        id="ansType"
                                                        label="Answer"
                                                        multiline
                                                        maxRows={1}
                                                        value={ansValue}
                                                        onChange={handleChangeAns}
                                                    />
                                                </div>
                                            </Box> 
                                        </Grid>
                                        <Grid item xs={5}>


                                        <Box component="form" sx={{ '& .MuiTextField-root': {width: '100%' },}} validate autoComplete="off">
                                                                <div>
                                                                <>
    
                                                  {
                                                      showSelectBarScalingAnswer ? 
                                                      
                                                      <div>

                                                      <Box >
                                                      <Grid container spacing={2}>
                                                      <Grid item xs={10}>
                                                      <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label" style={{marginTop:'!important -30px'}}>Input Score</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={sliderTypeScalingAnswer}
                                                          label="Age"
                                                          onChange={handleChangeScalingAnswer}
                                                        >

                                                            {scalingScoreMethods.map((data,index)=> (
                                                            
                                                            <MenuItem value={data.id}>{data.methodName}</MenuItem>

                                                           
                                                          ))}
                                                  
                                                        </Select>
                                                      </FormControl>
                                                      </Grid>
                                                      </Grid>
                                                    </Box>
                                                
                                                    </div>
                                                      
                                                      
                                                      :null
                                                  }
                                                    
                                                  {
                                                    scalingScoreMethods.map((data)=>(
                                                     
                                                      <>
                                                      {
                                                        sliderTypeScalingAnswer === data.id ? 
                                                        <>
                                                        
                                                         {/* <p style={{color:'black', marginTop:'-30px'}}>Scaling</p> */}
                                                              <Grid container spacing={2}>
                                                              
                                                                <Grid item xs={7}>
                                                                <FormLabel id="radio-buttons-group-label">Please select your color scheme,</FormLabel>
                                                                    <div style={{display:'flex'}}>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="radio-buttons-group-label"
                                                                        name="radio-buttons-group"
                                                                        >
                                                                        <FormControlLabel 
                                                                            value="primary" 
                                                                            control={<Radio />} 
                                                                            label="Risk" 
                                                                            checked={selectedColorForAppetiteSlider === 1}
                                                                            onClick={(e) => radioHandler(1)}    
                                                                        />
                                                                        <FormControlLabel 
                                                                            value="secondary" 
                                                                            control={<Radio />} 
                                                                            label="Preferences" 
                                                                            checked={selectedColorForAppetiteSlider === 2}
                                                                            onClick={(e) => radioHandler(2)}
                                                                        />
                                                                    </RadioGroup>
                                                                    </div>
                                                                  <div>
                                                                 
                                                                    <input
                                                                      id='slider'
                                                                      type="range"
                                                                      min={data.minimumWeightingNumber}
                                                                      
                                                                      max={data.maximumWeightingNumber}
                                                                      value={riskValue}
                                                                      onChange={({ target: { value } }) => {setRiskLvlEditFunctionRiskValue(value,data.minimumWeightingNumber,data.maximumWeightingNumber);nameChangeScalingAnswer(value)}}
                                                                      className={`slider`}
                                                                      // className={btnEntityClass()}
                                                                      style={{background: `${riskValueStyle}`}}
                                                                     // onClick={nameChangeScalingAnswer}
                                                                      />
                                                                  </div>
                                                                </Grid>
                                                                {/* <Grid item xs={2}>
                                                                  <div >
                                                                  <div >
                                                                    <p style={{fontSize:'10px'}}>{sliderValueNameScalingAnswer}</p>
                                                                  </div>
                                                                 
                                                                  </div>
                                                                </Grid> */}
                                                                <Grid item xs={1}>
                                                                <div>
                                                                  <Button variant="outlined"  onClick={riskClearFunctionScalingAnswer}>clear</Button>
                                                                  </div>
                                                                </Grid>
                                                                
                                                                
                                                              </Grid>
                                                            <p>
                                                                 <b>{riskValue}&nbsp;-&nbsp;{sliderValueNameScalingAnswer}</b>
                                                              </p>
                                                        </> 
                                                        
                                                        
                                                        
                                                        :null
                                                      }
                                                      </>
                                                    ))
                                                  }

                                                      
                                                    
                                                        </>
                                                                </div>
                                                            </Box> 




                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button 
                                                variant="outlined" 
                                                style={{fontSize:'0.7rem', height: '50px'}}
                                                startIcon={<AddIcon />} 
                                                onClick={() => setAnswerAndRisk()}
                                                >
                                                Add To List
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 695 }} aria-label="customized table">
                                                    <TableHead className={classes.tableHeadStyle}>
                                                        <TableRow>
                                                            <StyledTableCell className={classes.tableThStyle} style={{width: '10%'}}>Position</StyledTableCell>
                                                            <StyledTableCell className={classes.tableThStyle} style={{width: '40%'}}>Answer</StyledTableCell>
                                                            <StyledTableCell className={classes.tableThStyle} style={{width: '20%'}}>Answer Method</StyledTableCell>
                                                            <StyledTableCell className={classes.tableThStyle}style={{width: '10%'}}>Scaling</StyledTableCell>
                                                            <StyledTableCell align="right" className={classes.tableThStyle} style={{width: '10%'}}>Edit</StyledTableCell>
                                                            <StyledTableCell align="right" className={classes.tableThStyle} style={{width: '10%'}}>Delete</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {tableRowData}

                                                   
                                                </Table>
                                            </TableContainer>    
                                        </Grid>     
                                        <Grid item xs={12}>
                                            { ansArrayTmp.length > 0 && (
                                                <>
                                                    &nbsp;&nbsp;<Button 
                                                    variant="outlined" 
                                                    size='small' 
                                                    className={classes.btnBorderRed}
                                                    //onClick={entPosHandleClickOpen('paper')}
                                                    onClick={() => confirmChangePositionFunction()} 
                                                    style={{float:'right'}}
                                                    > 
                                                    Change the Position and Press Here to Change
                                                    </Button>&nbsp;&nbsp;
                                                    </>
                                            )}
                                            &nbsp;&nbsp;<Button 
                                                variant="outlined" 
                                                size='small'
                                                className={classes.btnBorderGreen}
                                                onClick={() => confirmFunction(1)} 
                                                style={{float:'right'}}>
                                                    CONFIRM
                                            </Button>&nbsp;&nbsp;
                                        </Grid>



                                    </Grid>
                            
                                </CardContent>
                            </Card>
                        </Grid>




                        
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ansHandleClickClose}>Cancel</Button>
            </DialogActions>
        </Dialog>

    </Grid>
    <Grid item xs={9}>
        {ansArray.length > 0 && (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 695 }} aria-label="customized table">
                    <TableHead className={classes.tableHeadStyle}>
                        <TableRow>
                            <StyledTableCell className={classes.tableThStyle}>Answer</StyledTableCell>
                            <StyledTableCell className={classes.tableThStyle}>Answer Method</StyledTableCell>
                            <StyledTableCell className={classes.tableThStyle}>Scaling</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {tableSetRowData}
                </Table>    
            </TableContainer>    
        )}
    </Grid>   
    {ansArray.length > 0 && (
        <Grid item xs={12}>
            <Button 
                variant="outlined" 
                size='small'
                className={classes.btnBorderGreen}
                onClick={ansConfirmHandleClickOpen('paper')} 
                style={{float:'right'}}>
                    Expert's Example Answer/s
            </Button>
        
        </Grid>   

    )}
                          

    {/** Answer Confirm Dialog Box */}
        <Dialog

            open={ansConfirmOpen}
            onClose={ansConfirmHandleClickClose}
            scroll={ansConfirmScroll}
            aria-labelledby="ansConfirm-scroll-dialog-title"
            aria-describedby="ansConfirm-scroll-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
          >
          <DialogTitle id="ansConfirm-scroll-dialog-title"></DialogTitle>
          <DialogContent dividers={ansConfirmScroll === 'paper'}>
            <DialogContentText
              id="ansConfirm-scroll-dialog-description"
              tabIndex={-1}
              >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{"marginTop": "-17px !important"}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 695 }} aria-label="customized table">
                            <TableHead className={classes.tableAnsConfirmHeadStyle}>
                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center" className={classes.tableAnsConfrnThStyle}>{selectedRiskName}</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <StyledTableRow key={1}>
                                    <StyledTableCell colSpan={3} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                        &nbsp;
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={2}>
                                    <StyledTableCell component="th" scope="row" className={classes.tableAnsConfirmTrStyle} style={{width:'5%'}}>
                                        #)
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} component="th" scope="row" className={classes.tableAnsConfirmTrStyle}>
                                        {closeEndedvalue} 
                                    </StyledTableCell>
                                </StyledTableRow>
                                {suggestedAnsTable}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => confirmExpertAnswer()}>Confirm</Button>&nbsp;&nbsp;<Button onClick={ansConfirmHandleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      {/** End of entity possition Dialog Box */}  

    
                       

    </>   
  )
}

export default QuestionerPageCloseEnded