import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import "../Css/EvaluateComponent.css";

const TransferToExcel = ({ commitArray, commitArrayData, commitBarData, riskTypeDataForEval }) => {

    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
  
        let maxRiskNameLength = 10;
        let maxRiskDescriptionLength = 10;
        let maxImpactDescriptionLength = 10;
        let maxMitigationLength = 10;

        const updateMaxLengths = (item, prefix = '') => {
            maxRiskNameLength = Math.max(maxRiskNameLength, (prefix + item.name).length);
            maxRiskDescriptionLength = Math.max(maxRiskDescriptionLength, (item.riskDescription || '').length);
            maxImpactDescriptionLength = Math.max(maxImpactDescriptionLength, (item.impactDescription || '').length);
            maxMitigationLength = Math.max(maxMitigationLength, (item.mitigation || '').length);
        };

 
        riskTypeDataForEval.riskTypes.forEach((data) => {
            updateMaxLengths(data);
            if (data.subRisks) {
                data.subRisks.forEach((subRisk) => {
                    updateMaxLengths(subRisk, '-- ');
                    if (subRisk.subRisks) {
                        subRisk.subRisks.forEach((subSubRisk) => {
                            updateMaxLengths(subSubRisk, '---- ');
                            if (subSubRisk.subRisks) {
                                subSubRisk.subRisks.forEach((subSubSubRisk) => {
                                    updateMaxLengths(subSubSubRisk, '------ ');
                                    if (subSubSubRisk.subRisks) {
                                        subSubSubRisk.subRisks.forEach((subSubSubSubRisk) => {
                                            updateMaxLengths(subSubSubSubRisk, '-------- ');  
                                            if (subSubSubSubRisk.subRisks) {
                                                subSubSubSubRisk.subRisks.forEach((subSubSubSubSubRisk) => {
                                                    updateMaxLengths(subSubSubSubSubRisk, '----------');  
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

   
        worksheet.columns = [
            { header: "Risk Name", width: maxRiskNameLength - 5 , style: { font: { size: 10 } } }, 
            { header: "Risk Impact", width: 10, style: { font: { size: 10 } } },
            { header: "Risk Likelihood", width: 10, style: { font: { size: 10 } } },
            { header: "Risk Level", width: 10, style: { font: { size: 10 } } },
            { header: "Risk Appetite", width: 10, style: { font: { size: 10 } } },
            { header: "Risk Description", width: maxRiskDescriptionLength - 12, style: { font: { size: 10 } } }, 
            { header: "Impact Description", width: maxImpactDescriptionLength - 12 , style: { font: { size: 10 } } }, 
            { header: "Mitigation", width: maxMitigationLength - 12 , style: { font: { size: 10 } } } 
        ];

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFADD8E6' }  
            };
            cell.font = { bold: true, size: 10 }; 
        });
    
        worksheet.addRow([]);
   

        riskTypeDataForEval.riskTypes.forEach((data) => {
 
            const row = worksheet.addRow([
                data.name, parseInt(data.riskImpact), parseInt(data.riskLikelihood), parseInt(data.riskLevel),
                parseInt(data.riskAppetite), data.riskDescription || '', data.impactDescription || '', data.mitigation || ''
            ]);
    
            applyRiskStyling(row, data);

       
            if (data.subRisks?.length > 0) {
                data.subRisks.forEach((subRisk) => {
                    const subRiskRow = worksheet.addRow([
                        `-- ${subRisk.name}`, parseInt(subRisk.riskImpact), parseInt(subRisk.riskLikelihood), parseInt(subRisk.riskLevel),
                        parseInt(subRisk.riskAppetite), subRisk.riskDescription || '', subRisk.impactDescription || '', subRisk.mitigation || ''
                    ]);

                    applyRiskStyling(subRiskRow, subRisk);

                    if (subRisk.subRisks?.length > 0) {
                        subRisk.subRisks.forEach((subSubRisk) => {
                            const subSubRiskRow = worksheet.addRow([
                                `---- ${subSubRisk.name}`, parseInt(subSubRisk.riskImpact), parseInt(subSubRisk.riskLikelihood), parseInt(subSubRisk.riskLevel),
                                    parseInt(subSubRisk.riskAppetite), subSubRisk.riskDescription || '', subSubRisk.impactDescription || '', subSubRisk.mitigation || ''
                            ]);

                            applyRiskStyling(subSubRiskRow, subSubRisk);

                   
                            if (subSubRisk.subRisks?.length > 0) {
                                subSubRisk.subRisks.forEach((subSubSubRisk) => {
                                    const subSubSubRiskRow = worksheet.addRow([
                                        `------ ${subSubSubRisk.name}`,parseInt(subSubSubRisk.riskImpact), parseInt(subSubSubRisk.riskLikelihood), parseInt(subSubSubRisk.riskLevel),
                                            parseInt(subSubSubRisk.riskAppetite), subSubSubRisk.riskDescription || '', subSubSubRisk.impactDescription || '', subSubSubRisk.mitigation || ''
                                    ]);

                                    applyRiskStyling(subSubSubRiskRow, subSubSubRisk);

                                
                                    if (subSubSubRisk.subRisks?.length > 0) {
                                        subSubSubRisk.subRisks.forEach((subSubSubSubRisk) => {
                                            const subSubSubSubRiskRow = worksheet.addRow([
                                                `-------- ${subSubSubSubRisk.name}`, parseInt(subSubSubSubRisk.riskImpact), parseInt(subSubSubSubRisk.riskLikelihood), parseInt(subSubSubSubRisk.riskLevel),
                                                parseInt(subSubSubSubRisk.riskAppetite), subSubSubSubRisk.riskDescription || '', subSubSubSubRisk.impactDescription || '', subSubSubSubRisk.mitigation || ''
                                            ]);

                                            applyRiskStyling(subSubSubSubRiskRow, subSubSubSubRisk);

                                            if (subSubSubSubRisk.subRisks?.length > 0) {
                                                subSubSubSubRisk.subRisks.forEach((subSubSubSubSubRisk) => {
                                                    const subSubSubSubSubRiskRow = worksheet.addRow([
                                                        `-------- ${subSubSubSubSubRisk.name}`, parseInt(subSubSubSubSubRisk.riskImpact), parseInt(subSubSubSubSubRisk.riskLikelihood), parseInt(subSubSubSubSubRisk.riskLevel),
                                                        parseInt(subSubSubSubSubRisk.riskAppetite), subSubSubSubSubRisk.riskDescription || '', subSubSubSubSubRisk.impactDescription || '', subSubSubSubSubRisk.mitigation || ''
                                                    ]);
        
                                                    applyRiskStyling(subSubSubSubSubRiskRow, subSubSubSubSubRisk);
        
                                                    
                                                });
                                            }

                                            
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        let fileName = "data.xlsx";
        if (riskTypeDataForEval) {
            fileName = `${riskTypeDataForEval.name.split(" - ")[0]} - All Roles.xlsx`;
        }
        saveAs(new Blob([buffer]), fileName);
    };


    const applyRiskStyling = (row, data) => {
        const riskLevelCell = row.getCell(4);
        riskLevelCell.font = {
            color: { argb: data.riskColor.replace('#', 'FF') },
            size: 10,
            bold: true
        };

        const riskAppetiteCell = row.getCell(5);
        riskAppetiteCell.font = {
            color: { argb: data.appetiteColor.replace('#', 'FF') },
            size: 10,
            bold: true
        };

        const riskDescriptionCell = row.getCell(6);
        const impactDescriptionCell = row.getCell(7);
        const mitigationCell = row.getCell(8);

        riskDescriptionCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF7D1' }
        };

        impactDescriptionCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFECC8' }
        };

        mitigationCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD09B' }
        };
    };

    return (
        <div>
            <Button className='btnNormal' variant='outlined' onClick={handleDownload}>
                Transfer to Excel File
            </Button>
        </div>
    );
};

export default TransferToExcel;
