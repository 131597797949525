import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import "../Css/EvaluateComponent.css";

const TransferToExcel1 = ({ commitArray, commitArrayData, commitBarData, riskTypeDataForEval, registerName }) => {

    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        let maxRiskNameLength = 10;
        let maxRiskDescriptionLength = 10;
        let maxImpactDescriptionLength = 10;
        let maxMitigationLength = 10;

        const updateMaxLengths = (item, prefix = '') => {
            maxRiskNameLength = Math.max(maxRiskNameLength, (prefix + item.name).length);
            maxRiskDescriptionLength = Math.max(maxRiskDescriptionLength, (item.riskDescription || '').length);
            maxImpactDescriptionLength = Math.max(maxImpactDescriptionLength, (item.impactDescription || '').length);
            maxMitigationLength = Math.max(maxMitigationLength, (item.mitigation || '').length);
        };

        riskTypeDataForEval.riskTypes.forEach((data) => {
            updateMaxLengths(data);
            if (data.subRisks) {
                data.subRisks.forEach((subRisk) => {
                    updateMaxLengths(subRisk, '-- ');
                    if (subRisk.subRisks) {
                        subRisk.subRisks.forEach((subSubRisk) => {
                            updateMaxLengths(subSubRisk, '---- ');
                            if (subSubRisk.subRisks) {
                                subSubRisk.subRisks.forEach((subSubSubRisk) => {
                                    updateMaxLengths(subSubSubRisk, '------ ');
                                    if (subSubSubRisk.subRisks) {
                                        subSubSubRisk.subRisks.forEach((subSubSubSubRisk) => {
                                            updateMaxLengths(subSubSubSubRisk, '-------- ');  
                                            if (subSubSubSubRisk.subRisks) {
                                                subSubSubSubRisk.subRisks.forEach((subSubSubSubSubRisk) => {
                                                    updateMaxLengths(subSubSubSubSubRisk, '----------');  
                                                    if (subSubSubSubSubRisk.subRisks) {
                                                        subSubSubSubSubRisk.subRisks.forEach((subSubSubSubSuSubbRisk) => {
                                                            updateMaxLengths(subSubSubSubSuSubbRisk, '------------');  
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        worksheet.columns = [
            { header: 'Risk Name', key: 'riskName', width: maxRiskNameLength -5 },
            { header: 'Risk Impact', key: 'riskImpact', width: 10 },
            { header: 'Risk Likelihood', key: 'riskLikelihood', width: 10 },
            { header: 'Risk Level', key: 'riskLevel', width: 10 },
            { header: 'Risk Appetite', key: 'riskAppetite', width: 10 },
            { header: 'Risk Description', key: 'riskDescription', width: maxRiskDescriptionLength - 10 },
            { header: 'Impact Description', key: 'impactDescription', width: maxImpactDescriptionLength - 10 },
            { header: 'Mitigation', key: 'mitigation', width: maxMitigationLength - 10 },
        ];

        const headerRow = worksheet.getRow(1);
        headerRow.values = [
            'Risk Name', 'Risk Impact', 'Risk Likelihood', 'Risk Level',
            'Risk Appetite', 'Risk Description', 'Impact Description', 'Mitigation'
        ];

        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ADD8E6' } 
            };
            cell.font = { bold: true, size: 10 };  
            cell.alignment = { horizontal: 'center' };
        });

        riskTypeDataForEval.riskTypes.forEach((data) => {
            addEmptyRow(worksheet);  
            addRiskRow(worksheet, data, '');  

            if (data.subRisks) {
                data.subRisks.forEach((subRisk) => {
                    addRiskRow(worksheet, subRisk, '-- ');  
                    if (subRisk.subRisks) {
                        subRisk.subRisks.forEach((subSubRisk) => {
                            addRiskRow(worksheet, subSubRisk, '---- ');  
                            if (subSubRisk.subRisks) {
                                subSubRisk.subRisks.forEach((subSubSubRisk) => {
                                    addRiskRow(worksheet, subSubSubRisk, '------ ');  
                                    if (subSubSubRisk.subRisks) {
                                        subSubSubRisk.subRisks.forEach((subSubSubSubRisk) => {
                                            addRiskRow(worksheet, subSubSubSubRisk, '-------- ');  
                                            if (subSubSubSubRisk.subRisks) {
                                                subSubSubSubRisk.subRisks.forEach((subSubSubSubSubRisk) => {
                                                    addRiskRow(worksheet, subSubSubSubSubRisk, '---------- ');  
                                                    if (subSubSubSubSubRisk.subRisks) {
                                                        subSubSubSubSubRisk.subRisks.forEach((subSubSubSubSubSubRisk) => {
                                                            addRiskRow(worksheet, subSubSubSubSubSubRisk, '------------ ');  
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        function addEmptyRow(sheet) {
            const row = sheet.addRow(new Array(8).fill(''));
            row.font = { size: 10 };  
            applyColorsToLastThreeCells(row);
        }

        function addRiskRow(sheet, item, prefix) {
            const row = sheet.addRow([
                `${prefix}${item.name}`,
                parseInt(item.riskImpact) || '',
                parseInt(item.riskLikelihood) || '',
                parseInt(item.riskLevel) || '',
                parseInt(item.riskAppetite) || '',
                item.riskDescription || '',
                item.impactDescription || '',
                item.mitigation || ''
            ]);

            row.font = { size: 10 };

    
            if (prefix && item.riskColor) {
                row.getCell(4).font = {
                    color: { argb: item.riskColor.replace('#', 'FF') },
                    size: 10,  
                    bold: true
                };
            }

   
            if (prefix && item.appetiteColor) {
                row.getCell(5).font = {
                        color: { argb: item.appetiteColor.replace('#', 'FF') },
                        size: 10,  
                        bold: true
                };
            }

            applyColorsToLastThreeCells(row);
        }

        function applyColorsToLastThreeCells(row) {
            row.getCell(6).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFF7D1' }
            };
            row.getCell(7).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFECC8' }
            };
            row.getCell(8).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD09B' }
            };
        }

        const buffer = await workbook.xlsx.writeBuffer();
        let fileName = "data.xlsx";
        if (registerName) {
            fileName = registerName.split(" - ").slice(0, 2).join(" - ") + " - All Roles.xlsx";
        }
        saveAs(new Blob([buffer]), fileName);
    };

    return (
        <div>
            <Button
                className='btnNormal'
                variant='outlined'
                onClick={handleDownload}
            >
                Transfer to Excel File
            </Button>
        </div>
    );
}

export default TransferToExcel1;
