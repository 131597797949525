
import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import useStyle from '../Css/MainCss';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import Draggable from 'react-draggable';
import axios from 'axios';
import { CONFIG } from '../config';
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormLabel from '@mui/material/FormLabel';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Add, AddToPhotosSharp, ArrowUpward, Close, Redo, Refresh, RestartAlt, Restore, RestorePage } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';

import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';


import Select from '@mui/material/Select'; import RiskHierarchy from '../Components/RiskHierarchy';
import GlobalStandard from '../Components/GlobalStandard';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CollectionManagement from '../Components/CollectionManagement';
import CenterMaintainersComponent from '../Components/CenterMaintainersComponent';
import SliderMethodsComponent from '../Components/SliderMethodsComponent';
import AddColorComponent from '../Components/AddColorComponent';
import CustomStandardsComponent from '../Components/CustomStandardsComponent';
import CollectionManagementEdit from '../Components/CollectionManagementEdit';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TransferCollectionComponent from '../Components/TransferCollectionComponent';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AddQuestionComponent from '../Components/AddQuestionComponent';
import AddScales from './AddScales';
import AddScaleComponent from '../Components/AddScaleComponent';
import QuestionerPageComponent from '../Components/QuestionerPageComponent';
import QuestionerPageFromDashBordComponent from '../Components/QuestionerPageFromDashBordComponent';
import EvaluateRisk from '../Pages/EvaluateRisk';
import imgdash from '../assets/risk.jpg'
import CreateEditRiskRegister from './CreateEditRiskRegister';
import RiskHierarchyNew from '../Components/RiskHierarchyNew';
import "../Css/Dashboard.css";
import parseHtml from 'html-react-parser';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ClockLoader } from 'react-spinners';
import InfoIcon from '@mui/icons-material/Info';
import Container from '@mui/material/Container';
import Footer from '../Components/Footer';
import '../Css/MainPge.css'
import { green, red } from '@mui/material/colors';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '13px'
  },
}));





const Dashboard = ({ userRoleId, centreId, name, userId }) => {
  const classes = useStyle();
  /** Sort abject sort array */
  function compare(a, b) {
    var nameA = a.title.toUpperCase(); // ignore upper and lowercase
    var nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
  function compareRiskNo(a, b) {
    var nameA = a.no; // ignore upper and lowercase
    var nameB = b.no; // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  function PaperComponentCollectionData(props) {
    return (
      <Draggable
        handle="#collection-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  function PaperComponentCollectionAmendData(props) {
    return (
      <Draggable
        handle="#collection-amend-data-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


  const [golobalCentreId, setGolobalCentreId] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);

  console.log("++++++++++++++++++++++++++++userRoleId++++++++++++++++++++++++");
  console.log(userRoleId);


  useEffect(() => {
    getLoginPageDetails()
    getCollection();
    getColors();
    getQuestionMethodsForSlider()
    getAnswerMethodsForSlider()
    validateTrailUser();
    loadMultiTableSetup();
    getLoginPageDetails1()

  }, [])

  function reloadAll() {
    setAllCollectionData([]);
    getCollection();
    getColors();
    getQuestionMethodsForSlider()
    getAnswerMethodsForSlider()
    setWaitingOpen(true);
    validateTrailUser();
    loadMultiTableSetup();
    getLoginPageDetails1()
    setTimeout(() => {
      setWaitingOpen(false);
    }, 1000);

  }

  const [pageBackgroundUrl, setPageBackgroundUrl] = React.useState('');
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');

  function getLoginPageDetails1() {
    axios(CONFIG.EXPERT_SERVER_URL + `/loginPageContent/allCurrent`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++Login Page Details +++++++++++++++++");
        console.log(response.data);

        var found = false;
        response.data.forEach((item, index) => {
          if (item.domainAllocatedCentres != undefined && item.domainAllocatedCentres != null && item.domainAllocatedCentres.length > 0) {
            item.domainAllocatedCentres.forEach((data) => {
              if (data.centreId === centreId) {
                if (item.backgroundUrl != undefined && item.backgroundUrl != null) {
                  setPageBackgroundUrl(item.backgroundUrl);
                }
                if (item.backgroundColor != undefined && item.backgroundColor != null) {
                  setPageBackgroundColor(item.backgroundColor);
                }
                sessionStorage.setItem('sessionLogoutUrl', item.domainName);
                found = true
              }
            })
          }
        })
        if (!found) {
          response.data.forEach((item, index) => {
            if (index === 0) {
              if (item.backgroundUrl != undefined && item.backgroundUrl != null) {
                setPageBackgroundUrl(item.backgroundUrl);
              }
              if (item.backgroundColor != undefined && item.backgroundColor != null) {
                setPageBackgroundColor(item.backgroundColor);
              }
              sessionStorage.setItem('sessionLogoutUrl', null);
            }
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   main_page
risk_collection
questionnaire_collections
global_standards
custom_standards
risk_hierarchy
centre_maintenance
risk_slider_methods
risk_slider_colors
iransfer_questionnaire_collection
create_edit_question
create_edit_answer_scale
evaluate_risk
   */
  //Multitasking Page setup 
  //For Main Page
  const [mprtl, setMprtl] = React.useState(0); //mainPageRadiusTopLeft
  const [mprtr, setMprtr] = React.useState(0); //RadiusTopRight
  const [mprbl, setMprbl] = React.useState(0); //RadiusBottomLeft
  const [mprbr, setMprbr] = React.useState(0); //RadiusBottomRight
  const [mpbc, setMpbc] = React.useState('#a3a3a3'); //BorderColor
  const [mpbgc, setMpbgc] = React.useState('#e5eaf4'); //BackGroundColor
  const [mpmfc, setMpmfc] = React.useState('#000000'); //MainFontColor
  const [mpht, setMpht] = React.useState('No Help Text'); //HelpText

  //For risk_collection
  const [rcrtl, setRcrtl] = React.useState(0); //mainPageRadiusTopLeft
  const [rcrtr, setRcrtr] = React.useState(0); //RadiusTopRight
  const [rcrbl, setRcrbl] = React.useState(0); //RadiusBottomLeft
  const [rcrbr, setRcrbr] = React.useState(0); //RadiusBottomRight
  const [rcbc, setRcbc] = React.useState('#4c7ee0'); //BorderColor
  const [rcbgc, setRcbgc] = React.useState('#e5eaf4'); //BackGroundColor
  const [rcmfc, setRcmfc] = React.useState('#000000'); //MainFontColor
  const [rcht, setRcht] = React.useState('No Help Text'); //HelpText


  const [radiusTopLeftmain_page, setRadiusTopLeftmain_page] = useState('');
  const [radiusTopRightmain_page, setRadiusTopRightmain_page] = useState('');
  const [radiusBottomLeftmain_page, setRadiusBottomLeftmain_page] = useState('');
  const [rradiusBottomRightmain_page, setRradiusBottomRightmain_page] = useState('');
  const [backgroundclrmain_page, setbackgroundclrmain_page] = useState('');
  const [borderclrmain_page, setborderclrmain_page] = useState('');
  const [headingFClrmain_page, setheadingFClrmain_page] = useState('');
  const [helpTextmain_page, sethelpTextmain_page] = useState('');

  const [radiusTopLeftrisk_collection, setRadiusTopLeftrisk_collection] = useState('');
  const [radiusTopRightrisk_collection, setRadiusTopRightrisk_collection] = useState('');
  const [radiusBottomLeftrisk_collection, setRadiusBottomLeftrisk_collection] = useState('');
  const [rradiusBottomRightrisk_collection, setRradiusBottomRightrisk_collection] = useState('');
  const [backgroundclrrisk_collection, setbackgroundclrrisk_collection] = useState('');
  const [borderclrrisk_collection, setborderclrrisk_collection] = useState('');
  const [headingFClrrisk_collection, setheadingFClrrisk_collection] = useState('');
  const [helpTextrisk_collection, sethelpTextrisk_collection] = useState('');

  const [radiusTopLeftquestionnaire_collections, setRadiusTopLeftquestionnaire_collections] = useState('');
  const [radiusTopRightquestionnaire_collections, setRadiusTopRightquestionnaire_collections] = useState('');
  const [radiusBottomLeftquestionnaire_collections, setRadiusBottomLeftquestionnaire_collections] = useState('');
  const [rradiusBottomRightquestionnaire_collections, setRradiusBottomRightquestionnaire_collections] = useState('');
  const [backgroundclrquestionnaire_collections, setbackgroundclrquestionnaire_collections] = useState('');
  const [borderclrquestionnaire_collections, setborderclrquestionnaire_collections] = useState('');
  const [headingFClrquestionnaire_collections, setheadingFClrquestionnaire_collections] = useState('');
  const [helpTextquestionnaire_collections, sethelpTextquestionnaire_collections] = useState('');

  const [radiusTopLeftglobal_standards, setRadiusTopLeftglobal_standards] = useState('');
  const [radiusTopRightglobal_standards, setRadiusTopRightglobal_standards] = useState('');
  const [radiusBottomLeftglobal_standards, setRadiusBottomLeftglobal_standards] = useState('');
  const [rradiusBottomRightglobal_standards, setRradiusBottomRightglobal_standards] = useState('');
  const [backgroundclglobal_standards, setbackgroundclrglobal_standards] = useState('');
  const [borderclrglobal_standards, setborderclrglobal_standards] = useState('');
  const [headingFClrglobal_standards, setheadingFClrglobal_standards] = useState('');
  const [helpTextglobal_standards, sethelpTextglobal_standards] = useState('');




  const [radiusTopLeftcustom_standards, setRadiusTopLeftcustom_standards] = useState('');
  const [radiusTopRightcustom_standards, setRadiusTopRightcustom_standards] = useState('');
  const [radiusBottomLeftcustom_standards, setRadiusBottomLeftcustom_standards] = useState('');
  const [rradiusBottomRightcustom_standards, setRradiusBottomRightcustom_standards] = useState('');
  const [backgroundclrcustom_standards, setbackgroundclrcustom_standards] = useState('');
  const [borderclrcustom_standards, setborderclrcustom_standards] = useState('');
  const [headingFClrcustom_standards, setheadingFClrcustom_standards] = useState('');
  const [helpTextcustom_standards, sethelpTextcustom_standards] = useState('');

  const [radiusTopLeftrisk_hierarchy, setRadiusTopLeftrisk_hierarchy] = useState('');
  const [radiusTopRightrisk_hierarchy, setRadiusTopRightrisk_hierarchy] = useState('');
  const [radiusBottomLeftrisk_hierarchy, setRadiusBottomLeftrisk_hierarchy] = useState('');
  const [rradiusBottomRightrisk_hierarchy, setRradiusBottomRightrisk_hierarchy] = useState('');
  const [backgroundclrrisk_hierarchy, setbackgroundclrrisk_hierarchy] = useState('');
  const [borderclrrisk_chierarchy, setborderclrrisk_hierarchy] = useState('');
  const [headingFClrrisk_hierarchy, setheadingFClrrisk_hierarchy] = useState('');
  const [helpTextrisk_hierarchy, sethelpTextrisk_hierarchy] = useState('');

  const [radiusTopLeftcentre_maintenance, setRadiusTopLeftcentre_maintenance] = useState('');
  const [radiusTopRightcentre_maintenance, setRadiusTopRightcentre_maintenance] = useState('');
  const [radiusBottomLeftcentre_maintenance, setRadiusBottomLeftcentre_maintenance] = useState('');
  const [rradiusBottomRightcentre_maintenance, setRradiusBottomRightcentre_maintenance] = useState('');
  const [backgroundclrcentre_maintenance, setbackgroundclrcentre_maintenance] = useState('');
  const [borderclrcentre_maintenance, setborderclrcentre_maintenance] = useState('');
  const [headingFClrcentre_maintenance, setheadingFClrcentre_maintenance] = useState('');
  const [helpTextcentre_maintenance, sethelpTextcentre_maintenance] = useState('');

  const [radiusTopLeftrisk_slider_methods, setRadiusTopLeftrisk_slider_methods] = useState('');
  const [radiusTopRightrisk_slider_methods, setRadiusTopRightrisk_slider_methods] = useState('');
  const [radiusBottomLeftrisk_slider_methods, setRadiusBottomLeftrisk_slider_methods] = useState('');
  const [rradiusBottomRightrisk_slider_methods, setRradiusBottomRightrisk_slider_methods] = useState('');
  const [backgroundclrisk_slider_methods, setbackgroundclrrisk_slider_methods] = useState('');
  const [borderclrrisk_slider_methods, setborderclrrisk_slider_methods] = useState('');
  const [headingFClrrisk_slider_methods, setheadingFClrgrisk_slider_methods] = useState('');
  const [helpTextrisk_slider_methods, sethelpTextrisk_slider_methods] = useState('');



  ///

  const [radiusTopLeftRSC, setRadiusTopLeftRSC] = useState('');
  const [radiusTopRightRSC, setRadiusTopRightRSC] = useState('');
  const [radiusBottomLeftRSC, setRadiusBottomLeftRSC] = useState('');
  const [rradiusBottomRightRSC, setRradiusBottomRightRSC] = useState('');
  const [backgroundclRSC, setbackgroundclrRSC] = useState('');
  const [borderclrRSC, setborderclrRSC] = useState('');
  const [headingFClrRSC, setheadingFClrgRSC] = useState('');
  const [helpTextRSC, sethelpTextRSC] = useState('');

  const [radiusTopLeftTQC, setRadiusTopLeftTQC] = useState('');
  const [radiusTopRightTQC, setRadiusTopRightTQC] = useState('');
  const [radiusBottomLeftTQC, setRadiusBottomLeftTQC] = useState('');
  const [rradiusBottomRightTQC, setRradiusBottomRightTQC] = useState('');
  const [backgroundclTQC, setbackgroundclrTQC] = useState('');
  const [borderclrTQC, setborderclrTQC] = useState('');
  const [headingFClrTQC, setheadingFClrgTQC] = useState('');
  const [helpTextTQC, sethelpTextTQC] = useState('');

  const [radiusTopLeftCEQ, setRadiusTopLeftCEQ] = useState('');
  const [radiusTopRightCEQ, setRadiusTopRightCEQ] = useState('');
  const [radiusBottomLeftCEQ, setRadiusBottomLeftCEQ] = useState('');
  const [rradiusBottomRightCEQ, setRradiusBottomRightCEQ] = useState('');
  const [backgroundclCEQ, setbackgroundclrCEQ] = useState('');
  const [borderclrCEQ, setborderclrCEQ] = useState('');
  const [headingFClrCEQ, setheadingFClrgCEQ] = useState('');
  const [helpTextCEQ, sethelpTextCEQ] = useState('');

  const [radiusTopLeftCEAS, setRadiusTopLeftCEAS] = useState('');
  const [radiusTopRightCEAS, setRadiusTopRightCEAS] = useState('');
  const [radiusBottomLeftCEAS, setRadiusBottomLeftCEAS] = useState('');
  const [rradiusBottomRightCEAS, setRradiusBottomRightCEAS] = useState('');
  const [backgroundclCEAS, setbackgroundclrCEAS] = useState('');
  const [borderclrCEAS, setborderclrCEAS] = useState('');
  const [headingFClrCEAS, setheadingFClrgCEAS] = useState('');
  const [helpTextCEAS, sethelpTextCEAS] = useState('');

  const [radiusTopLeftER, setRadiusTopLeftER] = useState('');
  const [radiusTopRightER, setRadiusTopRightER] = useState('');
  const [radiusBottomLeftER, setRadiusBottomLeftER] = useState('');
  const [rradiusBottomRightER, setRradiusBottomRightER] = useState('');
  const [backgroundclER, setbackgroundclrER] = useState('');
  const [borderclrER, setborderclrER] = useState('');
  const [headingFClrER, setheadingFClrgER] = useState('');
  const [helpTextER, sethelpTextER] = useState('');



  function loadMultiTableSetup() {
    axios(CONFIG.MAIN_URL + `/multitaskPageSetup/filter?centreId=` + centreId, {
      method: 'get',
    })
      .then(function (response) {
        console.log("++++MultiTask Page setup Details +++++++++++++++++");
        console.log(response.data);
        response.data.forEach((item, index) => {
          if (index === 0) {
            if (item.multitaskTables != undefined && item.multitaskTables != null && item.multitaskTables.length > 0) {
              item.multitaskTables.forEach((multi) => {
                if (multi.pageId === 'main_page') {
                  setRadiusTopLeftmain_page(multi.radiusTopLeft)
                  setRadiusTopRightmain_page(multi.radiusTopRight)
                  setRadiusBottomLeftmain_page(multi.radiusBottomLeft)
                  setRradiusBottomRightmain_page(multi.radiusBottomRight)
                  setbackgroundclrmain_page(multi.backgroundColor)
                  setborderclrmain_page(multi.borderColor)
                  setheadingFClrmain_page(multi.mainHeaderFontColor)
                  sethelpTextmain_page(multi.helpText)

                }
                if (multi.pageId === 'risk_collection') {
                  setRadiusTopLeftrisk_collection(multi.radiusTopLeft)
                  setRadiusTopRightrisk_collection(multi.radiusTopRight)
                  setRadiusBottomLeftrisk_collection(multi.radiusBottomLeft)
                  setRradiusBottomRightrisk_collection(multi.radiusBottomRight)
                  setbackgroundclrrisk_collection(multi.backgroundColor)
                  setborderclrrisk_collection(multi.borderColor)
                  setheadingFClrrisk_collection(multi.mainHeaderFontColor)
                  sethelpTextrisk_collection(multi.helpText)
                }
                if (multi.pageId === 'questionnaire_collections') {
                  setRadiusTopLeftquestionnaire_collections(multi.radiusTopLeft)
                  setRadiusTopRightquestionnaire_collections(multi.radiusTopRight)
                  setRadiusBottomLeftquestionnaire_collections(multi.radiusBottomLeft)
                  setRradiusBottomRightquestionnaire_collections(multi.radiusBottomRight)
                  setbackgroundclrquestionnaire_collections(multi.backgroundColor)
                  setborderclrquestionnaire_collections(multi.borderColor)
                  setheadingFClrquestionnaire_collections(multi.mainHeaderFontColor)
                  sethelpTextquestionnaire_collections(multi.helpText)
                }

                if (multi.pageId === 'global_standards') {
                  setRadiusTopLeftglobal_standards(multi.radiusTopLeft)
                  setRadiusTopRightglobal_standards(multi.radiusTopRight)
                  setRadiusBottomLeftglobal_standards(multi.radiusBottomLeft)
                  setRradiusBottomRightglobal_standards(multi.radiusBottomRight)
                  setbackgroundclrglobal_standards(multi.backgroundColor)
                  setborderclrglobal_standards(multi.borderColor)
                  setheadingFClrglobal_standards(multi.mainHeaderFontColor)
                  sethelpTextglobal_standards(multi.helpText)
                }

                if (multi.pageId === 'custom_standards') {
                  setRadiusTopLeftcustom_standards(multi.radiusTopLeft)
                  setRadiusTopRightcustom_standards(multi.radiusTopRight)
                  setRadiusBottomLeftcustom_standards(multi.radiusBottomLeft)
                  setRradiusBottomRightcustom_standards(multi.radiusBottomRight)
                  setbackgroundclrcustom_standards(multi.backgroundColor)
                  setborderclrcustom_standards(multi.borderColor)
                  setheadingFClrcustom_standards(multi.mainHeaderFontColor)
                  sethelpTextcustom_standards(multi.helpText)
                }

                if (multi.pageId === 'risk_hierarchy') {
                  setRadiusTopLeftrisk_hierarchy(multi.radiusTopLeft)
                  setRadiusTopRightrisk_hierarchy(multi.radiusTopRight)
                  setRadiusBottomLeftrisk_hierarchy(multi.radiusBottomLeft)
                  setRradiusBottomRightrisk_hierarchy(multi.radiusBottomRight)
                  setbackgroundclrrisk_hierarchy(multi.backgroundColor)
                  setborderclrrisk_hierarchy(multi.borderColor)
                  setheadingFClrrisk_hierarchy(multi.mainHeaderFontColor)
                  sethelpTextrisk_hierarchy(multi.helpText)
                }

                if (multi.pageId === 'centre_maintenance') {
                  setRadiusTopLeftcentre_maintenance(multi.radiusTopLeft)
                  setRadiusTopRightcentre_maintenance(multi.radiusTopRight)
                  setRadiusBottomLeftcentre_maintenance(multi.radiusBottomLeft)
                  setRradiusBottomRightcentre_maintenance(multi.radiusBottomRight)
                  setbackgroundclrcentre_maintenance(multi.backgroundColor)
                  setborderclrcentre_maintenance(multi.borderColor)
                  setheadingFClrcentre_maintenance(multi.mainHeaderFontColor)
                  sethelpTextcentre_maintenance(multi.helpText)
                }
                if (multi.pageId === 'risk_slider_methods') {
                  setRadiusTopLeftrisk_slider_methods(multi.radiusTopLeft)
                  setRadiusTopRightrisk_slider_methods(multi.radiusTopRight)
                  setRadiusBottomLeftrisk_slider_methods(multi.radiusBottomLeft)
                  setRradiusBottomRightrisk_slider_methods(multi.radiusBottomRight)
                  setbackgroundclrrisk_slider_methods(multi.backgroundColor)
                  setborderclrrisk_slider_methods(multi.borderColor)
                  setheadingFClrgrisk_slider_methods(multi.mainHeaderFontColor)
                  sethelpTextrisk_slider_methods(multi.helpText)
                }
                if (multi.pageId === 'risk_slider_colors') {
                  setRadiusTopLeftRSC(multi.radiusTopLeft)
                  setRadiusTopRightRSC(multi.radiusTopRight)
                  setRadiusBottomLeftRSC(multi.radiusBottomLeft)
                  setRradiusBottomRightRSC(multi.radiusBottomRight)
                  setbackgroundclrRSC(multi.backgroundColor)
                  setborderclrRSC(multi.borderColor)
                  setheadingFClrgRSC(multi.mainHeaderFontColor)
                  sethelpTextRSC(multi.helpText)
                }
                if (multi.pageId === 'transfer_questionnaire_collection') {
                  setRadiusTopLeftTQC(multi.radiusTopLeft)
                  setRadiusTopRightTQC(multi.radiusTopRight)
                  setRadiusBottomLeftTQC(multi.radiusBottomLeft)
                  setRradiusBottomRightTQC(multi.radiusBottomRight)
                  setbackgroundclrTQC(multi.backgroundColor)
                  setborderclrTQC(multi.borderColor)
                  setheadingFClrgTQC(multi.mainHeaderFontColor)
                  sethelpTextTQC(multi.helpText)
                }
                if (multi.pageId === 'create_edit_question') {
                  setRadiusTopLeftCEQ(multi.radiusTopLeft)
                  setRadiusTopRightCEQ(multi.radiusTopRight)
                  setRadiusBottomLeftCEQ(multi.radiusBottomLeft)
                  setRradiusBottomRightCEQ(multi.radiusBottomRight)
                  setbackgroundclrCEQ(multi.backgroundColor)
                  setborderclrCEQ(multi.borderColor)
                  setheadingFClrgCEQ(multi.mainHeaderFontColor)
                  sethelpTextCEQ(multi.helpText)
                }
                if (multi.pageId === 'create_edit_answer_scale') {
                  setRadiusTopLeftCEAS(multi.radiusTopLeft)
                  setRadiusTopRightCEAS(multi.radiusTopRight)
                  setRadiusBottomLeftCEAS(multi.radiusBottomLeft)
                  setRradiusBottomRightCEAS(multi.radiusBottomRight)
                  setbackgroundclrCEAS(multi.backgroundColor)
                  setborderclrCEAS(multi.borderColor)
                  setheadingFClrgCEAS(multi.mainHeaderFontColor)
                  sethelpTextCEAS(multi.helpText)
                }
                if (multi.pageId === 'evaluate_risk') {
                  setRadiusTopLeftER(multi.radiusTopLeft)
                  setRadiusTopRightER(multi.radiusTopRight)
                  setRadiusBottomLeftER(multi.radiusBottomLeft)
                  setRradiusBottomRightER(multi.radiusBottomRight)
                  setbackgroundclrER(multi.backgroundColor)
                  setborderclrER(multi.borderColor)
                  setheadingFClrgER(multi.mainHeaderFontColor)
                  sethelpTextER(multi.helpText)
                }

              })
            }
          }
        })

      })
      .catch(function (error) {
        console.log(error);
      });

  }





  // dashboard description 

  const [loading1, setLoading1] = React.useState(false);

  const [pageTopHtml, setPageTopHtml] = React.useState('');

  const [dbContentDisplay, setDbContentDisplay] = React.useState(false);

  const [loadingDisplay, setLoadingDisplay] = React.useState(true);
  const [displayTrialingMsg, setDisplayTrialingMsg] = React.useState("");

  const [displayMultitaskingMenuInHome, setDisplayMultitaskingMenuInHome] = React.useState(false);

  function compareEndDate(a, b) {
    var nameA = a.endDate; // ignore upper and lowercase
    var nameB = b.endDate; // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  const [menuNotAvailableMeg, setMenuNotAvailableMeg] = useState("");
  const [message1, setMessage1] = useState("You are not authorized to access this link.");
  const [message2, setMessage2] = useState("You are not authorized to access this site. Please contact administrator.");
  const [message3, setMessage3] = useState("Your Trialing period is over. Please contact administrator.");

  function validateTrailUser() {
    //if (CONFIG.TRIAL_CENTRE_ID === centreId) {
    axios(CONFIG.MAIN_URL + `/mainMenuSetup/filter?centreId=` + centreId, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        var mainMenuObj = {};
        var mainMenuFound = false;
        if (response.data.length === 0) {
          setMenuNotAvailableMeg(message1);
          setDisplayTrialingMsg(message2)
        }
        response.data.forEach((itemA, indexA) => {
          if (indexA === 0) {
            mainMenuFound = true;
            mainMenuObj = itemA;
          }
        })
        if (mainMenuFound) {
          mainMenuObj.entityMenus.forEach((itemX) => {
            if (userRoleId === itemX.iamRole) {
              var arr = itemX.menus;
              if (arr.length === 0) {
                setMenuNotAvailableMeg(message1);
                setDisplayTrialingMsg(message2)
              }
              axios(CONFIG.MAIN_URL + `/trialCentreSetup/filter?centreId=` + centreId, {
                method: 'get',
              })
                .then(function (response) {
                  console.log("++++Trial Centre setup DetailsXXXXX ++++++++++++++++++++++++++++");
                  console.log(response.data);
                  var trialDays = 0;
                  response.data.forEach((item, index) => {
                    if (index === 0) {
                      trialDays = item.noOfTrialDays
                      if (item.displayMultitaskingMenuAtHome != undefined && item.displayMultitaskingMenuAtHome != null && item.displayMultitaskingMenuAtHome === "yes") {
                        setDisplayMultitaskingMenuInHome(true);
                      }
                    }
                  })

                  if (trialDays > 0) {
                    setTimeout(() => {
                      axios(CONFIG.MAIN_URL + `/trialUsers/filter?userCode=` + userId, {
                        method: 'get',
                      })
                        .then(function (response) {
                          console.log("++++Trial User setup DetailsXXXXXXXX +++++++++++++++++");
                          console.log(response.data);
                          response.data.forEach((item, index) => {
                            if (index === 0) {
                              console.log(item);
                              if (item.trialDays != undefined && item.trialDays != null && item.trialDays.length > 0) {
                                var trilDayArr = item.trialDays;
                                trilDayArr = trilDayArr.sort(compareEndDate);
                                console.log("++++XXXXXXXXXXXX trilDayArr XXXXXXXXXXXXXXXXXXXXXXXXXXXXX +++++++++++++++++");
                                console.log(trilDayArr);
                                var tObject = {}
                                trilDayArr.forEach((itemDay) => {
                                  tObject = itemDay;
                                });
                                if (tObject.endDate != undefined && tObject.endDate != null) {
                                  var startDate = new Date(tObject.amendDate);
                                  var endDate = new Date(tObject.endDate);
                                  var currentDate = new Date();
                                  var DifferenceInTime = endDate.getTime() - currentDate.getTime();
                                  var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
                                  if (DifferenceInDays > 0) {
                                    var daysRemaining = DifferenceInDays
                                    var tArr2 = daysRemaining.toString().split(".");
                                    var dayCount = parseInt(tArr2[0]);
                                    if (tArr2.length > 1) {
                                      if (parseFloat("0." + tArr2[1]) > 0.5) {
                                        dayCount = dayCount + 1;
                                      }
                                    }

                                    setDisplayTrialingMsg("Your have " + dayCount + " trial days from " + startDate.toLocaleDateString('en-GB'))
                                  } else {
                                    setDisplayTrialingMsg("You have 0 trial days from the " + startDate.toLocaleDateString('en-GB'));
                                    setMenuNotAvailableMeg(message1);
                                  }
                                }
                              }

                            }
                          })
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    }, 500);


                  } else {
                    setMenuNotAvailableMeg(message1);
                    setDisplayTrialingMsg(message3)
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });











            }
          })
        } else {
          setMenuNotAvailableMeg(message1);
          setDisplayTrialingMsg(message2)
        }
      })
      .catch(function (error) {
        console.log("++++++++++++++++++Error+++++++++++++++++++");
        console.log(error);
      });



    //}
  }


  function getLoginPageDetails() {
    setLoading1(true)

    axios(CONFIG.EXPERT_SERVER_URL + `/homePageContent/filter?centreId=` + centreId + `&versionNumber=current`, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("++++home page detail xxxxxxxxxxxxxxxxxXXXXXXXXXXXXX +++++++++++++++++");
        console.log(response.data);
        var foundCentre = false;
        response.data.forEach((item, index) => {
          if (index === 0) {
            if (item.pageContent != undefined && item.pageContent != null) {
              setDbContentDisplay(true)
              foundCentre = true;
              //alert(item.pageContent)
              var tArr = item.pageContent.split("<body>");
              if (tArr.length > 1) {
                var tArr1 = tArr[1].split("</body>");
                //alert(tArr1[0])
                console.log(tArr1[0].replaceAll("\\n", ''))
                setPageTopHtml(tArr1[0].replaceAll("\\n", ''));
              } else {
                if (item.pageContent != "") {
                  setPageTopHtml(item.pageContent.replaceAll("\\n", ''));
                }
              }
            }
          }
        })
        if (!foundCentre) {
          setDbContentDisplay(true);
        }

        setLoading1(false)

      })
      .catch(function (error) {
        setLoading1(false)
        setDbContentDisplay(true);
        console.log(error);
      });
  }

  const [databClr, setDatabClr] = useState([])
  const [patternData, setPtternData] = useState([])
  function getColors() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_COLOR + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {
        console.log("All colors");
        console.log(response.data);
        setPtternData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getRiskLvlColor(lvlData, methodId) {
    var colorCode = ''
    var tmpPatId = '';
    var col = [];
    questionSliderMethods.forEach((data) => {
      if (methodId === data.id) {
        tmpPatId = data.colorPatternId;
        patternData.forEach((item) => {
          if (tmpPatId === item.id) {
            col = item.colors;
          }
        })
      }
    })
    col.forEach((data) => {
      if (parseInt(data.scoreNumber) === parseInt(lvlData)) {
        colorCode = data.color;
      }
    })
    return colorCode;
  }

  function getRiskLvlColorForAnswer(lvlData, methodId, colCode) {
    var colorCode = ''

    if (colCode != '') {
      colorCode = colCode;
    } else {
      var col = [];
      answerSliderMethods.forEach((data) => {
        if (data.id === methodId) {
          var tmpPatId = data.colorPatternId;
          patternData.forEach((item) => {
            if (tmpPatId === item.id) {
              col = item.colors;
            }
          })
        }
      })
      col.forEach((data) => {
        if (parseInt(data.scoreNumber) === parseInt(lvlData)) {
          colorCode = data.color;
        }
      })
    }
    return colorCode;
  }




  function getData() {
    let data = sessionStorage.getItem('sessionstorage');
    data = JSON.parse(data);
    console.log(data)
    setGolobalCentreId(data.centreId);
    getCollection(data.centreId)
    setUserName(data.name)
    console.log(data.name)
  }

  const [questionSliderMethods, setQuestionSliderMethods] = useState([])
  const [answerSliderMethods, setAnswerSliderMethods] = useState([])

  function getQuestionMethodsForSlider() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_QUESTION + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {

        console.log("All Risk Returns");
        console.log(response.data[0]);
        setQuestionSliderMethods(response.data[0].methods)
        //setQuestionCategoryId(response.data[0].id)
        // console.log(response.data[0].methods);

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  function getAnswerMethodsForSlider() {
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME_FOR_SLIDER_ANSWER + "/all", {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then(function (response) {

        console.log("All Risk Returns");
        console.log(response.data[0]);
        setAnswerSliderMethods(response.data[0].methods)
        //setQuestionCategoryId(response.data[0].id)
        // console.log(response.data[0].methods);

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  const [openRiskCollectionZIndex, setOpenRiskCollectionZIndex] = React.useState(1);
  const [openCollectionDataZIndex, setOpenCollectionDataZIndex] = React.useState(1);
  const [openExistingRiskCollectionZIndex, setOpenExistingRiskCollectionZIndex] = React.useState(1);
  const [openRiskHierarchyZIndex, setOpenRiskHierarchyZIndex] = React.useState(1);
  const [openRiskHierarchyZIndexPrevious, setOpenRiskHierarchyZIndexPrevious] = React.useState(1);
  const [openGlobalStandardZIndex, setOpenGlobalStandardZIndex] = React.useState(1);
  const [openCreateNewCollectionZIndex, setOpenCreateNewCollectionZIndex] = React.useState(1);
  const [openCreateNewCollectionZIndexPrevious, setOpenCreateNewCollectionZIndexPrevious] = React.useState(1);
  const [openEditCollectionZIndex, setOpenEditCollectionZIndex] = React.useState(1);
  const [openEditCollectionZIndexPrevious, setOpenEditCollectionZIndexPrevious] = React.useState(1);
  const [openAddColorZIndex, setOpenAddColorZIndex] = React.useState(1);
  const [openSliderMethodsZIndex, setOpenSliderMethodsZIndex] = React.useState(1);
  const [openCustomStandardsZIndex, setOpenCustomStandardsZIndex] = React.useState(1);
  const [openCentreMaintenanceZIndex, setOpenCentreMaintenanceZIndex] = React.useState(1);
  const [openCommentZIndex, setOpenCommentZIndex] = React.useState(1);
  const [openTransferQuestionnaireZIndex, setOpenTransferQuestionnaireZIndex] = React.useState(1);
  const [openAddQuestionZIndex, setOpenAddQuestionZIndex] = React.useState(1);
  const [openAnswerScaleZIndex, setOpenAnswerScaleZIndex] = React.useState(1);
  const [openEvaluaterRiskZIndex, setOpenEvaluaterRiskZIndex] = React.useState(1);
  const [openRiskRegisterZIndex, setOpenRiskRegisterZIndex] = React.useState(1);
  const [openQuestionerFromDashBordZIndex, setOpenQuestionerFromDashBordZIndex] = React.useState(1);



  const [openCommentPos, setOpenCommentPos] = useState(-25);


  const [isActiveDivOpenComment, setIsActiveDivOpenComment] = React.useState(true);
  const [isActiveDivOpenRiskCollection, setIsActiveDivOpenRiskCollection] = React.useState(true);
  const [isActiveDivOpenCollectionData, setIsActiveDivOpenCollectionData] = React.useState(true);
  const [isActiveDivOpenRiskHierarchy, setIsActiveDivOpenRiskHierarchy] = React.useState(true);
  const [isActiveDivOpenGlobalStandard, setIsActiveDivOpenGlobalStandard] = React.useState(true);
  const [isActiveDivOpenCreateNewCollection, setIsActiveDivOpenCreateNewCollection] = React.useState(true);
  const [isActiveDivOpenEditCollection, setIsActiveDivOpenEditCollection] = React.useState(true);
  const [isActiveDivOpenAddColor, setIsActiveDivOpenAddColor] = React.useState(true);
  const [isActiveDivOpenSliderMethods, setIsActiveDivOpenSliderMethods] = React.useState(true);
  const [isActiveDivOpenCustomStandards, setIsActiveDivOpenCustomStandards] = React.useState(true);
  const [isActiveDivOpenCentreMaintenance, setIsActiveDivOpenCentreMaintenance] = React.useState(true);
  const [isActiveDivOpenTransferQuestionnaire, setIsActiveDivOpenTransferQuestionnaire] = React.useState(true);
  const [isActiveDivOpenAddQuestion, setIsActiveDivOpenAddQuestion] = React.useState(true);
  const [isActiveDivOpenAnswerScale, setIsActiveDivOpenAnswerScale] = React.useState(true);
  const [isActiveDivOpenEvaluaterRisk, setIsActiveDivOpenEvaluaterRisk] = React.useState(true);
  const [isActiveDivOpenRiskRegister, setIsActiveDivOpenRiskRegister] = React.useState(true);
  const [isActiveDivOpenQuestionerFromDashBord, setIsActiveDivOpenQuestionerFromDashBord] = React.useState(true);

  const [isActiveBtnOpenRiskCollection, setIsActiveBtnOpenRiskCollection] = React.useState(true);
  const [isActiveBtnOpenCreateNewCollection, setIsActiveBtnOpenCreateNewCollection] = React.useState(true);
  const [isActiveMenuIcon, setIsActiveMenuIcon] = React.useState(true);

  const [divCurrentLeftPos, setDivCurrentLeftPos] = React.useState(1);
  const [divCurrentTopPos, setDivCurrentTopPos] = React.useState(1);
  const [noOfDivsOpen, setNoOfDivsOpen] = React.useState(0);

  /******************************************** */
  function disabledOtherDivs(divId) {
    if (divId === 'divOpenEditCollection') {
      setIsActiveDivOpenComment(false);
      setIsActiveDivOpenRiskCollection(false);
      setIsActiveDivOpenCollectionData(false);
      setIsActiveDivOpenRiskHierarchy(false);
      setIsActiveDivOpenGlobalStandard(false);
      setIsActiveDivOpenCreateNewCollection(false);
      //setIsActiveDivOpenEditCollection(false);
      setIsActiveDivOpenAddColor(false);
      setIsActiveDivOpenSliderMethods(false);
      setIsActiveDivOpenCustomStandards(false);
      setIsActiveDivOpenCentreMaintenance(false);
      setIsActiveDivOpenTransferQuestionnaire(false);
      setIsActiveDivOpenAddQuestion(false)
      setIsActiveDivOpenAnswerScale(false)
      setIsActiveDivOpenEvaluaterRisk(false)
      setIsActiveDivOpenRiskRegister(false)
      setIsActiveDivOpenQuestionerFromDashBord(false)
      setIsActiveBtnOpenRiskCollection(false);
      setIsActiveBtnOpenCreateNewCollection(false);
      setIsActiveMenuIcon(false);

    }
  }
  function enabledOtherDivs() {
    setIsActiveDivOpenComment(true);
    setIsActiveDivOpenRiskCollection(true);
    setIsActiveDivOpenCollectionData(true);
    setIsActiveDivOpenRiskHierarchy(true);
    setIsActiveDivOpenGlobalStandard(true);
    setIsActiveDivOpenCreateNewCollection(true);
    setIsActiveDivOpenEditCollection(true);
    setIsActiveDivOpenAddColor(true);
    setIsActiveDivOpenSliderMethods(true);
    setIsActiveDivOpenCustomStandards(true);
    setIsActiveDivOpenCentreMaintenance(true);
    setIsActiveDivOpenTransferQuestionnaire(true);
    setIsActiveDivOpenAddQuestion(true)
    setIsActiveDivOpenAnswerScale(true)
    setIsActiveDivOpenEvaluaterRisk(true)
    setIsActiveDivOpenRiskRegister(true)
    setIsActiveDivOpenQuestionerFromDashBord(true)
    setIsActiveBtnOpenRiskCollection(true);
    setIsActiveBtnOpenCreateNewCollection(true);
    setIsActiveMenuIcon(true);
  }






  /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
  const [allCollectionData, setAllCollectionData] = React.useState([]);
  function getCollection() {
    console.log("++++++++++++++centreId++++++++++++++++");
    console.log(centreId);
    console.log(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME + '/all?centreId=');
    axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME + '/all?centreId=' + centreId, {
      method: 'get',
    })
      .then(function (response) {
        console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
        console.log((response.data));
        console.log(("+++++++++++++++++++++All Collection in A Center +++++++++++++++++++++"));
        var sortArr = [];
        response.data.forEach((item) => {
          if (item.centreId === centreId) {
            sortArr.push(item);
          }
        });


        var sortArr = sortArr.sort(compare);
        //var sortArr = response.data;
        console.log((sortArr));
        setAllCollectionData([]);
        findCollectionWithQuestions(sortArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function findCollectionWithQuestions(collections) {

    collections.forEach((item) => {
      var collectionData = {};
      collectionData["id"] = item.id;
      collectionData["documentCreator"] = item.documentCreator;
      collectionData["collectionUniqueName"] = item.collectionUniqueName;
      collectionData["title"] = item.title;
      collectionData["description"] = item.description;
      collectionData["imageUrl"] = item.imageUrl;
      collectionData["status"] = item.status;
      if (item.documentCreator === "user") {
        setUserLoggedIn(true);
      }

      var tmpRiskArr = [];
      if (item.risks != undefined && item.risks != null && item.risks.length > 0) {
        collectionData["risks"] = item.risks;
        tmpRiskArr = item.risks;
      }
      getAllQuestions(tmpRiskArr, collectionData);
    });

  }
  var allQuesArray = [];
  function getAllQuestions(riskArray, collectionData) {
    allQuesArray = [];
    riskArray.forEach((item) => {
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1) => {
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0) {
            item1.questions.forEach((item2) => {
              allQuesArray.push(item2);
            })
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
            getAllQuestionsInSubRisks(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0) {
      //var quesArray = allQuesArray.sort (compare);
      collectionData["questions"] = allQuesArray;
    } else {
      collectionData["questions"] = [];
    }
    allCollectionData.push(collectionData);
    var tmpCollectionData = allCollectionData;
    setAllCollectionData([]);
    var arr = [];
    var arrId = [];
    tmpCollectionData.forEach((itemData) => {
      var canTake = true;
      arrId.forEach((itemId, indexItem) => {
        if (itemId === itemData.id) {
          arrId.splice(indexItem, 1);
          arr.splice(indexItem, 1);
          //canTake = false;
        }
      })
      if (canTake) {
        arrId.push(itemData.id);
        arr.push(itemData);
      }
    })
    setAllCollectionData(arr);

    //setAllCollectionData(allCollectionData);
    console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");
    console.log(allCollectionData);
    console.log("+++++++++++++++++++++++++allCollectionData++++++++++++++++");

  }

  function getAllQuestionsInSubRisks(riskArray) {
    riskArray.forEach((item) => {
      if (item.questions != undefined && item.questions != null && item.questions.length > 0) {
        item.questions.forEach((item1) => {
          allQuesArray.push(item1);
        })
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        getAllQuestionsInSubRisks(item.subRisks);
      }
    });
  }

  function addToTop(divId) {
    var addAmount = 14;
    var addAmountLeft = 8;
    var plusAmount = 40;
    var plusAmountLeft = 20;
    var tmpDivOpen = 0;

    if (!openRiskCollection && !openCollectionData && !openExistingRiskCollection && !openRiskHierarchy && !openGlobalStandard && !openCreateNewCollection && !openEditCollection && !centreMaintenance && !openAddColor && !openTransferQuestionnaire && !openCustomStandards && !opensliderMethods && !openAddQuestion && !openAnswerScale && !openEvaluaterRisk && !openRiskRegister) {
      tmpDivOpen = 0;
    } else {
      tmpDivOpen = noOfDivsOpen;
    }

    if (tmpDivOpen === 0) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 1) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 2) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 3) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 4) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 5) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 6) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 7) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 8) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 9) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 10) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 11) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 12) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 13) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 14) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 15) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }

    if (tmpDivOpen === 16) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 17) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 18) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }
    if (tmpDivOpen === 19) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
      addAmountLeft = addAmountLeft + (plusAmountLeft * tmpDivOpen);
    }

    setNoOfDivsOpen(tmpDivOpen + 1);

    if (divId === "divOpenRiskCollection") {
      setOpenRiskCollectionOriginalTop(addAmount);
      setOpenRiskCollectionOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenCreateNewCollection") {
      setOpenCreateNewCollectionOriginalTop(addAmount);
      setOpenCreateNewCollectionOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenCollectionData") {
      setOpenCollectionDataOriginalTop(addAmount);
      setOpenCollectionDataOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenRiskHierarchy") {
      setOpenRiskHierarchyOriginalTop(addAmount);
      setOpenRiskHierarchyOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenGlobalStandard") {
      setOpenGlobalStandardOriginalTop(addAmount);
      setOpenGlobalStandardOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenEditCollection") {
      setOpenEditCollectionOriginalTop(addAmount);
      setOpenEditCollectionOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenAddColor") {
      setOpenAddColorOriginalTop(addAmount);
      setOpenAddColorOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenSliderMethods") {
      setOpensliderMethodsOriginalTop(addAmount);
      setOpensliderMethodsOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenCustomStandards") {
      setOpenCustomStandardsOriginalTop(addAmount);
      setOpenCustomStandardsOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenCentreMaintenance") {
      setOpenCentreMaintenanceOriginalTop(addAmount);
      setOpenCentreMaintenanceOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenTransferQuestionnaire") {
      setOpenTransferQuestionnaireOriginalTop(addAmount);
      setOpenTransferQuestionnaireOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenAddQuestion") {
      setOpenAddQuestionOriginalTop(addAmount);
      setOpenAddQuestionOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenAnswerScale") {
      setOpenAnswerScaleOriginalTop(addAmount);
      setOpenAnswerScaleOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenEvaluaterRisk") {
      setOpenEvaluaterRiskOriginalTop(addAmount);
      setOpenEvaluaterRiskOriginalLeft(addAmountLeft);
    }
    if (divId === "divOpenRiskRegister") {
      setOpenRiskRegisterOriginalTop(addAmount);
      setOpenRiskRegisterOriginalLeft(addAmountLeft);
    }


  }


  function addToTopPos() {
    var addAmount = 20;
    var plusAmount = 40;
    var tmpDivOpen = 0;

    if (!openRiskCollection && !openCollectionData && !openExistingRiskCollection && !openRiskHierarchy && !openGlobalStandard && !openCreateNewCollection && !openEditCollection && !centreMaintenance && !openAddColor && !openTransferQuestionnaire && !openCustomStandards && !opensliderMethods && !openAddQuestion && !openAnswerScale && !openEvaluaterRisk && !openRiskRegister) {
      tmpDivOpen = 0;
    } else {
      tmpDivOpen = noOfDivsOpen;
    }

    if (tmpDivOpen === 0) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 1) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 2) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 3) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 4) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 5) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 6) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 7) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 8) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 9) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 10) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 11) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 12) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 13) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 14) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 15) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 16) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 17) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }
    if (tmpDivOpen === 18) {
      addAmount = addAmount + (plusAmount * tmpDivOpen);
    }


    return addAmount;

  }


  const [openRiskCollectionOrder, setOpenRiskCollectionOrder] = useState(0);
  const [openCreateNewCollectionOrder, setOpenCreateNewCollectionOrder] = useState(0);
  const [openGlobalStandardOrder, setOpenGlobalStandardOrder] = useState(0);
  const [openCustomStandardsOrder, setOpenCustomStandardsOrder] = useState(0);
  const [openRiskHierarchyOrder, setOpenRiskHierarchyOrder] = useState(0);
  const [openCentreMaintenanceOrder, setOpenCentreMaintenanceOrder] = useState(0);
  const [opensliderMethodsOrder, setOpensliderMethodsOrder] = useState(0);
  const [openAddColorOrder, setOpenAddColorOrder] = useState(0);
  const [openEditCollectionOrder, setOpenEditCollectionOrder] = useState(0);
  const [openTransferQuestionnaireOrder, setOpenTransferQuestionnaireOrder] = useState(0);
  const [openAddQuestionOrder, setOpenAddQuestionOrder] = useState(0);
  const [openAnswerScaleOrder, setOpenAnswerScaleOrder] = useState(0);
  const [openEvaluaterRiskOrder, setOpenEvaluaterRiskOrder] = useState(0);
  const [openRiskRegisterOrder, setOpenRiskRegisterOrder] = useState(0);
  const [openQuestionerFromDashBordOrder, setOpenQuestionerFromDashBordOrder] = useState(0);

  const [openOrderNumber, setOpenOrderNumber] = useState(0);
  const [waitingOpen, setWaitingOpen] = React.useState(false);


  const [openCustomStandards, setOpenCustomStandards] = useState(false);

  function viewLayersFuction(order, openOrderNo) {
    var addAmount = 20;
    var plusAmount = 40;
    var addAmountLeft = 8;
    var plusAmountLeft = 20;

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");

    setOpenRiskCollection(false);
    setOpenCreateNewCollection(false);
    setOpenGlobalStandard(false);
    setOpenCustomStandards(false);
    setOpenRiskHierarchy(false);
    setCentreMaintenance(false);
    setOpensliderMethods(false);
    setOpenAddColor(false);
    setOpenEditCollection(false);
    setOpenTransferQuestionnaire(false);
    setOpenAddQuestion(false)
    setOpenAnswerScale(false)
    setOpenEvaluaterRisk(false)
    setOpenRiskRegister(false)
    setOpenQuestionerFromDashBord(false)



    setWaitingOpen(true);
    setTimeout(() => {
      if (openRiskCollectionOrder > 0 || order === 1) {
        var tabId2 = document.getElementById("tabId2");
        var addToLeftAmount = 0;
        if (order === 1) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openRiskCollectionOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
        setTopBtnBottomPos(pos);
        setOpenRiskCollectionOriginalLeft(addToLeftAmount);
        setOpenRiskCollection(true);
        setOpenRiskCollectionOriginalWidth(width);
        setOpenRiskCollectionOriginalHeight(height);
        setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openCreateNewCollectionOrder > 0 || order === 2) {
        var tabId6 = document.getElementById("tabId6");
        var addToLeftAmount = 0;
        if (order === 2) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
        setOpenCreateNewCollectionPos(pos);
        setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
        setOpenCreateNewCollection(true);
        setOpenCreateNewCollectionOriginalWidth(width);
        setOpenCreateNewCollectionOriginalHeight(height);
        setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openGlobalStandardOrder > 0 || order === 3) {
        var tabId5 = document.getElementById("tabId5");
        var addToLeftAmount = 0;
        if (order === 3) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openGlobalStandardOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
        setOpenGlobalStandardPos(pos);
        setOpenGlobalStandardOriginalLeft(addToLeftAmount);
        setOpenGlobalStandard(true);
        setOpenGlobalStandardOriginalWidth(width);
        setOpenGlobalStandardOriginalHeight(height);
        setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openCustomStandardsOrder > 0 || order === 4) {
        var tabId10 = document.getElementById("tabId10");
        var addToLeftAmount = 0;
        if (order === 4) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openCustomStandardsOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
        setCustomStandardsPos(pos);
        setOpenCustomStandardsOriginalLeft(addToLeftAmount);
        setOpenCustomStandards(true);
        setOpenCustomStandardsOriginalWidth(width);
        setOpenCustomStandardsOriginalHeight(height);
        setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);


      }
      if (openRiskHierarchyOrder > 0 || order === 5) {
        var tabId3 = document.getElementById("tabId3");
        var addToLeftAmount = 0;
        if (order === 5) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));

        setOpenExistingRiskHierarchyPos(pos);
        setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
        setOpenRiskHierarchy(true);
        setOpenRiskHierarchyOriginalWidth(width);
        setOpenRiskHierarchyOriginalHeight(height);
        setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (openCentreMaintenanceOrder > 0 || order === 6) {
        var tabId7 = document.getElementById("tabId7");
        var addToLeftAmount = 0;
        if (order === 6) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
        setOpenCentreMaintenancePos(pos);
        setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
        setCentreMaintenance(true);
        setOpenCentreMaintenanceOriginalWidth(width);
        setOpenCentreMaintenanceOriginalHeight(height);
        setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (opensliderMethodsOrder > 0 || order === 7) {
        var tabId8 = document.getElementById("tabId8");
        var addToLeftAmount = 0;
        if (order === 7) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (opensliderMethodsOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
        setOpensliderMethodsPos(pos);
        setOpensliderMethodsOriginalLeft(addToLeftAmount);
        setOpensliderMethods(true);
        setOpensliderMethodsOriginalWidth(width);
        setOpensliderMethodsOriginalHeight(height);
        setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openAddColorOrder > 0 || order === 8) {
        var tabId9 = document.getElementById("tabId9");
        var addToLeftAmount = 0;
        if (order === 8) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openAddColorOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
        setOpeAddColorPos(pos);
        setOpenAddColorOriginalLeft(addToLeftAmount);
        setOpenAddColor(true);
        setOpenAddColorOriginalWidth(width);
        setOpenAddColorOriginalHeight(height);
        setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openEditCollectionOrder > 0 || order === 10) {
        var tabId12 = document.getElementById("tabId12");
        var addToLeftAmount = 0;
        if (order === 10) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openEditCollectionOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEditCollectionOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
        setOpenEditCollectionPos(pos);
        setOpenEditCollectionOriginalLeft(addToLeftAmount);
        setOpenEditCollection(true);
        setOpenEditCollectionOriginalWidth(width);
        setOpenEditCollectionOriginalHeight(height);
        setOpenEditCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }

      if (openTransferQuestionnaireOrder > 0 || order === 9) {
        var tabId14 = document.getElementById("tabId14");
        var addToLeftAmount = 0;
        if (order === 9) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
        setOpenTransferQuestionnairePos(pos);
        setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
        setOpenTransferQuestionnaire(true);
        setOpenTransferQuestionnaireOriginalWidth(width);
        setOpenTransferQuestionnaireOriginalHeight(height);
        setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openAddQuestionOrder > 0 || order === 11) {
        var tabId15 = document.getElementById("tabId15");
        var addToLeftAmount = 0;
        if (order === 11) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openAddQuestionOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
        setOpenAddQuestionPos(pos);
        setOpenAddQuestionOriginalLeft(addToLeftAmount);
        setOpenAddQuestion(true);
        setOpenAddQuestionOriginalWidth(width);
        setOpenAddQuestionOriginalHeight(height);
        setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openEvaluaterRiskOrder > 0 || order === 12) {
        var tabId16 = document.getElementById("tabId16");
        var addToLeftAmount = 0;
        if (order === 12) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openAnswerScaleOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
        setOpenAnswerScalePos(pos);
        setOpenAnswerScaleOriginalLeft(addToLeftAmount);
        setOpenAnswerScale(true);
        setOpenAnswerScaleOriginalWidth(width);
        setOpenAnswerScaleOriginalHeight(height);
        setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (openQuestionerFromDashBordOrder > 0 || order === 13) {
        var tabId17 = document.getElementById("tabId17");
        var addToLeftAmount = 0;
        if (order === 13) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openQuestionerFromDashBordOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openQuestionerFromDashBordOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId17.offsetTop + topBtnId.offsetTop));
        setOpenQuestionerFromDashBordPos(pos);
        setOpenQuestionerFromDashBordOriginalLeft(addToLeftAmount);
        setOpenQuestionerFromDashBord(true);
        setOpenQuestionerFromDashBordOriginalWidth(width);
        setOpenQuestionerFromDashBordOriginalHeight(height);
        setOpenQuestionerFromDashBordOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openEvaluaterRiskOrder > 0 || order === 14) {
        var tabId18 = document.getElementById("tabId18");
        var addToLeftAmount = 0;
        if (order === 14) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openEvaluaterRiskOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEvaluaterRiskOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId18.offsetTop + topBtnId.offsetTop));
        setOpenEvaluaterRiskPos(pos);
        setOpenEvaluaterRiskOriginalLeft(addToLeftAmount);
        setOpenEvaluaterRisk(true);
        setOpenEvaluaterRiskOriginalWidth(width);
        setOpenEvaluaterRiskOriginalHeight(height);
        setOpenEvaluaterRiskOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openRiskRegisterOrder > 0 || order === 15) {
        var tabId19 = document.getElementById("tabId19");
        var addToLeftAmount = 0;
        if (order === 15) {
          var addToTopAmount = addAmount + (plusAmount * (openOrderNo - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openOrderNo - 1));
        } else {
          var addToTopAmount = addAmount + (plusAmount * (openRiskRegisterOrder - 1));
          addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskRegisterOrder - 1));
        }
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId19.offsetTop + topBtnId.offsetTop));
        setOpenRiskRegisterPos(pos);
        setOpenRiskRegisterOriginalLeft(addToLeftAmount);
        setOpenRiskRegister(true);
        setOpenRiskRegisterOriginalWidth(width);
        setOpenRiskRegisterOriginalHeight(height);
        setOpenRiskRegisterOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      setWaitingOpen(false);
    }, 500);

  }


  function closeLayersFuction(order) {
    var addAmount = 20;
    var plusAmount = 40;
    var addAmountLeft = 8;
    var plusAmountLeft = 20;

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");

    setOpenRiskCollection(false);
    setOpenCreateNewCollection(false);
    setOpenGlobalStandard(false);
    setOpenCustomStandards(false);
    setOpenRiskHierarchy(false);
    setCentreMaintenance(false);
    setOpensliderMethods(false);
    setOpenAddColor(false);
    setOpenEditCollection(false);
    setOpenTransferQuestionnaire(false);
    setOpenAddQuestion(false)
    setOpenAnswerScale(false)
    setOpenEvaluaterRisk(false)
    setOpenRiskRegister(false)
    setOpenQuestionerFromDashBord(false)

    setWaitingOpen(true);
    setTimeout(() => {
      if (openRiskCollectionOrder > 0 && order != 1) {
        var tabId2 = document.getElementById("tabId2");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
        setTopBtnBottomPos(pos);
        setOpenRiskCollectionOriginalLeft(addToLeftAmount);
        setOpenRiskCollection(true);
        setOpenRiskCollectionOriginalWidth(width);
        setOpenRiskCollectionOriginalHeight(height);
        setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openCreateNewCollectionOrder > 0 && order != 2) {
        var tabId6 = document.getElementById("tabId6");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
        setOpenCreateNewCollectionPos(pos);
        setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
        setOpenCreateNewCollection(true);
        setOpenCreateNewCollectionOriginalWidth(width);
        setOpenCreateNewCollectionOriginalHeight(height);
        setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openGlobalStandardOrder > 0 && order != 3) {
        var tabId5 = document.getElementById("tabId5");
        var addToTopAmount = addAmount + (plusAmount * (openGlobalStandardOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
        setOpenGlobalStandardPos(pos);
        setOpenGlobalStandardOriginalLeft(addToLeftAmount);
        setOpenGlobalStandard(true);
        setOpenGlobalStandardOriginalWidth(width);
        setOpenGlobalStandardOriginalHeight(height);
        setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openCustomStandardsOrder > 0 && order != 4) {
        var tabId10 = document.getElementById("tabId10");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCustomStandardsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
        setCustomStandardsPos(pos);
        setOpenCustomStandardsOriginalLeft(addToLeftAmount);
        setOpenCustomStandards(true);
        setOpenCustomStandardsOriginalWidth(width);
        setOpenCustomStandardsOriginalHeight(height);
        setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openRiskHierarchyOrder > 0 && order != 5) {
        var tabId3 = document.getElementById("tabId3");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));

        setOpenExistingRiskHierarchyPos(pos);
        setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
        setOpenRiskHierarchy(true);
        setOpenRiskHierarchyOriginalWidth(width);
        setOpenRiskHierarchyOriginalHeight(height);
        setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (openCentreMaintenanceOrder > 0 && order != 6) {
        var tabId7 = document.getElementById("tabId7");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
        setOpenCentreMaintenancePos(pos);
        setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
        setCentreMaintenance(true);
        setOpenCentreMaintenanceOriginalWidth(width);
        setOpenCentreMaintenanceOriginalHeight(height);
        setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (opensliderMethodsOrder > 0 && order != 7) {
        var tabId8 = document.getElementById("tabId8");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (opensliderMethodsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
        setOpensliderMethodsPos(pos);
        setOpensliderMethodsOriginalLeft(addToLeftAmount);
        setOpensliderMethods(true);
        setOpensliderMethodsOriginalWidth(width);
        setOpensliderMethodsOriginalHeight(height);
        setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openAddColorOrder > 0 && order != 8) {
        var tabId9 = document.getElementById("tabId9");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAddColorOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
        setOpeAddColorPos(pos);
        setOpenAddColorOriginalLeft(addToLeftAmount);
        setOpenAddColor(true);
        setOpenAddColorOriginalWidth(width);
        setOpenAddColorOriginalHeight(height);
        setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openEditCollectionOrder > 0 && order != 10) {
        var tabId12 = document.getElementById("tabId12");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openEditCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEditCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
        setOpenEditCollectionPos(pos);
        setOpenEditCollectionOriginalLeft(addToLeftAmount);
        setOpenEditCollection(true);
        setOpenEditCollectionOriginalWidth(width);
        setOpenEditCollectionOriginalHeight(height);
        setOpenEditCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
      }
      if (openTransferQuestionnaireOrder > 0 && order != 9) {
        var tabId14 = document.getElementById("tabId14");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
        setOpenTransferQuestionnairePos(pos);
        setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
        setOpenTransferQuestionnaire(true);
        setOpenTransferQuestionnaireOriginalWidth(width);
        setOpenTransferQuestionnaireOriginalHeight(height);
        setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      if (openAddQuestionOrder > 0 && order != 11) {
        var tabId15 = document.getElementById("tabId15");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAddQuestionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
        setOpenAddQuestionPos(pos);
        setOpenAddQuestionOriginalLeft(addToLeftAmount);
        setOpenAddQuestion(true);
        setOpenAddQuestionOriginalWidth(width);
        setOpenAddQuestionOriginalHeight(height);
        setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openAnswerScaleOrder > 0 && order != 12) {
        var tabId16 = document.getElementById("tabId16");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAnswerScaleOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
        setOpenAnswerScalePos(pos);
        setOpenAnswerScaleOriginalLeft(addToLeftAmount);
        setOpenAnswerScale(true);
        setOpenAnswerScaleOriginalWidth(width);
        setOpenAnswerScaleOriginalHeight(height);
        setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openQuestionerFromDashBordOrder > 0 && order != 13) {
        var tabId17 = document.getElementById("tabId17");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openQuestionerFromDashBordOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openQuestionerFromDashBordOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId17.offsetTop + topBtnId.offsetTop));
        setOpenQuestionerFromDashBordPos(pos);
        setOpenQuestionerFromDashBordOriginalLeft(addToLeftAmount);
        setOpenQuestionerFromDashBord(true);
        setOpenQuestionerFromDashBordOriginalWidth(width);
        setOpenQuestionerFromDashBordOriginalHeight(height);
        setOpenQuestionerFromDashBordOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openEvaluaterRiskOrder > 0 && order != 14) {
        var tabId18 = document.getElementById("tabId18");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openEvaluaterRiskOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEvaluaterRiskOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId18.offsetTop + topBtnId.offsetTop));
        setOpenEvaluaterRiskPos(pos);
        setOpenEvaluaterRiskOriginalLeft(addToLeftAmount);
        setOpenEvaluaterRisk(true);
        setOpenEvaluaterRiskOriginalWidth(width);
        setOpenEvaluaterRiskOriginalHeight(height);
        setOpenEvaluaterRiskOriginalRelatedHeight(relatedDivLoadingHeight);

      }

      if (openRiskRegisterOrder > 0 && order != 15) {
        var tabId19 = document.getElementById("tabId19");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskRegisterOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskRegisterOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId19.offsetTop + topBtnId.offsetTop));
        setOpenRiskRegisterPos(pos);
        setOpenRiskRegisterOriginalLeft(addToLeftAmount);
        setOpenRiskRegister(true);
        setOpenRiskRegisterOriginalWidth(width);
        setOpenRiskRegisterOriginalHeight(height);
        setOpenRiskRegisterOriginalRelatedHeight(relatedDivLoadingHeight);

      }
      setWaitingOpen(false);
    }, 500);

  }


  function refreshLayersFuction() {
    var addAmount = 20;
    var plusAmount = 40;
    var addAmountLeft = 8;
    var plusAmountLeft = 20;

    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");

    setOpenRiskCollection(false);
    setOpenCreateNewCollection(false);
    setOpenGlobalStandard(false);
    setOpenCustomStandards(false);
    setOpenRiskHierarchy(false);
    setCentreMaintenance(false);
    setOpensliderMethods(false);
    setOpenAddColor(false);
    setOpenEditCollection(false);
    setOpenTransferQuestionnaire(false);
    setOpenAddQuestion(false)
    setOpenAnswerScale(false)
    setOpenEvaluaterRisk(false)
    setOpenRiskRegister(false)
    setOpenQuestionerFromDashBord(false)

    var maxZIndex = findMaxZIndex();

    setWaitingOpen(true);
    setTimeout(() => {
      if (openRiskCollectionOrder > 0) {
        var tabId2 = document.getElementById("tabId2");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));
        setTopBtnBottomPos(pos);
        setOpenRiskCollectionOriginalLeft(addToLeftAmount);
        setOpenRiskCollection(true);
        setOpenRiskCollectionOriginalWidth(width);
        setOpenRiskCollectionOriginalHeight(height);
        setOpenRiskCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenRiskCollectionZIndex(maxZIndex + openRiskCollectionOrder);
      }
      if (openCreateNewCollectionOrder > 0) {
        var tabId6 = document.getElementById("tabId6");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCreateNewCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCreateNewCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
        setOpenCreateNewCollectionPos(pos);
        setOpenCreateNewCollectionOriginalLeft(addToLeftAmount);
        setOpenCreateNewCollection(true);
        setOpenCreateNewCollectionOriginalWidth(width);
        setOpenCreateNewCollectionOriginalHeight(height);
        setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenCreateNewCollectionZIndex(maxZIndex + openCreateNewCollectionOrder);
      }
      if (openGlobalStandardOrder > 0) {
        var tabId5 = document.getElementById("tabId5");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openGlobalStandardOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openGlobalStandardOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
        setOpenGlobalStandardPos(pos);
        setOpenGlobalStandardOriginalLeft(addToLeftAmount);
        setOpenGlobalStandard(true);
        setOpenGlobalStandardOriginalWidth(width);
        setOpenGlobalStandardOriginalHeight(height);
        setOpenGlobalStandardOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenGlobalStandardZIndex(maxZIndex + openGlobalStandardOrder);
      }
      if (openCustomStandardsOrder > 0) {
        var tabId10 = document.getElementById("tabId10");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCustomStandardsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCustomStandardsOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
        setCustomStandardsPos(pos);
        setOpenCustomStandardsOriginalLeft(addToLeftAmount);
        setOpenCustomStandards(true);
        setOpenCustomStandardsOriginalWidth(width);
        setOpenCustomStandardsOriginalHeight(height);
        setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenCustomStandardsZIndex(maxZIndex + openCustomStandardsOrder);
      }
      if (openRiskHierarchyOrder > 0) {
        var tabId3 = document.getElementById("tabId3");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskHierarchyOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskHierarchyOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));
        setOpenExistingRiskHierarchyPos(pos);
        setOpenRiskHierarchyOriginalLeft(addToLeftAmount);
        setOpenRiskHierarchy(true);
        setOpenRiskHierarchyOriginalWidth(width);
        setOpenRiskHierarchyOriginalHeight(height);
        setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenRiskHierarchyZIndex(maxZIndex + openRiskHierarchyOrder);
      }
      if (openCentreMaintenanceOrder > 0) {
        var tabId7 = document.getElementById("tabId7");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openCentreMaintenanceOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openCentreMaintenanceOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
        setOpenCentreMaintenancePos(pos);
        setOpenCentreMaintenanceOriginalLeft(addToLeftAmount);
        setCentreMaintenance(true);
        setOpenCentreMaintenanceOriginalWidth(width);
        setOpenCentreMaintenanceOriginalHeight(height);
        setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenCentreMaintenanceZIndex(maxZIndex + openCentreMaintenanceOrder);
      }
      if (opensliderMethodsOrder > 0) {
        var tabId8 = document.getElementById("tabId8");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (opensliderMethodsOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (opensliderMethodsOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
        setOpensliderMethodsPos(pos);
        setOpensliderMethodsOriginalLeft(addToLeftAmount);
        setOpensliderMethods(true);
        setOpensliderMethodsOriginalWidth(width);
        setOpensliderMethodsOriginalHeight(height);
        setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenSliderMethodsZIndex(maxZIndex + opensliderMethodsOrder);
      }
      if (openAddColorOrder > 0) {
        var tabId9 = document.getElementById("tabId9");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAddColorOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddColorOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
        setOpeAddColorPos(pos);
        setOpenAddColorOriginalLeft(addToLeftAmount);
        setOpenAddColor(true);
        setOpenAddColorOriginalWidth(width);
        setOpenAddColorOriginalHeight(height);
        setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenAddColorZIndex(maxZIndex + openAddColorOrder);
      }
      if (openEditCollectionOrder > 0) {
        var tabId12 = document.getElementById("tabId12");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openEditCollectionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEditCollectionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
        setOpenEditCollectionPos(pos);
        setOpenEditCollectionOriginalLeft(addToLeftAmount);
        setOpenEditCollection(true);
        setOpenEditCollectionOriginalWidth(width);
        setOpenEditCollectionOriginalHeight(height);
        setOpenEditCollectionOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenEditCollectionZIndex(maxZIndex + openEditCollectionOrder);
      }
      if (openTransferQuestionnaireOrder > 0) {
        var tabId14 = document.getElementById("tabId14");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openTransferQuestionnaireOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openTransferQuestionnaireOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
        setOpenTransferQuestionnairePos(pos);
        setOpenTransferQuestionnaireOriginalLeft(addToLeftAmount);
        setOpenTransferQuestionnaire(true);
        setOpenTransferQuestionnaireOriginalWidth(width);
        setOpenTransferQuestionnaireOriginalHeight(height);
        setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenTransferQuestionnaireZIndex(maxZIndex + openTransferQuestionnaireOrder);
      }

      // add question
      if (openAddQuestionOrder > 0) {
        var tabId15 = document.getElementById("tabId15");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAddQuestionOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAddQuestionOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId15.offsetTop + topBtnId.offsetTop));
        setOpenAddQuestionPos(pos);
        setOpenAddQuestionOriginalLeft(addToLeftAmount);
        setOpenAddQuestion(true);
        setOpenAddQuestionOriginalWidth(width);
        setOpenAddQuestionOriginalHeight(height);
        setOpenAddQuestionOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenAddQuestionZIndex(maxZIndex + openAddQuestionOrder);
      }


      if (openAnswerScaleOrder > 0) {
        var tabId16 = document.getElementById("tabId16");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openAnswerScaleOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openAnswerScaleOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId16.offsetTop + topBtnId.offsetTop));
        setOpenAnswerScalePos(pos);
        setOpenAnswerScaleOriginalLeft(addToLeftAmount);
        setOpenAnswerScale(true);
        setOpenAnswerScaleOriginalWidth(width);
        setOpenAnswerScaleOriginalHeight(height);
        setOpenAnswerScaleOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenAnswerScaleZIndex(maxZIndex + openAnswerScaleOrder);
      }

      if (openQuestionerFromDashBordOrder > 0) {
        var tabId17 = document.getElementById("tabId17");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openQuestionerFromDashBordOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openQuestionerFromDashBordOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId17.offsetTop + topBtnId.offsetTop));
        setOpenQuestionerFromDashBordPos(pos);
        setOpenQuestionerFromDashBordOriginalLeft(addToLeftAmount);
        setOpenQuestionerFromDashBord(true);
        setOpenQuestionerFromDashBordOriginalWidth(width);
        setOpenQuestionerFromDashBordOriginalHeight(height);
        setOpenQuestionerFromDashBordOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenQuestionerFromDashBordZIndex(maxZIndex + openQuestionerFromDashBordOrder);
      }

      if (openEvaluaterRiskOrder > 0) {
        var tabId18 = document.getElementById("tabId18");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openEvaluaterRiskOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openEvaluaterRiskOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId18.offsetTop + topBtnId.offsetTop));
        setOpenEvaluaterRiskPos(pos);
        setOpenEvaluaterRiskOriginalLeft(addToLeftAmount);
        setOpenEvaluaterRisk(true);
        setOpenEvaluaterRiskOriginalWidth(width);
        setOpenEvaluaterRiskOriginalHeight(height);
        setOpenEvaluaterRiskOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenEvaluaterRiskZIndex(maxZIndex + openEvaluaterRiskOrder);

      }

      if (openRiskRegisterOrder > 0) {
        var tabId19 = document.getElementById("tabId19");
        var addToLeftAmount = 0;
        var addToTopAmount = addAmount + (plusAmount * (openRiskRegisterOrder - 1));
        addToLeftAmount = addAmountLeft + (plusAmountLeft * (openRiskRegisterOrder - 1));
        var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId19.offsetTop + topBtnId.offsetTop));
        setOpenRiskRegisterPos(pos);
        setOpenRiskRegisterOriginalLeft(addToLeftAmount);
        setOpenRiskRegister(true);
        setOpenRiskRegisterOriginalWidth(width);
        setOpenRiskRegisterOriginalHeight(height);
        setOpenRiskRegisterOriginalRelatedHeight(relatedDivLoadingHeight);
        setOpenRiskRegisterZIndex(maxZIndex + openRiskRegisterOrder);

      }
      setWaitingOpen(false);
    }, 500);

  }








  const [topBtnBottomPos, setTopBtnBottomPos] = useState(0);
  const [openRiskCollection, setOpenRiskCollection] = useState(false);

  function isMultiTaskingMenuOpen(openDivs) {
    if (openDivs != '') {
      var multiOpen = sessionStorage.getItem('sessionstoragemultiopen')
      if (multiOpen === null || multiOpen != 'YES') {
        sessionStorage.setItem('sessionstoragemultiopen', 'YES');
      }
    }
  }

  function setOpenRiskCollectionFuction(flag) {

    var maxZIndex = findMaxZIndex();
    setOpenRiskCollectionZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openRiskCollection');

    if (!openRiskCollection) {
      reloadAll();
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenRiskCollectionOrder(openOrderNo);



      viewLayersFuction(1, openOrderNo);

      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId2 = document.getElementById("tabId2");
      
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenRiskCollection');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId2.offsetTop + topBtnId.offsetTop));

      setTopBtnBottomPos(pos);
      */
    }

  }

  /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  const [riskEvalTypeOpen, setRiskEvalTypeOpen] = React.useState(false);
  const [riskEvalTypeScroll, setRiskEvalTypeScroll] = React.useState('paper');
  const [selectedRiskEvalArray, setSelectedRiskEvalArray] = React.useState([]);
  const [rsType, setRsTtpe] = useState([]);
  const [riskTypeData, setRiskTypeData] = useState([])

  const riskEvalTypeHandleClickClose = () => {
    setRiskEvalTypeOpen(false);
    setSelectedRiskEvalArray([]);
  };

  const [hierarchyId, setHierarchyId] = React.useState();
  const handleChangeHierarchy = (event) => {
    var hierarchyId = event.target.value;
    setHierarchyId(event.target.value)
    riskTypeData.forEach((item, index) => {
      if (item.collectionRiskId === hierarchyId) {
        setRsTtpe(item.riskTypes);
      }
    })
  };

  function checkSelected(id, name, closeIds, openIds) {
    if (document.getElementById("chkRiskType" + id).checked) {
      var tmpArray = {};
      tmpArray["collectionRiskTypeId"] = id;
      var ar = [];
      closeIds.forEach((data) => {
        ar.push(data);
      });
      openIds.forEach((data) => {
        ar.push(data);
      });
      tmpArray["collectionQuestionIds"] = ar;
      tmpArray["name"] = name;
      selectedRiskEvalArray.push(tmpArray);
      setSelectedRiskEvalArray(selectedRiskEvalArray);
    } else {
      selectedRiskEvalArray.forEach((data, index) => {
        if (data.collectionRiskTypeId === id) {
          selectedRiskEvalArray.splice(index, 1);
          setSelectedRiskEvalArray(selectedRiskEvalArray);
        }
      })
    }
  }


  function getQuestionIds(comment, type) {
    var count = 0;
    var ids = [];
    if (comment.questions != undefined && comment.questions != null) {
      if (type === 1) {
        if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
          comment.questions.forEach((quesItem) => {
            if (quesItem.questionType === "Closeended") {
              ids.push(quesItem.questionId);
            }
          })
        }
      }
      if (type === 2) {
        if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
          comment.questions.forEach((quesItem) => {
            if (quesItem.questionType === "Openended") {
              ids.push(quesItem.questionId);
            }
          })
        }
      }
    } else {
      ids = [];
    }
    return ids;
  }

  function getQuestionIdsWithSubRisks(comment, type) {
    var count = 0;
    var riskIds = "";
    var ids = [];
    if (type === 1) {
      if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
        comment.questions.forEach((quesItem) => {
          if (quesItem.questionType === "Closeended") {
            ids.push(quesItem.questionId);
          }
        })
      }
    }
    if (type === 2) {
      if (comment.questions != undefined && comment.questions != null && comment.questions.length > 0) {
        comment.questions.forEach((quesItem) => {
          if (quesItem.questionType === "Openended") {
            ids.push(quesItem.questionId);
          }
        })
      }
    }
    if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
      return getQuestionSubIdsWithSubRisks(comment.subRisks, type, ids);
    } else {
      return ids;
    }
  }
  function getQuestionSubIdsWithSubRisks(comment, type, ids) {
    comment.forEach((data, index) => {
      if (type === 1) {
        if (data.questions != undefined && data.questions != null) {
          if (data.questions.closeEndedQuestionIds != undefined && data.questions.closeEndedQuestionIds != null && data.questions.closeEndedQuestionIds.length > 0) {
            data.questions.closeEndedQuestionIds.forEach((item, index) => {
              ids.push(item.questionId);
            });
          }
        }
      }
      if (type === 2) {
        if (data.questions != undefined && data.questions != null) {
          if (data.questions.openEdndedQuestionIds != undefined && data.questions.openEdndedQuestionIds != null && data.questions.openEdndedQuestionIds.length > 0) {
            data.questions.openEdndedQuestionIds.forEach((item, index) => {
              ids.push(item.questionId);
            });
          }
        }
      }
      if (data.subRisks != undefined && data.subRisks != null && data.subRisks.length > 0) {
        getQuestionSubIdsWithSubRisks(data.subRisks, type, ids);
      }
    });
    return ids;

  }


  function RiskEval({ comment }) {
    var closeEndedQuestionIds = getQuestionIds(comment, 1);
    var openEndedQuestionIds = getQuestionIds(comment, 2);
    var closeEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment, 1);
    var openEndedQuestionIdsWithSub = getQuestionIdsWithSubRisks(comment, 2);

    const [show, setShow] = React.useState(false)
    const nestedComments = (comment.subRisks || []).map(comment => {
      return <RiskEval key={comment.collectionRiskTypeId} comment={comment} type="child" />
    })

    return (
      <div style={{ "marginLeft": "15px", "marginTop": "10px" }}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <Button
            style={{ width: '0px', height: '0px', marginTop: '14px' }}
            onClick={() => setShow(!show)}
            startIcon={show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
          </Button>

          <span style={{ marginTop: '-7px !important' }} >

            <FormControlLabel control={<Checkbox onClick={() => checkSelected(comment.collectionRiskTypeId, comment.name, show ? closeEndedQuestionIds : closeEndedQuestionIdsWithSub, show ? openEndedQuestionIds : openEndedQuestionIdsWithSub)} id={"chkRiskType" + comment.collectionRiskTypeId} />}
              label={show ? comment.name + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ? '' : ' (') + (closeEndedQuestionIds.length > 0 ? ' C : '
                + closeEndedQuestionIds.length : '') + (openEndedQuestionIds.length > 0
                  ? closeEndedQuestionIds.length > 0 ? ', O : ' + openEndedQuestionIds.length : ' O : ' + openEndedQuestionIds.length : '') + (closeEndedQuestionIds.length === 0 && openEndedQuestionIds.length === 0 ? '' : ' )')
                :
                comment.name + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ? '' : ' (') + (closeEndedQuestionIdsWithSub.length > 0 ? ' C : '
                  + closeEndedQuestionIdsWithSub.length : '') + (openEndedQuestionIdsWithSub.length > 0
                    ? closeEndedQuestionIdsWithSub.length > 0 ? ', O : ' + openEndedQuestionIdsWithSub.length : ' O : ' + openEndedQuestionIdsWithSub.length : '') + (closeEndedQuestionIdsWithSub.length === 0 && openEndedQuestionIdsWithSub.length === 0 ? '' : ' )')
              }

            />
          </span>
        </div>
        {
          show ?
            <>
              {nestedComments}
            </>
            : null
        }
      </div>
    )
  }

  const [quesData, setQuesData] = useState([]);
  const [selectedRiskArrForEval, setSelectedRiskArrForEval] = useState([]);

  function setRiskTypeEvalFuction() {
    setSelectedRiskArrForEval([]);
    var takenFlag = true;
    var takenFlag = false;
    var tmpRiskData = [];
    var headerMsg = "";
    var selectedRiskArr = [];
    if (document.getElementById('chkRiskType0').checked) {
      takenFlag = true;
      selectedRiskArr.push("ALL");
      quesData.forEach((data) => {
        tmpRiskData.push(data);
        takenFlag = false;
        headerMsg = "All";
      });
    } else {
      var k = 0;
      selectedRiskEvalArray.forEach((item) => {
        if (document.getElementById('chkRiskType' + item.collectionRiskTypeId) != null && document.getElementById('chkRiskType' + item.collectionRiskTypeId).checked) {
          selectedRiskArr.push(item.collectionRiskTypeId);
          takenFlag = true;
          item.collectionQuestionIds.forEach((quesId) => {
            quesData.forEach((data) => {
              if (data.questionId === quesId) {
                tmpRiskData.push(data);
                takenFlag = false;
              }
            });
          });
          if (k === 0) {
            headerMsg = item.name;
          } else {
            headerMsg += ", " + item.name;
          }
          k++;
        }
      });
    }
    if (takenFlag) {
      if (takenFlag) {
        alert("Please note that you don't have any Questioners for the selected Risk Types")
      } else {
        alert("Please select atleast one Risk Type for evaluation")
      }
    } else {
      //setRiskEvalHeaderMsg(headerMsg);
      setSelectedRiskArrForEval(selectedRiskArr);
      var count = 1;
      var tmpArray = [];
      setRows([]);
      tmpRiskData.forEach((item) => {
        var tmpObj = {};
        tmpObj["questionId"] = item.questionId;
        tmpObj["collectionId"] = selectedCollectionEvaluateId;
        tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
        tmpObj["id"] = count;
        var riskScrubStr = RiskScrumb(item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData)
        tmpObj["riskName"] = riskScrubStr;

        tmpObj["objectiveName"] = item.objective.name;
        tmpObj["componentName"] = item.component.name;
        tmpObj["principalName"] = item.principle.name;
        tmpObj["entityName"] = item.entity.name;
        tmpObj["question"] = item.question;
        tmpObj["questionType"] = item.questionType;
        tmpObj["questionScore"] = item.questionScore;
        tmpObj["questionScoreMethodId"] = item.questionScoreData.riskMethodId;
        tmpObj["questionData"] = item;
        tmpArray.push(tmpObj);
        setRows(tmpArray);
        count++;
      })
      setRiskEvalTypeOpen(false);




      setOpenCollectionData(true);
      setOpenCollectionDataScroll('paper');
    }
  }

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const [openCollectionData, setOpenCollectionData] = useState(false);
  const [openCollectionDataScroll, setOpenCollectionDataScroll] = useState('paper');

  const [openCollectionDataTopPos, setOpenCollectionDataTopPos] = useState(0);
  const [selectedCollectionEvaluateId, setSelectedCollectionEvaluateId] = useState('');
  const [selectedCollectionEvaluateUniqueName, setSelectedCollectionEvaluateUniqueName] = useState('');
  const [selectedCollectionEvaluateTitle, setSelectedCollectionEvaluateTitle] = useState('');

  const [rows, setRows] = useState([]);

  function setOpenCollectionDataFuction(flag, collectionId, title) {

    /*
    var maxZIndex = findMaxZIndex();
    setOpenCollectionDataZIndex(maxZIndex + 1);


    
    var topBtnId = document.getElementById("topBtnId");
    var tabId1 = document.getElementById("tabId1");
    var tabId11 = document.getElementById("tabId11");
    
    var addToTopAmount =  addToTopPos();
    addToTop('divOpenCollectionData');
    var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId11.offsetTop + topBtnId.offsetTop));
    
    */
    setRiskTypeData([]);
    allCollectionData.forEach((data) => {
      if (data.id === collectionId) {
        var tmpRiskArr = [];
        if (data.risks != undefined && data.risks != null && data.risks.length > 0) {
          tmpRiskArr = data.risks;
        }
        setSelectedCollectionEvaluateUniqueName(data.collectionUniqueName);
        setRiskTypeData(tmpRiskArr);
        getAllQuestionsForCollection(tmpRiskArr);
      }
    });
    /*
    setOpenCollectionDataTopPos(pos);
    */
    setSelectedCollectionEvaluateId(collectionId);

    setSelectedCollectionEvaluateTitle(title);

    setRiskEvalTypeOpen(true);
    setRiskEvalTypeScroll('paper');
    setSelectedRiskEvalArray([]);

  }

  /** Get all question from the collection and put in to attay */
  var allQuesArray = [];
  function getAllQuestionsForCollection(riskArray) {
    allQuesArray = [];
    riskArray.forEach((item) => {
      if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
        item.riskTypes.forEach((item1) => {
          if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0) {
            item1.questions.forEach((item2) => {
              allQuesArray.push(item2);
            })
          }
          if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
            getAllQuestionsInSubRisksForCollection(item1.subRisks);
          }
        });
      }
    });
    if (allQuesArray.length > 0) {
      //var quesArray = allQuesArray.sort (compare);
      setQuesData(allQuesArray);
    }
  }

  function getAllQuestionsInSubRisksForCollection(riskArray) {
    riskArray.forEach((item) => {
      if (item.questions != undefined && item.questions != null && item.questions.length > 0) {
        item.questions.forEach((item1) => {
          allQuesArray.push(item1);
        })
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        getAllQuestionsInSubRisksForCollection(item.subRisks);
      }
    });
  }

  const [selectedQuesData, setSelectedQuesData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [quesDataCount, setQuesDataCount] = useState(0);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [questionDetailScroll, setQuestionDetailScroll] = React.useState('paper');

  function parseId(no, questionId, collectionId, question) {
    var tmpArray = [];
    setQuesDataCount(no);
    setSelectedQuestion(question);
    allCollectionData.forEach((data) => {
      if (data.id === collectionId) {
        data.questions.forEach((item) => {
          if (item.questionId === questionId) {
            tmpArray.push(item);
            setSelectedQuesData(tmpArray);
          }
        });
      }
    });

    setQuestionDetailOpen(true);
    setQuestionDetailScroll('paper');

  }

  const questionDetailHandleClickClose = () => {
    setQuestionDetailOpen(false);
  };



  function RiskScrumb(riskName, collectionRiskId, riskTypeData) {
    var rsTypeArr = [];
    riskTypeData.forEach((data) => {
      if (data.collectionRiskId === collectionRiskId) {
        rsTypeArr = data.riskTypes;
      }
    });
    var riskScrumbPath = "";
    rsTypeArr.forEach((item) => {
      var pathStr = "";
      pathStr = getRiskScrumb(item, riskName);
      if (pathStr != undefined && pathStr != "") {
        riskScrumbPath = pathStr;
        return true;
      }
    });
    return riskScrumbPath;
  }

  // *Risk Scrumb****
  function getRiskScrumb(comment, riskName) {
    var riskScrumbPath = "";
    if (riskName === comment.name) {
      riskScrumbPath = riskName;
    } else {
      if (comment.subRisks != undefined && comment.subRisks != null && comment.subRisks.length > 0) {
        var riskPath = comment.name;
        var subRiskPath = getSubRiskScrumb(comment.subRisks, riskName);
        if (subRiskPath != "") {
          riskScrumbPath = riskPath + subRiskPath;
        }
      }
    }
    return riskScrumbPath;
  }
  function getSubRiskScrumb(subData, riskName) {
    var tmpSubPath = "";
    subData.forEach((item, index) => {
      if (item.name === riskName) {
        tmpSubPath += " --> " + item.name;
        return true;
      } else {
        if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
          var tmpSubPath1 = " --> " + item.name;
          var tmpSubSubPath1 = getSubRiskScrumb(item.subRisks, riskName);
          if (tmpSubSubPath1 != "") {
            tmpSubPath += tmpSubPath1 + tmpSubSubPath1;
          }
        }
      }
    });
    return tmpSubPath;
  }





  const renderEditButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            editQuestion(params.row.questionId, params.row.collectionUniqueName, params.row.question)
          }}
        >
          Edit
        </Button>
      </strong>
    )
  }


  const [openAmendQuestionFromEval, setOpenAmendQuestionFromEval] = useState(false);
  const [openAmendQuestionFromEvalScroll, setOpenAmendQuestionFromEvalScroll] = useState('paper');

  const [collectionIdFromEval, setCollectionIdFromEval] = useState('');
  const [questionIdFromEval, setQuestionIdFromEval] = useState('');
  const [questionNameFromEval, setQuestionNameFromEval] = useState('');

  function editQuestion(questionId, collectionUniqueName, questionName) {
    setCollectionIdFromEval(collectionUniqueName);
    setQuestionIdFromEval(questionId);
    setQuestionNameFromEval(questionName);
    setOpenAmendQuestionFromEval(true);
    setOpenAmendQuestionFromEvalScroll('paper');

    //let a= document.createElement('a');
    //a.target= '_self';
    //a.href= '/questioPage/' + collectionUniqueName + "?questionid=" + questionId;
    //a.click();
  }




  var allQuesArray1 = [];
  function getAllQuestionsInSubRisksForCollection1(riskArray) {
    riskArray.forEach((item) => {
      if (item.questions != undefined && item.questions != null && item.questions.length > 0) {
        item.questions.forEach((item1) => {
          allQuesArray.push(item1);
        })
      }
      if (item.subRisks != undefined && item.subRisks != null && item.subRisks.length > 0) {
        getAllQuestionsInSubRisksForCollection1(item.subRisks);
      }
    });
  }

  function setOpenAmendQuestionFromEvalFunction(flag) {

    setOpenAmendQuestionFromEval(flag);
    reloadAll();
    setWaitingOpen(true);
    setTimeout(() => {
      axios(CONFIG.MAIN_URL + CONFIG.CONTROLLER_NAME + '/all?collectionUniqueName=' + selectedCollectionEvaluateUniqueName, {
        method: 'get',
      })
        .then(function (response) {
          console.log("+++++++++++++++Get Selected Collection+++++++++++++++++");
          console.log(response.data);
          var tmpRiskArr = [];
          if (response.data[0].risks != undefined && response.data[0].risks != null && response.data[0].risks.length > 0) {
            tmpRiskArr = response.data[0].risks;
          }
          setRiskTypeData(tmpRiskArr);

          allQuesArray1 = [];
          tmpRiskArr.forEach((item) => {
            if (item.riskTypes != undefined && item.riskTypes != null && item.riskTypes.length > 0) {
              item.riskTypes.forEach((item1) => {
                if (item1.questions != undefined && item1.questions != null && item1.questions.length > 0) {
                  item1.questions.forEach((item2) => {
                    allQuesArray1.push(item2);
                  })
                }
                if (item1.subRisks != undefined && item1.subRisks != null && item1.subRisks.length > 0) {
                  getAllQuestionsInSubRisksForCollection1(item1.subRisks);
                }
              });
            }
          });
          var takenFlag = true;
          var takenFlag = false;
          var tmpRiskData = [];
          var headerMsg = "";
          var selectedRiskArr = [];
          selectedRiskArrForEval.forEach((item1) => {
            if (item1 === "ALL") {
              takenFlag = true;
              allQuesArray1.forEach((data) => {
                tmpRiskData.push(data);
                takenFlag = false;
                headerMsg = "All";
              });
            } else {
              var k = 0;
              selectedRiskEvalArray.forEach((item) => {
                if (item.collectionRiskTypeId === item1) {
                  selectedRiskArr.push(item.collectionRiskTypeId);
                  takenFlag = true;
                  item.collectionQuestionIds.forEach((quesId) => {
                    allQuesArray1.forEach((data) => {
                      if (data.questionId === quesId) {
                        tmpRiskData.push(data);
                        takenFlag = false;
                      }
                    });
                  });
                  if (k === 0) {
                    headerMsg = item.name;
                  } else {
                    headerMsg += ", " + item.name;
                  }
                  k++;
                }
              });

            }

          })

          if (takenFlag) {
            if (takenFlag) {
              alert("Please note that you don't have any Questioners for the selected Risk Types")
            } else {
              alert("Please select atleast one Risk Type for evaluation")
            }
          } else {
            //setRiskEvalHeaderMsg(headerMsg);

            var count = 1;
            var tmpArray = [];
            setRows([]);
            tmpRiskData.forEach((item) => {
              var tmpObj = {};
              tmpObj["questionId"] = item.questionId;
              tmpObj["collectionId"] = selectedCollectionEvaluateId;
              tmpObj["collectionUniqueName"] = selectedCollectionEvaluateUniqueName;
              tmpObj["id"] = count;
              var riskScrubStr = RiskScrumb(item.riskTypes.name, item.riskTypes.collectionRiskId, riskTypeData)
              tmpObj["riskName"] = riskScrubStr;

              tmpObj["objectiveName"] = item.objective.name;
              tmpObj["componentName"] = item.component.name;
              tmpObj["principalName"] = item.principle.name;
              tmpObj["entityName"] = item.entity.name;
              tmpObj["question"] = item.question;
              tmpObj["questionType"] = item.questionType;
              tmpObj["questionScore"] = item.questionScore;
              tmpObj["questionData"] = item;
              console.log("++++++++++++++++++++++tmpRiskData++++++++++++++++++++")
              console.log(tmpRiskData)
              console.log(tmpObj)
              tmpArray.push(tmpObj);

              count++;
            })
            setWaitingOpen(false);
            setRows(tmpArray);
            setRiskEvalTypeOpen(false);
            setOpenCollectionData(true);
            setOpenCollectionDataScroll('paper');
          }







        })
        .catch(function (error) {
          setWaitingOpen(false);
          console.log(error);
        });





    }, 600);
    /** 
    reloadAll();
  
    setTimeout(()=> {
      setRiskTypeData([]);
      console.log("+++++++++++++++allCollectionDataTesting +++++++++++ Testing ++++++++++++++");
      console.log(allCollectionData);
      allCollectionData.forEach((data)=>{
        if (data.id === selectedCollectionEvaluateId) {
          var tmpRiskArr = [];
          if (data.risks != undefined && data.risks != null && data.risks.length > 0){
            tmpRiskArr = data.risks; 
          }
          setSelectedCollectionEvaluateUniqueName(data.collectionUniqueName);
          setRiskTypeData(tmpRiskArr);
          getAllQuestionsForCollection(tmpRiskArr);
        }
      });
  
  
  
      //setOpenCollectionDataFuction(true, selectedCollectionEvaluateId, selectedCollectionEvaluateTitle)
      setTimeout(()=> {
        setRiskTypeEvalFuction();
      }, 500);  
  
    }, 600);  
    
  */


  }




  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            parseId(params.row.id, params.row.questionId, params.row.collectionId, params.row.question)
          }}
        >
          More Info
        </Button>
      </strong>
    )
  }

  const renderTextRiskName = (params) => {
    return (
      <Tooltip title={params.row.riskName}>
        <span className={classes.fontZise10}>{params.row.riskName}</span>
      </Tooltip>
    )
  }
  const renderTextId = (params) => {
    return (
      <span className={classes.fontZise10}>{params.row.id}</span>
    )
  }
  const renderTextObjectiveName = (params) => {
    return (
      <Tooltip title={params.row.objectiveName}>
        <span className={classes.fontZise10}>{params.row.objectiveName}</span>
      </Tooltip>
    )
  }
  const renderTextComponentName = (params) => {
    return (
      <Tooltip title={params.row.componentName}>
        <span className={classes.fontZise10}>{params.row.componentName}</span>
      </Tooltip>
    )
  }
  const renderTextPrincipalName = (params) => {
    return (
      <Tooltip title={params.row.principalName}>
        <span className={classes.fontZise10}>{params.row.principalName}</span>
      </Tooltip>
    )
  }
  const renderTextQuestion = (params) => {
    return (
      <Tooltip title={params.row.question}>
        <span className={classes.fontZise10}>{params.row.question}</span>
      </Tooltip>
    )
  }
  const renderTextEntity = (params) => {
    return (
      <Tooltip title={params.row.entityName}>
        <span className={classes.fontZise10}>{params.row.entityName}</span>
      </Tooltip>
    )
  }

  const renderTextQuestionType = (params) => {
    return (
      <span className={classes.fontZise10}>{params.row.questionType}</span>
    )
  }
  const renderTextQuestionScore = (params) => {
    var riskLvlColor = getRiskLvlColor(params.row.questionScore, params.row.questionScoreMethodId)
    return (
      <span className={classes.fontZise10} style={{ color: riskLvlColor }}>{params.row.questionScore}</span>
    )
  }



  const columns = [
    { field: 'id', headerName: 'No', renderCell: renderTextId, resizable: true, width: 15 },
    { field: 'questionId', headerName: 'No', width: 15, hide: true },
    { field: 'collectionId', headerName: 'No', width: 15, hide: true },
    { field: 'collectionUniqueName', headerName: 'No', width: 15, hide: true },
    { field: 'riskName', headerName: 'Risk', renderCell: renderTextRiskName, resizable: true, cellClassName: 'super-app-theme--cell', width: 180 },
    { field: 'objectiveName', headerName: 'Objective', renderCell: renderTextObjectiveName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
    { field: 'componentName', headerName: 'Component', renderCell: renderTextComponentName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
    { field: 'principalName', headerName: 'Principal', renderCell: renderTextPrincipalName, resizable: true, cellClassName: 'super-app-theme--cell', width: 100 },
    { field: 'entityName', headerName: 'Entity', renderCell: renderTextEntity, cellClassName: 'super-app-theme--cell', resizable: true, width: 80 },
    { field: 'question', headerName: 'Question', renderCell: renderTextQuestion, cellClassName: 'super-app-theme--cell', resizable: true, width: 200 },
    { field: 'questionType', headerName: 'Type', renderCell: renderTextQuestionType, resizable: true, width: 80 },
    { field: 'questionScoreMethodId', headerName: 'No', width: 15, hide: true },
    { field: 'questionScore', headerName: 'Score', renderCell: renderTextQuestionScore, resizable: true, width: 50 },
    {
      field: 'action',
      headerName: 'More Info',
      sortable: false,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,

    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,

    }

  ];


  /** Evaluate All Questions */
  const [evaluateAllOpen, setEvaluateAllOpen] = React.useState(false);
  const [evaluateAllScroll, setEvaluateAllScroll] = React.useState('paper');
  const [closeEndedData, setCloseEndedData] = React.useState([]);
  const [openEndedData, setOpenEndedData] = React.useState([]);
  const [closeEndedTotal, setCloseEndedTotal] = React.useState({});

  const evaluateAllHandleClickClose = () => {
    setEvaluateAllOpen(false);
  };


  function evaluateAll() {
    console.log(rows);
    var countCloseEnded = 1;
    var countOpenEnded = 1;
    var closeEndedArray = [];
    var openEndedArray = [];
    var tmpCOTotalQuestionWeight = 0;
    var tmpCOTotalUsers = 0;
    var tmpCOTotalAnswer1 = 0;
    var tmpCOTotalAnswer2 = 0;
    var tmpCOTotalAnswer3 = 0;
    var tmpCOTotalAnswer4 = 0;
    var tmpCOTotalAnswer5 = 0;
    rows.forEach((data, index) => {
      var tmpArray = [];
      var objAllCe = {};
      var objAllOe = {};
      var tmpDataArray = [];
      var questionData = data.questionData;
      if (data.questionType === "Closeended") {
        objAllCe["id"] = countCloseEnded;
        objAllCe["questionId"] = questionData.questionId;
        objAllCe["question"] = questionData.question;
        objAllCe["questionType"] = questionData.questionType;
        objAllCe["questionWeigth"] = questionData.questionScore;
        objAllCe["noOfRespondedUsers"] = 1;
        tmpCOTotalUsers += 1;
        objAllCe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
        tmpCOTotalQuestionWeight += (1 * parseInt(questionData.questionScore));
        objAllCe["questionData"] = questionData;
        countCloseEnded++;

        if (questionData.answers.answerMethod.name != "" && questionData.answers.answerList.length > 0) {
          var tmpAnsName = '';
          var tmpRiskLevel = '';
          var tmpTotalUserValue = 0;
          var count1 = 1;
          questionData.answers.answerList.forEach((ansData, index2) => {
            var tmpRiskLevel = ansData.riskLevel;
            tmpAnsName = ansData.answerName;
            tmpTotalUserValue = 0;
            if (ansData.expectedAnswerName === "Yes" || ansData.expectedAnswerName === "checked") {
              tmpTotalUserValue = 1;
            } else {
              if (ansData.expectedAnswerName != '') {
                tmpTotalUserValue = 1;
              }
            }
            var objData = {};
            objData["answerName"] = tmpAnsName;
            objData["riskLevel"] = tmpRiskLevel;
            objData["userResponseCount"] = tmpTotalUserValue;
            tmpDataArray.push(objData);
          })
        }

      } else {
        objAllOe["id"] = countOpenEnded;
        objAllOe["questionId"] = questionData.questionId;
        objAllOe["question"] = questionData.question;
        objAllOe["questionType"] = questionData.questionType;
        objAllOe["questionWeigth"] = questionData.questionScore;
        objAllOe["noOfRespondedUsers"] = 1;
        objAllOe["totalQuestionWeigth"] = (1 * parseInt(questionData.questionScore));
        objAllOe["questionData"] = questionData;
        //objAllOe["responseUserData"] = tmpArray; 
        countOpenEnded++;
        var objData = {};
        objData["answer"] = questionData.answers.answer;
        tmpDataArray.push(objData);

      }
      if (Object.keys(objAllCe).length != 0) {
        var answerLevelArr = [];
        var level1 = false;
        var level2 = false;
        var level3 = false;
        var level4 = false;
        var level5 = false;
        tmpDataArray.forEach((itemDataData) => {
          var riskLevelVal = parseInt(itemDataData.riskLevel);
          if (riskLevelVal === -5 || riskLevelVal === -3 || (riskLevelVal > -5 && riskLevelVal < -3)) {
            level1 = true;
            var obj = {}
            obj["no"] = 1;
            obj["riskName"] = 'Heighly Negative';
            obj["riskLevel"] = '-5';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = -5 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer1 += -5 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === -2 || riskLevelVal === 0 || (riskLevelVal > -2 && riskLevelVal < 0)) {
            level2 = true;
            var obj = {}
            obj["no"] = 2;
            obj["riskName"] = 'Negative';
            obj["riskLevel"] = '-2';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = -2 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer2 += -2 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === 1 || riskLevelVal === 3 || (riskLevelVal > 1 && riskLevelVal < 3)) {
            level3 = true;
            var obj = {}
            obj["no"] = 3;
            obj["riskName"] = 'Somewhat Positive';
            obj["riskLevel"] = '1';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 1 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer3 += 1 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === 4 || riskLevelVal === 7 || (riskLevelVal > 4 && riskLevelVal < 7)) {
            level4 = true;
            var obj = {}
            obj["no"] = 4;
            obj["riskName"] = 'Positive';
            obj["riskLevel"] = '4';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 4 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer4 += 4 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
          if (riskLevelVal === 8 || riskLevelVal === 10 || (riskLevelVal > 8 && riskLevelVal < 10)) {
            level5 = true;
            var obj = {}
            obj["no"] = 5;
            obj["riskName"] = 'Heightly Positive';
            obj["riskLevel"] = '8';
            obj["userResponseCount"] = itemDataData.userResponseCount;
            obj["totalRiskLevel"] = 8 * parseInt(itemDataData.userResponseCount);
            tmpCOTotalAnswer5 += 8 * parseInt(itemDataData.userResponseCount);
            answerLevelArr.push(obj);
          }
        })
        if (!level1) {
          var obj = {}
          obj["no"] = 1;
          obj["riskName"] = 'Heighly Negative';
          obj["riskLevel"] = '-5';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level2) {
          var obj = {}
          obj["no"] = 2;
          obj["riskName"] = 'Negative';
          obj["riskLevel"] = '-2';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level3) {
          var obj = {}
          obj["no"] = 3;
          obj["riskName"] = 'Somewhat Positive';
          obj["riskLevel"] = '1';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level4) {
          var obj = {}
          obj["no"] = 4;
          obj["riskName"] = 'Positive';
          obj["riskLevel"] = '4';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        if (!level5) {
          var obj = {}
          obj["no"] = 5;
          obj["riskName"] = 'Heightly Positive';
          obj["riskLevel"] = '8';
          obj["userResponseCount"] = 0;
          obj["totalRiskLevel"] = 0;
          answerLevelArr.push(obj);
        }
        answerLevelArr = answerLevelArr.sort(compareRiskNo);
        var no = 0;
        var totalRiskLevel = 0;
        var riskName = '';
        var riskLevel = '';
        var userResponseCount = 0;
        var tmpAnswerLevelArr = [];
        //console.log(answerLevelArr);
        answerLevelArr.forEach((itemDataData, index2) => {
          if (index2 === 0) {
            no = itemDataData.no;
            totalRiskLevel = itemDataData.totalRiskLevel;
            riskName = itemDataData.riskName;
            riskLevel = itemDataData.riskLevel;
            userResponseCount = itemDataData.userResponseCount;
            if ((answerLevelArr.length - 1) === index2) {
              var obj = {};
              obj["no"] = no;
              obj["riskName"] = riskName;
              obj["riskLevel"] = riskLevel;
              obj["userResponseCount"] = userResponseCount;
              obj["totalRiskLevel"] = totalRiskLevel;
              tmpAnswerLevelArr.push(obj);
            }
          } else {
            if (no === itemDataData.no) {
              totalRiskLevel += itemDataData.totalRiskLevel;
              riskName = itemDataData.riskName;
              riskLevel = itemDataData.riskLevel;
              userResponseCount += itemDataData.userResponseCount;
              if ((answerLevelArr.length - 1) === index2) {
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
              }
            } else {
              var obj = {};
              obj["no"] = no;
              obj["riskName"] = riskName;
              obj["riskLevel"] = riskLevel;
              obj["userResponseCount"] = userResponseCount;
              obj["totalRiskLevel"] = totalRiskLevel;
              tmpAnswerLevelArr.push(obj);
              no = itemDataData.no;
              totalRiskLevel = itemDataData.totalRiskLevel;
              riskName = itemDataData.riskName;
              riskLevel = itemDataData.riskLevel;
              userResponseCount = itemDataData.userResponseCount;
              if ((answerLevelArr.length - 1) === index2) {
                var obj = {};
                obj["no"] = no;
                obj["riskName"] = riskName;
                obj["riskLevel"] = riskLevel;
                obj["userResponseCount"] = userResponseCount;
                obj["totalRiskLevel"] = totalRiskLevel;
                tmpAnswerLevelArr.push(obj);
              }
            }
          }
        })

        //console.log(tmpAnswerLevelArr);
        tmpAnswerLevelArr = tmpAnswerLevelArr.sort(compareRiskNo);
        objAllCe["answerRiskLevelList"] = tmpAnswerLevelArr;
      } else {
        objAllOe["answers"] = tmpDataArray;
      }
      if (Object.keys(objAllCe).length === 0) {
        openEndedArray.push(objAllOe);
      }
      if (Object.keys(objAllOe).length === 0) {
        closeEndedArray.push(objAllCe);
      }
    });
    var obj = {}
    obj["totalQuestionWeight"] = tmpCOTotalQuestionWeight;
    obj["totalUsers"] = tmpCOTotalUsers;
    obj["totalAnswer1"] = tmpCOTotalAnswer1;
    obj["totalAnswer2"] = tmpCOTotalAnswer2;
    obj["totalAnswer3"] = tmpCOTotalAnswer3;
    obj["totalAnswer4"] = tmpCOTotalAnswer4;
    obj["totalAnswer5"] = tmpCOTotalAnswer5;


    console.log(closeEndedArray);
    console.log(obj);
    setCloseEndedData(closeEndedArray);
    setOpenEndedData(openEndedArray);
    setCloseEndedTotal(obj);

    setEvaluateAllOpen(true);
    setEvaluateAllScroll('paper');

  }
















  /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  const [openExistingRiskCollection, setOpenExistingRiskCollection] = useState(false);
  const [openExistingRiskCollectionPos, setOpenExistingRiskCollectionPos] = useState(0);





  /** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


  const [openRiskHierarchy, setOpenRiskHierarchy] = useState(false);
  const [openExistingRiskHierarchyPos, setOpenExistingRiskHierarchyPos] = useState(0);




  function setOpenRiskHierarchyFuction(flag) {

    var maxZIndex = findMaxZIndex();
    setOpenRiskHierarchyZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openRiskHierarchy');


    if (!openRiskHierarchy) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenRiskHierarchyOrder(openOrderNo);

      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId3 = document.getElementById("tabId3");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenRiskHierarchy');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId3.offsetTop + topBtnId.offsetTop));
      console.log(pos)

      setOpenExistingRiskHierarchyPos(pos);
      setOpenRiskHierarchy(true);
      setOpenRiskHierarchyOriginalWidth(width);
      setOpenRiskHierarchyOriginalHeight(height);
      setOpenRiskHierarchyOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(5, openOrderNo);

    }

  }


  const [openGlobalStandard, setOpenGlobalStandard] = useState(false);
  const [openGlobalStandardPos, setOpenGlobalStandardPos] = useState(0);

  function setOpenGlobalStandardFuction(flag) {

    var maxZIndex = findMaxZIndex();
    setOpenGlobalStandardZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openGlobalStandard');

    if (!openGlobalStandard) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenGlobalStandardOrder(openOrderNo);

      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      
      var tabId5 = document.getElementById("tabId5");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenGlobalStandard');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId5.offsetTop + topBtnId.offsetTop));
      console.log(pos)
      setOpenGlobalStandardPos(pos);

      */
      viewLayersFuction(3, openOrderNo);

    }




  }


  const [openCreateNewCollection, setOpenCreateNewCollection] = useState(false);
  const [openCreateNewCollectionPos, setOpenCreateNewCollectionPos] = useState(0);

  function setOpenCreateNewCollectionFuction(flag) {


    var maxZIndex = findMaxZIndex();
    setOpenCreateNewCollectionZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openCreateNewCollection');

    setOpenCreateNewCollectionWithColId('');


    if (!openCreateNewCollection) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenCreateNewCollectionOrder(openOrderNo);

      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId6 = document.getElementById("tabId6");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenCreateNewCollection');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId6.offsetTop + topBtnId.offsetTop));
      console.log(pos)

      setOpenCreateNewCollectionPos(pos);
      */


      viewLayersFuction(2, openOrderNo);


    }

  }


  const [openEditCollection, setOpenEditCollection] = useState(false);
  const [openEditCollectionPos, setOpenEditCollectionPos] = useState(0);

  function setOpenEditCollectionFuction(flag) {


    var maxZIndex = findMaxZIndex();
    setOpenEditCollectionZIndex(maxZIndex + 1);

    if (!openEditCollection) {

      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");

      var tabId12 = document.getElementById("tabId12");
      var addToTopAmount = addToTopPos();
      addToTop('divOpenEditCollection');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
      console.log(pos)

      setOpenEditCollectionPos(pos);
      setOpenEditCollection(true);
    }

  }


  const [openCreateNewCollectionWithColId, setOpenCreateNewCollectionWithColId] = useState('');
  const [openCreateNewCollectionWithColIdWhenEdit, setOpenCreateNewCollectionWithColIdWhenEdit] = useState('');

  const [openAmendCollectionData, setAmendOpenCollectionData] = useState(false);
  const [openAmendCollectionDataScroll, setOpenAmendCollectionDataScroll] = useState('paper');


  function setOpenCreateNewCollectionFuctionWithEdit(colId) {

    /*
    var maxZIndex = findMaxZIndex();
    setOpenEditCollectionZIndex(maxZIndex + 1);
    setOpenEditCollectionZIndexPrevious(maxZIndex + 1);
    */
    //setOpenCreateNewCollectionWithColId(colId);

    /*
    disabledOtherDivs('divOpenEditCollection');

      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId12 = document.getElementById("tabId12");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenEditCollection');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId12.offsetTop + topBtnId.offsetTop));
      console.log(pos)

      setOpenEditCollectionPos(pos);
      setOpenEditCollection(true);
    */
    //  setAmendOpenCollectionData(true);  
    //  setOpenAmendCollectionDataScroll('paper');




    var maxZIndex = findMaxZIndex();
    setOpenEditCollectionZIndex(maxZIndex + 1);

    setOpenCreateNewCollectionWithColIdWhenEdit(colId);
    //alert(openEditCollection);
    if (!openEditCollection) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenEditCollectionOrder(openOrderNo);
      viewLayersFuction(10, openOrderNo);
    }


  }


  const [centreMaintenance, setCentreMaintenance] = useState(false);
  const [openCentreMaintenancePos, setOpenCentreMaintenancePos] = useState(0);

  function setOpenCentreMaintenanceFuction(flag) {

    var maxZIndex = findMaxZIndex();
    setOpenCentreMaintenanceZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('centreMaintenance');

    if (!centreMaintenance) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenCentreMaintenanceOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId7 = document.getElementById("tabId7");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenCentreMaintenance');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId7.offsetTop + topBtnId.offsetTop));
      setOpenCentreMaintenancePos(pos);
      setCentreMaintenance(true);
      setOpenCentreMaintenanceOriginalWidth(width);
      setOpenCentreMaintenanceOriginalHeight(height);
      setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(6, openOrderNo);


    }



  }

  const [opensliderMethods, setOpensliderMethods] = useState(false);
  const [opensliderMethodsPos, setOpensliderMethodsPos] = useState(0);

  function setOpenSliderMethodsFuction(flag) {

    var maxZIndex = findMaxZIndex();
    setOpenSliderMethodsZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('opensliderMethods');

    if (!opensliderMethods) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpensliderMethodsOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId8 = document.getElementById("tabId8");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenSliderMethods');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId8.offsetTop + topBtnId.offsetTop));
      setOpensliderMethodsPos(pos);
      setOpensliderMethods(true);
      setOpensliderMethodsOriginalWidth(width);
      setOpensliderMethodsOriginalHeight(height);
      setOpensliderMethodsOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(7, openOrderNo);

    }

  }

  const [openAddColor, setOpenAddColor] = useState(false);
  const [opeAddColorPos, setOpeAddColorPos] = useState(0);

  function setOpenAddColorFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenAddColorZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openAddColor');

    if (!openAddColor) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenAddColorOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId9 = document.getElementById("tabId9");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenAddColor');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId9.offsetTop + topBtnId.offsetTop));
      setOpeAddColorPos(pos);
      setOpenAddColor(true);
      setOpenAddColorOriginalWidth(width);
      setOpenAddColorOriginalHeight(height);
      setOpenAddColorOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(8, openOrderNo);
    }


  }

  const [openTransferQuestionnaire, setOpenTransferQuestionnaire] = useState(false);
  const [openTransferQuestionnairePos, setOpenTransferQuestionnairePos] = useState(0);

  function setOpenTransferQuestionnaireFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenTransferQuestionnaireZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openTransferQuestionnaire');

    if (!openTransferQuestionnaire) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenTransferQuestionnaireOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId14 = document.getElementById("tabId14");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenTransferQuestionnaire');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(9, openOrderNo);
    }

  }

  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [openAddQuestionPos, setOpenAddQuestionPos] = useState(0);

  function setOpenAddQuestionFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenAddQuestionZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openAddQuestion');

    if (!openAddQuestion) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenAddQuestionOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId14 = document.getElementById("tabId14");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenTransferQuestionnaire');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(11, openOrderNo);
    }

  }

  const [openAnswerScale, setOpenAnswerScale] = useState(false);
  const [openAnswerScalePos, setOpenAnswerScalePos] = useState(0);

  function setOpenAnswerScaleFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenAnswerScaleZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openAnswerScale');

    if (!openAnswerScale) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openEvaluaterRisk && !openRiskRegister) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenAnswerScaleOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId14 = document.getElementById("tabId14");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenTransferQuestionnaire');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(12, openOrderNo);
    }

  }

  const [openEvaluaterRisk, setOpenEvaluaterRisk] = useState(false);
  const [openEvaluaterRiskPos, setOpenEvaluaterRiskPos] = useState(0);

  function setOpenEvaluaterRiskFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenEvaluaterRiskZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openEvaluaterRisk');

    if (!openEvaluaterRisk) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openAnswerScale) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenEvaluaterRiskOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId14 = document.getElementById("tabId14");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenTransferQuestionnaire');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(14, openOrderNo);
    }

  }

  const [openRiskRegister, setOpenRiskRegister] = useState(false);
  const [openRiskRegisterPos, setOpenRiskRegisterPos] = useState(0);

  function setOpenRiskRegisterFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenRiskRegisterZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openRiskRegister');

    if (!openRiskRegister) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord && !openAnswerScale && !openEvaluaterRisk) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenRiskRegisterOrder(openOrderNo);
      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId14 = document.getElementById("tabId14");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenTransferQuestionnaire');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId14.offsetTop + topBtnId.offsetTop));
      setOpenTransferQuestionnairePos(pos);
      setOpenTransferQuestionnaire(true);
      setOpenTransferQuestionnaireOriginalWidth(width);
      setOpenTransferQuestionnaireOriginalHeight(height);
      setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(15, openOrderNo);
    }

  }



  const [openQuestionerFromDashBord, setOpenQuestionerFromDashBord] = useState(false);
  const [openQuestionerFromDashBordPos, setOpenQuestionerFromDashBordPos] = useState(0);
  const [openEvaluaterRiskFromDashBordPos, setOpenEvaluaterRiskFromDashBordPos] = useState(0);
  const [openRiskRegisterFromDashBordPos, setOpenRiskRegisterFromDashBordPos] = useState(0);

  /** To open the Questiner Page for Collection edit page */
  const [fromCollectionManagementEditPage, setFromCollectionManagementEditPage] = useState(false);

  const [fromEditPageCollectionRiskId, setFromEditPageCollectionRiskId] = useState('');
  const [fromEditPageCollectionRiskTypeId, setFromEditPageCollectionRiskTypeId] = useState('');
  const [fromEditPageRiskTypeName, setFromEditPageRiskTypeName] = useState('');
  const [fromEditPageRiskLevel, setFromEditPageRiskLevel] = useState('');
  const [fromEditPageRiskLevelCategoryId, setFromEditPageRiskLevelCategoryId] = useState('');
  const [fromEditPageRiskLevelMethodId, setFromEditPageRiskLevelMethodId] = useState('');
  const [fromEditPageRiskAppetite, setFromEditPageRiskAppetite] = useState('');
  const [fromEditPageRiskAppetiteCategoryId, setFromEditPageRiskAppetiteCategoryId] = useState('');
  const [fromEditPageRiskAppetiteMethodId, setFromEditPageRiskAppetiteMethodId] = useState('');
  const [fromEditPageQuestionId, setFromEditPageQuestionId] = useState('');

  const [fromEditPageCollectionRiskIdTmp, setFromEditPageCollectionRiskIdTmp] = useState('');
  const [fromEditPageCollectionRiskTypeIdTmp, setFromEditPageCollectionRiskTypeIdTmp] = useState('');
  const [fromEditPageRiskTypeNameTmp, setFromEditPageRiskTypeNameTmp] = useState('');
  const [fromEditPageRiskLevelTmp, setFromEditPageRiskLevelTmp] = useState('');
  const [fromEditPageRiskLevelCategoryIdTmp, setFromEditPageRiskLevelCategoryIdTmp] = useState('');
  const [fromEditPageRiskLevelMethodIdTmp, setFromEditPageRiskLevelMethodIdTmp] = useState('');
  const [fromEditPageRiskAppetiteTmp, setFromEditPageRiskAppetiteTmp] = useState('');
  const [fromEditPageRiskAppetiteCategoryIdTmp, setFromEditPageRiskAppetiteCategoryIdTmp] = useState('');
  const [fromEditPageRiskAppetiteMethodIdTmp, setFromEditPageRiskAppetiteMethodIdTmp] = useState('');
  const [fromEditPageQuestionIdTmp, setFromEditPageQuestionIdTmp] = useState('');


  if (fromCollectionManagementEditPage) {
    setFromCollectionManagementEditPage(false);
    setFromEditPageCollectionRiskIdTmp(fromEditPageCollectionRiskId);
    setFromEditPageCollectionRiskTypeIdTmp(fromEditPageCollectionRiskTypeId);
    setFromEditPageRiskTypeNameTmp(fromEditPageRiskTypeName);
    setFromEditPageRiskLevelTmp(fromEditPageRiskLevel);
    setFromEditPageRiskLevelCategoryIdTmp(fromEditPageRiskLevelCategoryId);
    setFromEditPageRiskLevelMethodIdTmp(fromEditPageRiskLevelMethodId);
    setFromEditPageRiskAppetiteTmp(fromEditPageRiskAppetite);
    setFromEditPageRiskAppetiteCategoryIdTmp(fromEditPageRiskAppetiteCategoryId);
    setFromEditPageRiskAppetiteMethodIdTmp(fromEditPageRiskAppetiteMethodId);
    setFromEditPageQuestionIdTmp(fromEditPageQuestionId);
    if (openQuestionerFromDashBord && fromEditPageQuestionId != '') {
      var maxZIndex = findMaxZIndex();
      setOpenQuestionerFromDashBordZIndex(maxZIndex + 1);
      var openOrderNo = openQuestionerFromDashBordOrder
      viewLayersFuction(13, openOrderNo);
    } else {
      setOpenQuestionerFromDashBordFuction();
    }

  }

  const [fromMultiTaskin, setFromMultiTaskin] = useState(true)



  function setOpenQuestionerFromDashBordFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenQuestionerFromDashBordZIndex(maxZIndex + 1);

    if (!openQuestionerFromDashBord) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenQuestionerFromDashBordOrder(openOrderNo);
      viewLayersFuction(13, openOrderNo);
    }

  }



  const [customStandardsPos, setCustomStandardsPos] = useState(0);

  function setOpenCustomStandardFuction(flag) {
    var maxZIndex = findMaxZIndex();
    setOpenCustomStandardsZIndex(maxZIndex + 1);

    isMultiTaskingMenuOpen('openCustomStandards');

    if (!openCustomStandards) {
      var openOrderNo = 0;
      if (!openRiskCollection && !openCreateNewCollection && !openGlobalStandard && !openCustomStandards && !openRiskHierarchy && !centreMaintenance && !opensliderMethods && !openAddColor && !openEditCollection && !openTransferQuestionnaire && !openAddQuestion && !openAnswerScale && !openQuestionerFromDashBord) {
        setOpenOrderNumber(1);
        openOrderNo = 1;
      } else {
        openOrderNo = openOrderNumber + 1;
      }
      setOpenOrderNumber(openOrderNo);
      setOpenCustomStandardsOrder(openOrderNo);

      /*
      var topBtnId = document.getElementById("topBtnId");
      var tabId1 = document.getElementById("tabId1");
      var tabId10 = document.getElementById("tabId10");
      var addToTopAmount =  addToTopPos();
      addToTop('divOpenCustomStandards');
      var pos = ((tabId1.offsetTop + addToTopAmount) - (tabId10.offsetTop + topBtnId.offsetTop));
      console.log(pos)
      setCustomStandardsPos(pos);
      setOpenCustomStandards(true);
      setOpenCustomStandardsOriginalWidth(width);
      setOpenCustomStandardsOriginalHeight(height);
      setOpenCustomStandardsOriginalRelatedHeight(relatedDivLoadingHeight);
      */
      viewLayersFuction(4, openOrderNo);
    }



  }

  // *******************************************************************************



  const handleRiskArticle = () => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://www.techtarget.com/searchsecurity/definition/What-is-risk-management-and-why-is-it-important';
    a.click();
  };


  //menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (menuNotAvailableMeg != "") {
      alert(menuNotAvailableMeg);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    if (menuNotAvailableMeg != "") {
      alert(menuNotAvailableMeg);
      return;
    }
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };



  function divActiveFuction(divId) {

    if (divId === "divOpenComment") {
      var maxZIndex = findMaxZIndex();
      setOpenCommentZIndex(maxZIndex + 1);
    }

    if (divId === "divOpenRiskCollection") {
      var maxZIndex = findMaxZIndex();
      setOpenRiskCollectionZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenCollectionData") {
      var maxZIndex = findMaxZIndex();
      setOpenCollectionDataZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenRiskHierarchy") {
      var maxZIndex = findMaxZIndex();
      if (maxZIndex != openRiskHierarchyZIndexPrevious) {
        setOpenRiskHierarchyZIndexPrevious(maxZIndex + 1)
        setOpenRiskHierarchyZIndex(maxZIndex + 1);
      }

    }
    if (divId === "divOpenGlobalStandard") {
      var maxZIndex = findMaxZIndex();
      setOpenGlobalStandardZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenCreateNewCollection") {
      var maxZIndex = findMaxZIndex();
      if (maxZIndex != openCreateNewCollectionZIndexPrevious) {
        setOpenCreateNewCollectionZIndex(maxZIndex + 1);
        setOpenCreateNewCollectionZIndexPrevious(maxZIndex + 1);
      }

    }
    if (divId === "divOpenEditCollection") {
      var maxZIndex = findMaxZIndex();
      if (maxZIndex != openEditCollectionZIndexPrevious) {
        setOpenEditCollectionZIndex(maxZIndex + 1);
        setOpenEditCollectionZIndexPrevious(maxZIndex + 1);
      }

    }
    if (divId === "divOpenAddColor") {
      var maxZIndex = findMaxZIndex();
      setOpenAddColorZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenSliderMethods") {
      var maxZIndex = findMaxZIndex();
      setOpenSliderMethodsZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenCustomStandards") {
      var maxZIndex = findMaxZIndex();
      setOpenCustomStandardsZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenCentreMaintenance") {
      var maxZIndex = findMaxZIndex();
      setOpenCentreMaintenanceZIndex(maxZIndex + 1);
    }

    if (divId === "divOpenTransferQuestionnaire") {
      var maxZIndex = findMaxZIndex();
      setOpenTransferQuestionnaireZIndex(maxZIndex + 1);
    }

    if (divId === "divOpenAddQuestion") {
      var maxZIndex = findMaxZIndex();
      setOpenAddQuestionZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenAnswerScale") {
      var maxZIndex = findMaxZIndex();
      setOpenAnswerScaleZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenQuestionerFromDashBord") {
      var maxZIndex = findMaxZIndex();
      setOpenQuestionerFromDashBordZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenEvaluaterRisk") {
      var maxZIndex = findMaxZIndex();
      setOpenEvaluaterRiskZIndex(maxZIndex + 1);
    }
    if (divId === "divOpenRiskRegister") {
      var maxZIndex = findMaxZIndex();
      setOpenRiskRegisterZIndex(maxZIndex + 1);
    }


  }

  function findMaxZIndex() {
    var maxZIndex = 1;

    maxZIndex = openCommentZIndex;
    if (openRiskCollectionZIndex > maxZIndex) {
      maxZIndex = openRiskCollectionZIndex;
    }
    if (openCollectionDataZIndex > maxZIndex) {
      maxZIndex = openCollectionDataZIndex;
    }
    if (openRiskHierarchyZIndex > maxZIndex) {
      maxZIndex = openRiskHierarchyZIndex;
    }
    if (openGlobalStandardZIndex > maxZIndex) {
      maxZIndex = openGlobalStandardZIndex;
    }
    if (openCreateNewCollectionZIndex > maxZIndex) {
      maxZIndex = openCreateNewCollectionZIndex;
    }
    if (openEditCollectionZIndex > maxZIndex) {
      maxZIndex = openEditCollectionZIndex;
    }
    if (openAddColorZIndex > maxZIndex) {
      maxZIndex = openAddColorZIndex;
    }
    if (openSliderMethodsZIndex > maxZIndex) {
      maxZIndex = openSliderMethodsZIndex;
    }
    if (openCustomStandardsZIndex > maxZIndex) {
      maxZIndex = openCustomStandardsZIndex;
    }
    if (openCentreMaintenanceZIndex > maxZIndex) {
      maxZIndex = openCentreMaintenanceZIndex;
    }
    if (openTransferQuestionnaireZIndex > maxZIndex) {
      maxZIndex = openTransferQuestionnaireZIndex;
    }
    if (openAddQuestionZIndex > maxZIndex) {
      maxZIndex = openAddQuestionZIndex;
    }
    if (openAnswerScaleZIndex > maxZIndex) {
      maxZIndex = openAnswerScaleZIndex;
    }
    if (openQuestionerFromDashBordZIndex > maxZIndex) {
      maxZIndex = openQuestionerFromDashBordZIndex;
    }
    if (openEvaluaterRiskZIndex > maxZIndex) {
      maxZIndex = openEvaluaterRiskZIndex;
    }
    if (openRiskRegisterZIndex > maxZIndex) {
      maxZIndex = openRiskRegisterZIndex;
    }

    return maxZIndex;

  }


  //rezite divs

  var height = '750';
  var width = '100%'

  var relatedDivOriginalHeight = '10';
  var relatedDivLoadingHeight = '10';


  const [openRiskHierarchyOriginalWidth, setOpenRiskHierarchyOriginalWidth] = useState(width);
  const [openRiskHierarchyOriginalHeight, setOpenRiskHierarchyOriginalHeight] = useState(height);
  const [openRiskHierarchyOriginalRelatedHeight, setOpenRiskHierarchyOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openGlobalStandardOriginalWidth, setOpenGlobalStandardOriginalWidth] = useState(width);
  const [openGlobalStandardOriginalHeight, setOpenGlobalStandardOriginalHeight] = useState(height);
  const [openGlobalStandardOriginalRelatedHeight, setOpenGlobalStandardOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openCreateNewCollectionOriginalWidth, setOpenCreateNewCollectionOriginalWidth] = useState(width);
  const [openCreateNewCollectionOriginalHeight, setOpenCreateNewCollectionOriginalHeight] = useState(height);
  const [openCreateNewCollectionOriginalRelatedHeight, setOpenCreateNewCollectionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openCentreMaintenanceOriginalWidth, setOpenCentreMaintenanceOriginalWidth] = useState(width);
  const [openCentreMaintenanceOriginalHeight, setOpenCentreMaintenanceOriginalHeight] = useState(height);
  const [openCentreMaintenanceOriginalRelatedHeight, setOpenCentreMaintenanceOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [opensliderMethodsOriginalWidth, setOpensliderMethodsOriginalWidth] = useState(width);
  const [opensliderMethodsOriginalHeight, setOpensliderMethodsOriginalHeight] = useState(height);
  const [opensliderMethodsOriginalRelatedHeight, setOpensliderMethodsOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openAddColorOriginalWidth, setOpenAddColorOriginalWidth] = useState(width);
  const [openAddColorOriginalHeight, setOpenAddColorOriginalHeight] = useState(height);
  const [openAddColorOriginalRelatedHeight, setOpenAddColorOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openCustomStandardsOriginalWidth, setOpenCustomStandardsOriginalWidth] = useState(width);
  const [openCustomStandardsOriginalHeight, setOpenCustomStandardsOriginalHeight] = useState(height);
  const [openCustomStandardsOriginalRelatedHeight, setOpenCustomStandardsOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openEditCollectionOriginalWidth, setOpenEditCollectionOriginalWidth] = useState(width);
  const [openEditCollectionOriginalHeight, setOpenEditCollectionOriginalHeight] = useState(height);
  const [openEditCollectionOriginalRelatedHeight, setOpenEditCollectionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openCollectionDataOriginalWidth, setOpenCollectionDataOriginalWidth] = useState(width);
  const [openCollectionDataOriginalHeight, setOpenCollectionDataOriginalHeight] = useState(height);
  const [openCollectionDataOriginalRelatedHeight, setOpenCollectionDataOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openRiskCollectionOriginalWidth, setOpenRiskCollectionOriginalWidth] = useState(width);
  const [openRiskCollectionOriginalHeight, setOpenRiskCollectionOriginalHeight] = useState(height);
  const [openRiskCollectionOriginalRelatedHeight, setOpenRiskCollectionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);


  const [openTransferQuestionnaireOriginalWidth, setOpenTransferQuestionnaireOriginalWidth] = useState(width);
  const [openTransferQuestionnaireOriginalHeight, setOpenTransferQuestionnaireOriginalHeight] = useState(height);
  const [openTransferQuestionnaireOriginalRelatedHeight, setOpenTransferQuestionnaireOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openAddQuestionOriginalWidth, setOpenAddQuestionOriginalWidth] = useState(width);
  const [openAddQuestionOriginalHeight, setOpenAddQuestionOriginalHeight] = useState(height);
  const [openAddQuestionOriginalRelatedHeight, setOpenAddQuestionOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openAnswerScaleOriginalWidth, setOpenAnswerScaleOriginalWidth] = useState(width);
  const [openAnswerScaleOriginalHeight, setOpenAnswerScaleOriginalHeight] = useState(height);
  const [openAnswerScaleOriginalRelatedHeight, setOpenAnswerScaleOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openEvaluaterRiskOriginalWidth, setOpenEvaluaterRiskOriginalWidth] = useState(width);
  const [openEvaluaterRiskOriginalHeight, setOpenEvaluaterRiskOriginalHeight] = useState(height);
  const [openEvaluaterRiskOriginalRelatedHeight, setOpenEvaluaterRiskOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openRiskRegisterOriginalWidth, setOpenRiskRegisterOriginalWidth] = useState(width);
  const [openRiskRegisterOriginalHeight, setOpenRiskRegisterOriginalHeight] = useState(height);
  const [openRiskRegisterOriginalRelatedHeight, setOpenRiskRegisterOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openQuestionerFromDashBordOriginalWidth, setOpenQuestionerFromDashBordOriginalWidth] = useState(width);
  const [openQuestionerFromDashBordOriginalHeight, setOpenQuestionerFromDashBordOriginalHeight] = useState(height);
  const [openQuestionerFromDashBordOriginalRelatedHeight, setOpenQuestionerFromDashBordOriginalRelatedHeight] = useState(relatedDivOriginalHeight);


  const [openCommentOriginalWidth, setOpenCommentOriginalWidth] = useState(width);
  const [openCommentOriginalHeight, setOpenCommentOriginalHeight] = useState(height);
  const [openCommentOriginalRelatedHeight, setOpenCommentOriginalRelatedHeight] = useState(relatedDivOriginalHeight);

  const [openCommentOriginalLeft, setOpenCommentOriginalLeft] = useState(0);
  const [openRiskHierarchyOriginalLeft, setOpenRiskHierarchyOriginalLeft] = useState(0);
  const [openGlobalStandardOriginalLeft, setOpenGlobalStandardOriginalLeft] = useState(0);
  const [openCreateNewCollectionOriginalLeft, setOpenCreateNewCollectionOriginalLeft] = useState(0);
  const [openCentreMaintenanceOriginalLeft, setOpenCentreMaintenanceOriginalLeft] = useState(0);
  const [opensliderMethodsOriginalLeft, setOpensliderMethodsOriginalLeft] = useState(0);
  const [openAddColorOriginalLeft, setOpenAddColorOriginalLeft] = useState(0);
  const [openCustomStandardsOriginalLeft, setOpenCustomStandardsOriginalLeft] = useState(0);
  const [openEditCollectionOriginalLeft, setOpenEditCollectionOriginalLeft] = useState(0);
  const [openRiskCollectionOriginalLeft, setOpenRiskCollectionOriginalLeft] = useState(20);
  const [openTransferQuestionnaireOriginalLeft, setOpenTransferQuestionnaireOriginalLeft] = useState(0);
  const [openAddQuestionOriginalLeft, setOpenAddQuestionOriginalLeft] = useState(0);
  const [openCollectionDataOriginalLeft, setOpenCollectionDataOriginalLeft] = useState(0);
  const [openAnswerScaleOriginalLeft, setOpenAnswerScaleOriginalLeft] = useState(0);
  const [openEvaluaterRiskOriginalLeft, setOpenEvaluaterRiskOriginalLeft] = useState(0);
  const [openRiskRegisterOriginalLeft, setOpenRiskRegisterOriginalLeft] = useState(0);
  const [openQuestionerFromDashBordOriginalLeft, setOpenQuestionerFromDashBordOriginalLeft] = useState(0);



  const [openRiskHierarchyOriginalTop, setOpenRiskHierarchyOriginalTop] = useState(0);
  const [openGlobalStandardOriginalTop, setOpenGlobalStandardOriginalTop] = useState(0);
  const [openCreateNewCollectionOriginalTop, setOpenCreateNewCollectionOriginalTop] = useState(0);
  const [openCentreMaintenanceOriginalTop, setOpenCentreMaintenanceOriginalTop] = useState(0);
  const [opensliderMethodsOriginalTop, setOpensliderMethodsOriginalTop] = useState(0);
  const [openAddColorOriginalTop, setOpenAddColorOriginalTop] = useState(0);
  const [openCustomStandardsOriginalTop, setOpenCustomStandardsOriginalTop] = useState(0);
  const [openEditCollectionOriginalTop, setOpenEditCollectionOriginalTop] = useState(0);
  const [openRiskCollectionOriginalTop, setOpenRiskCollectionOriginalTop] = useState(37);
  const [openTransferQuestionnaireOriginalTop, setOpenTransferQuestionnaireOriginalTop] = useState(0);
  const [openAddQuestionOriginalTop, setOpenAddQuestionOriginalTop] = useState(0);
  const [openCollectionDataOriginalTop, setOpenCollectionDataOriginalTop] = useState(0);
  const [openAnswerScaleOriginalTop, setOpenAnswerScaleOriginalTop] = useState(0);
  const [openEvaluaterRiskOriginalTop, setOpenEvaluaterRiskOriginalTop] = useState(0);
  const [openRiskRegisterOriginalTop, setOpenRiskRegisterOriginalTop] = useState(0);
  const [openQuestionerFromDashBordOriginalTop, setOpenQuestionerFromDashBordOriginalTop] = useState(0);


  /** This is for Global Standard Close 'Show all Data' display */
  const [fromGlobalStandardPage, setFromGlobalStandardPage] = useState(false);
  const [fromCustomStandardPage, setFromCustomStandardPage] = useState(false);
  const [mainDivX, setMainDivX] = React.useState(0);
  const [mainDivY, setMainDivY] = React.useState(0);

  const [mainDivXC, setMainDivXC] = React.useState(0);
  const [mainDivYC, setMainDivYC] = React.useState(0);


  if (fromGlobalStandardPage) {
    setFromGlobalStandardPage(false);
    var idDiv = document.getElementById('divOpenGlobalStandard');
    var styleDiv = idDiv.currentStyle || window.getComputedStyle(idDiv);
    idDiv.style.transform = "translate(" + mainDivX + "px," + mainDivY + "px)";

  }


  if (fromCustomStandardPage) {
    setFromCustomStandardPage(false);
    var idDiv = document.getElementById('divOpenCustomStandards');
    var styleDiv = idDiv.currentStyle || window.getComputedStyle(idDiv);
    idDiv.style.transform = "translate(" + mainDivXC + "px," + mainDivYC + "px)";

  }


  function setOpenRiskCollectionCloseFunction(flag) {
    setOpenRiskCollection(flag);
    setOpenRiskCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenRiskCollectionOrder(0);
    closeLayersFuction(1);
  }
  function setOpenCreateNewCollectionCloseFunction(flag) {
    setOpenCreateNewCollection(flag);
    setOpenCreateNewCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenCreateNewCollectionOrder(0);
    closeLayersFuction(2);
  }
  function setOpenGlobalStandardCloseFunction(flag) {
    setOpenGlobalStandard(flag);
    setOpenGlobalStandardOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenGlobalStandardOrder(0);
    closeLayersFuction(3);
  }
  function setOpenCustomStandardsCloseFunction(flag) {
    setOpenCustomStandards(flag);
    setOpenCustomStandardsOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenCustomStandardsOrder(0);
    closeLayersFuction(4);
  }
  function setOpenRiskHierarchyCloseFunction(flag) {
    setOpenRiskHierarchy(flag);
    setOpenRiskHierarchyOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenRiskHierarchyOrder(0);
    closeLayersFuction(5);
  }
  function setCentreMaintenanceCloseFunction(flag) {
    setCentreMaintenance(flag);
    setOpenCentreMaintenanceOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenCentreMaintenanceOrder(0);
    closeLayersFuction(6);
  }
  function setOpensliderMethodsCloseFunction(flag) {
    setOpensliderMethods(flag);
    setOpensliderMethodsOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpensliderMethodsOrder(0);
    closeLayersFuction(7);
  }
  function setOpenAddColorCloseFunction(flag) {
    setOpenAddColor(flag);
    setOpenAddColorOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenAddColorOrder(0);
    closeLayersFuction(8);
  }
  function setOpenEditCollectionCloseFunction(flag) {
    setOpenEditCollection(flag);
    setOpenEditCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenEditCollectionOrder(0);
    closeLayersFuction(10);
  }
  function setOpenTransferQuestionnaireCloseFunction(flag) {
    setOpenTransferQuestionnaire(flag);
    setOpenTransferQuestionnaireOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenTransferQuestionnaireOrder(0);
    closeLayersFuction(9);
  }


  function setOpenAddQuestionCloseFunction(flag) {
    setOpenAddQuestion(flag);
    setOpenAddQuestionOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenAddQuestionOrder(0);
    closeLayersFuction(11);
  }

  function setOpenAnswerScaleCloseFunction(flag) {
    setOpenAnswerScale(flag);
    setOpenAnswerScaleOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenAnswerScaleOrder(0);
    closeLayersFuction(12);
  }

  function setOpenQuestionerFromDashBordCloseFunction(flag) {
    setOpenQuestionerFromDashBord(flag);
    setOpenQuestionerFromDashBordOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenQuestionerFromDashBordOrder(0);
    closeLayersFuction(13);
  }


  function setOpenEvaluaterRiskCloseFunction(flag) {
    setOpenEvaluaterRisk(flag);
    setOpenEvaluaterRiskOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenEvaluaterRiskOrder(0);
    closeLayersFuction(14);
  }

  function setOpenRiskRegisterCloseFunction(flag) {
    setOpenRiskRegister(flag);
    setOpenRiskRegisterOriginalRelatedHeight(relatedDivOriginalHeight);
    //setOpenOrderNumber(openOrderNumber-1);
    setOpenRiskRegisterOrder(0);
    closeLayersFuction(14);
  }



  function divPlusFuction(divId) {

    if (divId === 'divOpenComment') {
      var obj = document.getElementById("divOpenComment");
      if (openCommentOriginalWidth != 0) {
        var oldWidth = openCommentOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenCommentOriginalWidth(newWidth);
          setOpenCommentOriginalLeft(openCommentOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCommentOriginalHeight != 0) {
            setOpenCommentOriginalHeight(openCommentOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }



    if (divId === 'divOpenRiskHierarchy') {
      var obj = document.getElementById("divOpenRiskHierarchy");
      if (openRiskHierarchyOriginalWidth != 0) {
        var oldWidth = openRiskHierarchyOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenRiskHierarchyOriginalWidth(newWidth);
          setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskHierarchyOriginalHeight != 0) {
            setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenCreateNewCollection') {
      var obj = document.getElementById("divOpenRiskHierarchy");
      if (openCreateNewCollectionOriginalWidth != 0) {
        var oldWidth = openCreateNewCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenCreateNewCollectionOriginalWidth(newWidth);
          setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskHierarchyOriginalHeight != 0) {
            setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenGlobalStandard') {
      if (openGlobalStandardOriginalWidth != 0) {
        var oldWidth = openGlobalStandardOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenGlobalStandardOriginalWidth(newWidth);
          setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth) / 2);
          if (openGlobalStandardOriginalHeight != 0) {
            setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenCustomStandards') {

      if (openCustomStandardsOriginalWidth != 0) {
        var oldWidth = openCustomStandardsOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenCustomStandardsOriginalWidth(newWidth);
          setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCustomStandardsOriginalHeight != 0) {
            setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenCentreMaintenance') {

      if (openCentreMaintenanceOriginalWidth != 0) {
        var oldWidth = openCentreMaintenanceOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenCentreMaintenanceOriginalWidth(newWidth);
          setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCentreMaintenanceOriginalHeight != 0) {
            setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenSliderMethods') {

      if (opensliderMethodsOriginalWidth != 0) {
        var oldWidth = opensliderMethodsOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpensliderMethodsOriginalWidth(newWidth);
          setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth) / 2);
          if (opensliderMethodsOriginalHeight != 0) {
            setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenAddColor') {

      if (openAddColorOriginalWidth != 0) {
        var oldWidth = openAddColorOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenAddColorOriginalWidth(newWidth);
          setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAddColorOriginalHeight != 0) {
            setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenEditCollection') {
      if (openEditCollectionOriginalWidth != 0) {
        var oldWidth = openEditCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenEditCollectionOriginalWidth(newWidth);
          setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openEditCollectionOriginalHeight != 0) {
            setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenTransferQuestionnaire') {

      if (openTransferQuestionnaireOriginalWidth != 0) {
        var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenTransferQuestionnaireOriginalWidth(newWidth);
          setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth) / 2);
          if (openTransferQuestionnaireOriginalHeight != 0) {
            setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenRiskCollection') {
      if (openRiskCollectionOriginalWidth != 0) {
        var oldWidth = openRiskCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenRiskCollectionOriginalWidth(newWidth);
          setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskCollectionOriginalHeight != 0) {
            setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenAddQuestion') {
      if (openAddQuestionOriginalWidth != 0) {
        var oldWidth = openAddQuestionOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenAddQuestionOriginalWidth(newWidth);
          setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAddQuestionOriginalHeight != 0) {
            setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenAnswerScale') {
      if (openAnswerScaleOriginalWidth != 0) {
        var oldWidth = openAnswerScaleOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenAnswerScaleOriginalWidth(newWidth);
          setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAnswerScaleOriginalHeight != 0) {
            setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenQuestionerFromDashBord') {
      if (openQuestionerFromDashBordOriginalWidth != 0) {
        var oldWidth = openQuestionerFromDashBordOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenQuestionerFromDashBordOriginalWidth(newWidth);
          setOpenQuestionerFromDashBordOriginalLeft(openQuestionerFromDashBordOriginalLeft + (oldWidth - newWidth) / 2);
          if (openQuestionerFromDashBordOriginalHeight != 0) {
            setOpenQuestionerFromDashBordOriginalHeight(openQuestionerFromDashBordOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenEvaluaterRisk') {
      if (openEvaluaterRiskOriginalWidth != 0) {
        var oldWidth = openEvaluaterRiskOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenEvaluaterRiskOriginalWidth(newWidth);
          setOpenEvaluaterRiskOriginalLeft(openEvaluaterRiskOriginalLeft + (oldWidth - newWidth) / 2);
          if (openEvaluaterRiskOriginalHeight != 0) {
            setOpenEvaluaterRiskOriginalHeight(openEvaluaterRiskOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenRiskRegister') {
      if (openRiskRegisterOriginalWidth != 0) {
        var oldWidth = openRiskRegisterOriginalWidth.toString();
        var newWidth = oldWidth * 110 / 100;
        if (((newWidth / width) * 100) < 180) {
          setOpenRiskRegisterOriginalWidth(newWidth);
          setOpenRiskRegisterOriginalLeft(openRiskRegisterOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskRegisterOriginalHeight != 0) {
            setOpenRiskRegisterOriginalHeight(openRiskRegisterOriginalHeight.toString() * 110 / 100)
          }
        }
      }
    }

    if (openRiskCollection === true) {
      if (divId === undefined) {
        if (openRiskCollectionOriginalWidth != 0) {
          var oldWidth = openRiskCollectionOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenRiskCollectionOriginalWidth(newWidth);
            setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskCollectionOriginalHeight != 0) {
              setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openCreateNewCollection === true) {
      if (divId === undefined) {
        if (openCreateNewCollectionOriginalWidth != 0) {
          var oldWidth = openCreateNewCollectionOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenCreateNewCollectionOriginalWidth(newWidth);
            setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskHierarchyOriginalHeight != 0) {
              setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openGlobalStandard === true) {
      if (divId === undefined) {
        if (openGlobalStandardOriginalWidth != 0) {
          var oldWidth = openGlobalStandardOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenGlobalStandardOriginalWidth(newWidth);
            setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth) / 2);
            if (openGlobalStandardOriginalHeight != 0) {
              setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openCustomStandards === true) {
      if (divId === undefined) {
        if (openCustomStandardsOriginalWidth != 0) {
          var oldWidth = openCustomStandardsOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenCustomStandardsOriginalWidth(newWidth);
            setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth) / 2);
            if (openCustomStandardsOriginalHeight != 0) {
              setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openRiskHierarchy === true) {
      if (divId === undefined) {
        if (openRiskHierarchyOriginalWidth != 0) {
          var oldWidth = openRiskHierarchyOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenRiskHierarchyOriginalWidth(newWidth);
            setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskHierarchyOriginalHeight != 0) {
              setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (centreMaintenance === true) {
      if (divId === undefined) {
        if (openCentreMaintenanceOriginalWidth != 0) {
          var oldWidth = openCentreMaintenanceOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenCentreMaintenanceOriginalWidth(newWidth);
            setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth) / 2);
            if (openCentreMaintenanceOriginalHeight != 0) {
              setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (opensliderMethods === true) {
      if (divId === undefined) {
        if (opensliderMethodsOriginalWidth != 0) {
          var oldWidth = opensliderMethodsOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpensliderMethodsOriginalWidth(newWidth);
            setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth) / 2);
            if (opensliderMethodsOriginalHeight != 0) {
              setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openAddColor === true) {
      if (divId === undefined) {
        if (openAddColorOriginalWidth != 0) {
          var oldWidth = openAddColorOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenAddColorOriginalWidth(newWidth);
            setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAddColorOriginalHeight != 0) {
              setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }
    if (openEditCollection === true) {
      if (divId === undefined) {
        if (openEditCollectionOriginalWidth != 0) {
          var oldWidth = openEditCollectionOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenEditCollectionOriginalWidth(newWidth);
            setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openEditCollectionOriginalHeight != 0) {
              setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openTransferQuestionnaire === true) {
      if (divId === undefined) {
        if (openTransferQuestionnaireOriginalWidth != 0) {
          var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenTransferQuestionnaireOriginalWidth(newWidth);
            setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth) / 2);
            if (openTransferQuestionnaireOriginalHeight != 0) {
              setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openAddQuestion === true) {
      if (divId === undefined) {
        if (openAddQuestionOriginalWidth != 0) {
          var oldWidth = openAddQuestionOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenAddQuestionOriginalWidth(newWidth);
            setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAddQuestionOriginalHeight != 0) {
              setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openAnswerScale === true) {
      if (divId === undefined) {
        if (openAnswerScaleOriginalWidth != 0) {
          var oldWidth = openAnswerScaleOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenAnswerScaleOriginalWidth(newWidth);
            setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAnswerScaleOriginalHeight != 0) {
              setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openQuestionerFromDashBord === true) {
      if (divId === undefined) {
        if (openQuestionerFromDashBordOriginalWidth != 0) {
          var oldWidth = openQuestionerFromDashBordOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenQuestionerFromDashBordOriginalWidth(newWidth);
            setOpenQuestionerFromDashBordOriginalLeft(openQuestionerFromDashBordOriginalLeft + (oldWidth - newWidth) / 2);
            if (openQuestionerFromDashBordOriginalHeight != 0) {
              setOpenQuestionerFromDashBordOriginalHeight(openQuestionerFromDashBordOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openEvaluaterRisk === true) {
      if (divId === undefined) {
        if (openEvaluaterRiskOriginalWidth != 0) {
          var oldWidth = openEvaluaterRiskOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenEvaluaterRiskOriginalWidth(newWidth);
            setOpenEvaluaterRiskOriginalLeft(openEvaluaterRiskOriginalLeft + (oldWidth - newWidth) / 2);
            if (openEvaluaterRiskOriginalHeight != 0) {
              setOpenEvaluaterRiskOriginalHeight(openEvaluaterRiskOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

    if (openRiskRegister === true) {
      if (divId === undefined) {
        if (openRiskRegisterOriginalWidth != 0) {
          var oldWidth = openRiskRegisterOriginalWidth.toString();
          var newWidth = oldWidth * 110 / 100;
          if (((newWidth / width) * 100) < 180) {
            setOpenRiskRegisterOriginalWidth(newWidth);
            setOpenRiskRegisterOriginalLeft(openRiskRegisterOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskRegisterOriginalHeight != 0) {
              setOpenRiskRegisterOriginalHeight(openRiskRegisterOriginalHeight.toString() * 110 / 100)
            }
          }
        }
      }
    }

  }


  function divMinusFuction(divId) {

    if (divId === 'divOpenComment') {
      var obj = document.getElementById("divOpenComment");
      if (openCommentOriginalWidth != 0) {
        var oldWidth = openCommentOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenCommentOriginalWidth(newWidth);
          setOpenCommentOriginalLeft(openCommentOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCommentOriginalHeight != 0) {
            setOpenCommentOriginalHeight(openCommentOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenRiskHierarchy') {
      var obj = document.getElementById("divOpenRiskHierarchy");
      if (openRiskHierarchyOriginalWidth != 0) {
        var oldWidth = openRiskHierarchyOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenRiskHierarchyOriginalWidth(newWidth);
          setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskHierarchyOriginalHeight != 0) {
            setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenCreateNewCollection') {
      var obj = document.getElementById("divOpenRiskHierarchy");
      if (openCreateNewCollectionOriginalWidth != 0) {
        var oldWidth = openCreateNewCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenCreateNewCollectionOriginalWidth(newWidth);
          setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCreateNewCollectionOriginalHeight != 0) {
            setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenGlobalStandard') {

      if (openGlobalStandardOriginalWidth != 0) {
        var oldWidth = openGlobalStandardOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenGlobalStandardOriginalWidth(newWidth);
          setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth) / 2);
          if (openGlobalStandardOriginalHeight != 0) {
            setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenCustomStandards') {

      if (openCustomStandardsOriginalWidth != 0) {
        var oldWidth = openCustomStandardsOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenCustomStandardsOriginalWidth(newWidth);
          setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCustomStandardsOriginalHeight != 0) {
            setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenCentreMaintenance') {

      if (openCentreMaintenanceOriginalWidth != 0) {
        var oldWidth = openCentreMaintenanceOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenCentreMaintenanceOriginalWidth(newWidth);
          setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth) / 2);
          if (openCentreMaintenanceOriginalHeight != 0) {
            setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenSliderMethods') {

      if (opensliderMethodsOriginalWidth != 0) {
        var oldWidth = opensliderMethodsOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpensliderMethodsOriginalWidth(newWidth);
          setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth) / 2);
          if (opensliderMethodsOriginalHeight != 0) {
            setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenAddColor') {

      if (openAddColorOriginalWidth != 0) {
        var oldWidth = openAddColorOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenAddColorOriginalWidth(newWidth);
          setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAddColorOriginalHeight != 0) {
            setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divOpenEditCollection') {

      if (openEditCollectionOriginalWidth != 0) {
        var oldWidth = openEditCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenEditCollectionOriginalWidth(newWidth);
          setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openEditCollectionOriginalHeight != 0) {
            setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }


    if (divId === 'divOpenRiskCollection') {

      if (openRiskCollectionOriginalWidth != 0) {
        var oldWidth = openRiskCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenRiskCollectionOriginalWidth(newWidth);
          setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskCollectionOriginalHeight != 0) {
            setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 90 / 100)
          }
        }
      }

    }


    if (divId === 'divOpenTransferQuestionnaire') {

      if (openTransferQuestionnaireOriginalWidth != 0) {
        var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenTransferQuestionnaireOriginalWidth(newWidth);
          setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth) / 2);
          if (openTransferQuestionnaireOriginalHeight != 0) {
            setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    /////////////

    if (divId === 'divAddQuestion') {

      if (openAddQuestionOriginalWidth != 0) {
        var oldWidth = openAddQuestionOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenAddQuestionOriginalWidth(newWidth);
          setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAddQuestionOriginalHeight != 0) {
            setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divAnswerScale') {

      if (openAnswerScaleOriginalWidth != 0) {
        var oldWidth = openAnswerScaleOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenAnswerScaleOriginalWidth(newWidth);
          setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth) / 2);
          if (openAnswerScaleOriginalHeight != 0) {
            setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }
    if (divId === 'divOpenQuestionerFromDashBord') {

      if (openQuestionerFromDashBordOriginalWidth != 0) {
        var oldWidth = openQuestionerFromDashBordOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenQuestionerFromDashBordOriginalWidth(newWidth);
          setOpenQuestionerFromDashBordOriginalLeft(openQuestionerFromDashBordOriginalLeft + (oldWidth - newWidth) / 2);
          if (openQuestionerFromDashBordOriginalHeight != 0) {
            setOpenQuestionerFromDashBordOriginalHeight(openQuestionerFromDashBordOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divEvaluaterRisk') {

      if (openEvaluaterRiskOriginalWidth != 0) {
        var oldWidth = openEvaluaterRiskOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenEvaluaterRiskOriginalWidth(newWidth);
          setOpenEvaluaterRiskOriginalLeft(openEvaluaterRiskOriginalLeft + (oldWidth - newWidth) / 2);
          if (openEvaluaterRiskOriginalHeight != 0) {
            setOpenEvaluaterRiskOriginalHeight(openEvaluaterRiskOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }

    if (divId === 'divRiskRegister') {

      if (openRiskRegisterOriginalWidth != 0) {
        var oldWidth = openRiskRegisterOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenRiskRegisterOriginalWidth(newWidth);
          setOpenRiskRegisterOriginalLeft(openRiskRegisterOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskRegisterOriginalHeight != 0) {
            setOpenRiskRegisterOriginalHeight(openRiskRegisterOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }


    if (openRiskCollection === true) {
      if (divId === undefined) {
        var oldWidth = openRiskCollectionOriginalWidth.toString();
        var newWidth = oldWidth * 90 / 100;
        if (((newWidth / width) * 100) > 30) {
          setOpenRiskCollectionOriginalWidth(newWidth);
          setOpenRiskCollectionOriginalLeft(openRiskCollectionOriginalLeft + (oldWidth - newWidth) / 2);
          if (openRiskCollectionOriginalHeight != 0) {
            setOpenRiskCollectionOriginalHeight(openRiskCollectionOriginalHeight.toString() * 90 / 100)
          }
        }
      }
    }
    if (openCreateNewCollection === true) {
      if (divId === undefined) {
        if (openCreateNewCollectionOriginalWidth != 0) {
          var oldWidth = openCreateNewCollectionOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenCreateNewCollectionOriginalWidth(newWidth);
            setOpenCreateNewCollectionOriginalLeft(openCreateNewCollectionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openCreateNewCollectionOriginalHeight != 0) {
              setOpenCreateNewCollectionOriginalHeight(openCreateNewCollectionOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (openGlobalStandard === true) {
      if (divId === undefined) {
        if (openGlobalStandardOriginalWidth != 0) {
          var oldWidth = openGlobalStandardOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenGlobalStandardOriginalWidth(newWidth);
            setOpenGlobalStandardOriginalLeft(openGlobalStandardOriginalLeft + (oldWidth - newWidth) / 2);
            if (openGlobalStandardOriginalHeight != 0) {
              setOpenGlobalStandardOriginalHeight(openGlobalStandardOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (openCustomStandards === true) {
      if (divId === undefined) {
        if (openCustomStandardsOriginalWidth != 0) {
          var oldWidth = openCustomStandardsOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenCustomStandardsOriginalWidth(newWidth);
            setOpenCustomStandardsOriginalLeft(openCustomStandardsOriginalLeft + (oldWidth - newWidth) / 2);
            if (openCustomStandardsOriginalHeight != 0) {
              setOpenCustomStandardsOriginalHeight(openCustomStandardsOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (openRiskHierarchy === true) {
      if (divId === undefined) {
        if (openRiskHierarchyOriginalWidth != 0) {
          var oldWidth = openRiskHierarchyOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenRiskHierarchyOriginalWidth(newWidth);
            setOpenRiskHierarchyOriginalLeft(openRiskHierarchyOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskHierarchyOriginalHeight != 0) {
              setOpenRiskHierarchyOriginalHeight(openRiskHierarchyOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (centreMaintenance === true) {
      if (divId === undefined) {
        if (openCentreMaintenanceOriginalWidth != 0) {
          var oldWidth = openCentreMaintenanceOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenCentreMaintenanceOriginalWidth(newWidth);
            setOpenCentreMaintenanceOriginalLeft(openCentreMaintenanceOriginalLeft + (oldWidth - newWidth) / 2);
            if (openCentreMaintenanceOriginalHeight != 0) {
              setOpenCentreMaintenanceOriginalHeight(openCentreMaintenanceOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (opensliderMethods === true) {
      if (divId === undefined) {
        if (opensliderMethodsOriginalWidth != 0) {
          var oldWidth = opensliderMethodsOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpensliderMethodsOriginalWidth(newWidth);
            setOpensliderMethodsOriginalLeft(opensliderMethodsOriginalLeft + (oldWidth - newWidth) / 2);
            if (opensliderMethodsOriginalHeight != 0) {
              setOpensliderMethodsOriginalHeight(opensliderMethodsOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (openAddColor === true) {
      if (divId === undefined) {
        if (openAddColorOriginalWidth != 0) {
          var oldWidth = openAddColorOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenAddColorOriginalWidth(newWidth);
            setOpenAddColorOriginalLeft(openAddColorOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAddColorOriginalHeight != 0) {
              setOpenAddColorOriginalHeight(openAddColorOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }
    if (openEditCollection === true) {
      if (divId === undefined) {
        if (openEditCollectionOriginalWidth != 0) {
          var oldWidth = openEditCollectionOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenEditCollectionOriginalWidth(newWidth);
            setOpenEditCollectionOriginalLeft(openEditCollectionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openEditCollectionOriginalHeight != 0) {
              setOpenEditCollectionOriginalHeight(openEditCollectionOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (openTransferQuestionnaire === true) {
      if (divId === undefined) {
        if (openTransferQuestionnaireOriginalWidth != 0) {
          var oldWidth = openTransferQuestionnaireOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenTransferQuestionnaireOriginalWidth(newWidth);
            setOpenTransferQuestionnaireOriginalLeft(openTransferQuestionnaireOriginalLeft + (oldWidth - newWidth) / 2);
            if (openTransferQuestionnaireOriginalHeight != 0) {
              setOpenTransferQuestionnaireOriginalHeight(openTransferQuestionnaireOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (openAddQuestion === true) {
      if (divId === undefined) {
        if (openAddQuestionOriginalWidth != 0) {
          var oldWidth = openAddQuestionOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenAddQuestionOriginalWidth(newWidth);
            setOpenAddQuestionOriginalLeft(openAddQuestionOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAddQuestionOriginalHeight != 0) {
              setOpenAddQuestionOriginalHeight(openAddQuestionOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (openAnswerScale === true) {
      if (divId === undefined) {
        if (openAnswerScaleOriginalWidth != 0) {
          var oldWidth = openAnswerScaleOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenAnswerScaleOriginalWidth(newWidth);
            setOpenAnswerScaleOriginalLeft(openAnswerScaleOriginalLeft + (oldWidth - newWidth) / 2);
            if (openAnswerScaleOriginalHeight != 0) {
              setOpenAnswerScaleOriginalHeight(openAnswerScaleOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (openEvaluaterRisk === true) {
      if (divId === undefined) {
        if (openEvaluaterRiskOriginalWidth != 0) {
          var oldWidth = openEvaluaterRiskOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenEvaluaterRiskOriginalWidth(newWidth);
            setOpenEvaluaterRiskOriginalLeft(openEvaluaterRiskOriginalLeft + (oldWidth - newWidth) / 2);
            if (openEvaluaterRiskOriginalHeight != 0) {
              setOpenEvaluaterRiskOriginalHeight(openEvaluaterRiskOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }

    if (openRiskRegister === true) {
      if (divId === undefined) {
        if (openRiskRegisterOriginalWidth != 0) {
          var oldWidth = openRiskRegisterOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenRiskRegisterOriginalWidth(newWidth);
            setOpenRiskRegisterOriginalLeft(openRiskRegisterOriginalLeft + (oldWidth - newWidth) / 2);
            if (openRiskRegisterOriginalHeight != 0) {
              setOpenRiskRegisterOriginalHeight(openRiskRegisterOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }


    if (openQuestionerFromDashBord === true) {
      if (divId === undefined) {
        if (openQuestionerFromDashBordOriginalWidth != 0) {
          var oldWidth = openQuestionerFromDashBordOriginalWidth.toString();
          var newWidth = oldWidth * 90 / 100;
          if (((newWidth / width) * 100) > 30) {
            setOpenQuestionerFromDashBordOriginalWidth(newWidth);
            setOpenQuestionerFromDashBordOriginalLeft(openQuestionerFromDashBordOriginalLeft + (oldWidth - newWidth) / 2);
            if (openQuestionerFromDashBordOriginalHeight != 0) {
              setOpenQuestionerFromDashBordOriginalHeight(openQuestionerFromDashBordOriginalHeight.toString() * 90 / 100)
            }
          }
        }
      }
    }



  }

  function closeDiv(divId) {
    if (divId === "divOpenEditCollection") {
      setOpenEditCollection(false);
      setOpenEditCollectionOriginalRelatedHeight(relatedDivOriginalHeight);
      enabledOtherDivs();

    }
  }




  function handleDmgGptClick(url) {
    if (menuNotAvailableMeg != "") {
      alert(menuNotAvailableMeg);
      return;
    }
    let multiOpen = sessionStorage.getItem('sessionstoragemultiopen');
    if (multiOpen != null && multiOpen === 'YES') {
      let text = "Exit multitasking menu?";
      if (window.confirm(text) == true) {
        sessionStorage.setItem('sessionstoragemultiopen', 'NO');
        let a = document.createElement('a');
        a.target = '_self';
        a.href = url;
        a.click();
      }

    } else {
      let a = document.createElement('a');
      a.target = '_self';
      a.href = url;
      a.click();
    }
  }

  function handleDmgGptClickResearch(url) {
    if (gptTextArray.length === 0) {
      alert("You have not provided any messages. Please type your message")
      return;
    }

    let multiOpen = sessionStorage.getItem('sessionstoragemultiopen');
    if (multiOpen != null && multiOpen === 'YES') {
      let text = "Exit multitasking menu?";
      if (window.confirm(text) == true) {
        sessionStorage.setItem('sessionstoragemultiopen', 'NO');
        let a = document.createElement('a');
        a.target = '_self';
        a.href = url;
        a.click();
      }

    } else {
      let a = document.createElement('a');
      a.target = '_self';
      a.href = url;
      a.click();
    }



  }


  const [openImage, setOpenImage] = React.useState(false);
  const [imageHtmlData, setImageHtmlData] = React.useState('');

  function handleCloseClick() {
    setOpenImage(false)
  }

  setTimeout(() => {

    var imgTags = document.getElementsByTagName('img');
    for (var i = 0; i < imgTags.length; i++) {
      imgTags[i].onclick = function () {
        var str = "<img src=\"" + this.src + "\" />";
        setImageHtmlData(str)
        setOpenImage(true);
      };
      imgTags[i].onmouseover = function () {
        this.style.cursor = 'pointer';
      };
    }
  }, 1000);


  const [openDisplayChoice, setOpenDisplayChoice] = React.useState(false);
  const handleCloseDisplayChoice = () => {
    setOpenDisplayChoice(false);
  };

  const wordstyle = 'wordStyle'
  const [openRiskGpt, setOpenRiskGpt] = React.useState(false);
  const [openRiskGptScroll, setOpenRiskGptScroll] = React.useState('paper');
  const [gptTextArray, setGptTextArray] = React.useState([]);

  function openRiskGptFunction() {
    setGptTextArray([])
    setOpenRiskGpt(true)
    setOpenRiskGptScroll('paper')
    setOpenDisplayChoice(false);
  }

  const [gptText, setGptText] = React.useState('');
  const handleChangeGptText = (e) => {
    setGptText(e.target.value);
    if (e.target.value != '') {
      document.getElementById('btnSend').style.color = 'white';
      document.getElementById('btnSend').style.backgroundColor = 'black';
    } else {
      document.getElementById('btnSend').style.color = 'black'
      document.getElementById('btnSend').style.backgroundColor = 'aliceblue'
    }
  };

  function formatDate1(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, '0');     // Get current hour (00-23)
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Get current minutes (00-59)
    const seconds = String(date.getSeconds()).padStart(2, '0'); // Get current seconds (00-59)
    return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;

  }

  const [answerCount, setAnswerCount] = React.useState(0);

  const saveTheResult1 = () => {
    if (gptTextArray.length === 0) {
      alert("No answers available")
      return;
    }
    var ans = "";
    gptTextArray.forEach((item, index) => {
      if ((index + 1) === gptTextArray.length) {
        ans = item.answer;
      }
    })
    
    const lines = ans.split("\n");
  

    const paragraphs = [];
  
    lines.forEach((line) => {
      if (line.trim().startsWith("###")) {
        const newText = line.replace("###", "").trim();
        const newText1 = newText.replace("**", "").trim()
  
        const newText2 = newText1.replace(/\*\*$/, "").trim(); 
    
        console.log(newText2);
        paragraphs.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: newText2,
                        bold: true,
                        size: 32, 
                    }),
                ],
                spacing: { after: 200 }, 
            })
        );
    } else if (line.trim() !== "") {
        const newText = line.replace("#", "").trim();
        const textParts = newText.split(/\*\*/); 
        const textRuns = [];
  
        textParts.forEach((part, index) => {
      
          if (part.trim() !== "") { 
            console.log(part)
            textRuns.push(
              new TextRun({
                text: part.replace("**", "").trim(),
                bold: index % 2 !== 0, 
                size: 24, 
              })
            );
          }
        });
       // const newText = textRuns.replace("###", "").trim()
        
        paragraphs.push(
          
          new Paragraph({
            children: textRuns,
            spacing: { after: 200 }, 
          })
        );
      }


    });


    const doc = new Document({
      sections: [
        {
          children: paragraphs,
        },
      ],
    });
  
    // Generate the docx file and save it
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `answer-${formatDate(new Date())}.docx`);
    });
  }

  const saveTheResult = () => {

    if (gptTextArray.length === 0) {
      alert("No answers available")
      return;
    }
    var ans = "";
    gptTextArray.forEach((item, index) => {
      if ((index + 1) === gptTextArray.length) {
        ans = item.answer;
      }
    })

    const doc = new jsPDF();
    const lines = ans.split('\n');
    let y = 22;
    const lineHeight = 7;
  
    lines.forEach(line => {
      if (line.trim().startsWith('###')) {
        doc.setFont('times', 'bold'); 
        doc.setFontSize(18); 
        line = line.replace('###', '').trim(); 
      } else {
        doc.setFont('times', 'normal');
        doc.setFontSize(13); 
      }
  
      if (line.includes('**')) {
        doc.setFont('times', 'bold'); 
        line = line.replace(/\*\*/g, ''); 
      }
  
      const textLines = doc.splitTextToSize(line, 180);
      textLines.forEach(txt => {
        doc.text(txt, 14, y); 
        y += lineHeight; 

        if (y > doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 22; 
        }
      });
    });

    doc.save(`answer-${formatDate(new Date())}.pdf`);

  };

  function resetGtp() {
    setGptTextArray([])
    setGptText('')
  }

  const [messageId, setMessageId] = React.useState(1);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formatContent = (text) => {
    // Split the content by newlines
    const textStr = text.replace(/\n/g, '@H'); // 4 spaces for each "#"
    console.log(textStr)
    const allText = textStr.replace(/\#\#\#(.*?)\@\H\@\H/g, (match, p1) => {
      //return `<span style={{color: 'red'}}>${p1}</span>@H@H`;
      return `<strong style="color: teal;font-size: 20px;"><u>${p1}</u></strong>@H@H`;
    });
    console.log(allText)
    return allText.split('@H').map((line, index) => {
      // Replace "#" with tabs (indentation using non-breaking space &nbsp;)
      const tabbedLine1 = line.replace(/#/g, ''); // 4 spaces for each "#"
      const tabbedLine2 = tabbedLine1.replace(/\-\-\-/g, ''); // 4 spaces for each "#"
      //const tabbedLine = tabbedLine2.replace(/-/g, '\u00A0\u00A0\u00A0-'); // 4 spaces for each "#"
      // Replace **bold** and remove the surrounding ** marks
      const formattedLine = tabbedLine2.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

      // Return as HTML content, dangerouslySetInnerHTML is used to allow HTML like <strong>
      return (
        <div key={index} dangerouslySetInnerHTML={{ __html: formattedLine }}></div>
      );
    });
  };


  function sentToChatGtp() {
    var obj = {}
    obj["userId"] = centreId;
    obj["createdBy"] = name;
    console.log(centreId + "-" + name)

    var objArr = [];

    var obj1 = {}
    obj1["messageId"] = formatDate(new Date()) + "-" + messageId;
    setMessageId(messageId + 1);
    obj1["message"] = gptText;
    objArr.push(obj1);
    obj["chats"] = objArr
    var sendJson = JSON.stringify(obj)
    setLoading1(true)

    axios(CONFIG.MAIN_URL + '/openai/advancechat', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: sendJson,
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        var obj = {};
        obj["question"] = gptText;
        var ans = response.data.answer.replaceAll('\n', '<br>')
        obj["answer"] = (response.data.answer);
        setGptTextArray([...gptTextArray, obj]);
        setGptText('')
        setLoading1(false)
        document.getElementById('btnSend').style.color = 'black'
        document.getElementById('btnSend').style.backgroundColor = 'aliceblue'

      })
      .catch(function (error) {
        console.log(error);
        setLoading1(false)
        alert("Error saving Question -:" + error);
      });


  }










  const [gptUserInput1, setGptUserInput1] = React.useState('');
  const handleChangeGptUserInput1 = (e) => {
    setGptUserInput1(e.target.value);
  };

  const [gptUserInputUrl, setGptUserInputUrl] = React.useState('');
  const handleChangeGptUserInputUrl = (e) => {
    setGptUserInputUrl(e.target.value);
  };

  const [inputUrlArray, setInputUrlArray] = React.useState([]);
  const validateUrl = async () => {
    try {
      const response = await fetch(gptUserInputUrl, { method: 'HEAD' });
      if (response.ok) {
        var foundUrl = false;
        if (inputUrlArray.length > 0) {
          inputUrlArray.forEach((item, index) => {
            if (item.name === gptUserInputUrl) {
              foundUrl = true;
            }
          })
        }
        if (foundUrl) {
          alert("You have provided this url before. Please enter different Url");
          return;
        }

        var obj = {};
        obj["name"] = gptUserInputUrl;
        setInputUrlArray([...inputUrlArray, obj]);
        setGptUserInputUrl('')
      } else {
        alert("Not responding from the provided Url. Please check your Url")
      }
    } catch (error) {
      alert("Invalid Url. Please check your Url")
    }
  };




  return (
    <div >
      <div style={
        pageBackgroundUrl !== '' ?
          {
            backgroundSize: 'cover',
            //backgroundImage: `linear-gradient(0deg, rgb(63 54 80), rgb(240 232 239 / 0%)), url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`, 
            backgroundRepeat: 'no-repeat',
            backgroundImage: `linear-gradient( 0deg , rgb(63 54 80), rgb(240 232 239 / 0%)),url(${pageBackgroundUrl})`,
          }
          :
          (pageBackgroundColor !== '' ?
            {
              backgroundSize: 'cover',
              //backgroundImage: `linear-gradient(0deg, rgb(63 54 80), rgb(240 232 239 / 0%)), url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`, 
              backgroundRepeat: 'no-repeat',
              backgroundColor: `${pageBackgroundColor}`,
            }
            :
            {
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(0deg, rgb(63 54 80), rgb(240 232 239 / 0%)), url(https://assets.kpmg/is/image/kpmg/pointing-digital-screen:cq5dam.web.512.341)`,
              backgroundRepeat: 'no-repeat'
            }
          )
      }>
        <div>
          <div class="scrollable-container-main">
            <div class="scrollable-content">

              <Grid container>
                <Grid item xs={12}>

                  <Container >

                    {(userRoleId === "volunteer" || userLoggedIn || !CONFIG.EXPERT_LOGIN) && !displayMultitaskingMenuInHome ?
                      <Grid container spacing={2} className={classes.pageBodyStyleDB}>


                        <Grid item xs={12} id="tabId1">
                          <div style={{ textAlign: 'left', marginTop: '70px' }}  >
                            <Card >
                              <CardContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {dbContentDisplay ?
                                      <>
                                        {pageTopHtml != '' ?
                                          <div style={{ minHeight: '400px', marginTop: '0px' }}>

                                            {parseHtml(pageTopHtml)}
                                          </div>
                                          :
                                          <div >
                                            {displayTrialingMsg != '' ?
                                              <div>
                                                <h3 className={classes.topHeaderText1DB} style={{ color: 'red' }}>{displayTrialingMsg}</h3>
                                              </div>
                                              :
                                              ''
                                            }
                                            <div>
                                              <h3 className={classes.topHeaderText1DB}>What is risk management and why is it important?</h3>
                                            </div>
                                            <div className='mt-4'>
                                              <div >

                                                <p style={{ fontSize: '15px' }}>
                                                  Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                                                  It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                                                  and the board of directors, to easily assess and mitigate risks.
                                                </p>
                                                <p style={{ fontSize: '15px' }}>
                                                  The system allows users to either ask questions about a specific risk or define a risk and its hierarchy, providing a comprehensive and flexible
                                                  solution for organizations of all sizes.
                                                </p>
                                                <p style={{ fontSize: '15px' }}>
                                                  With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                                                </p>
                                              </div>
                                              <Grid container spacing={2}>
                                                <Grid item xs={1}>
                                                  &nbsp;
                                                </Grid>
                                                <Grid item xs={10}>
                                                  <div style={{ marginTop: '20px' }}>
                                                    <img src={imgdash} alt='image' style={{ width: '100%', margin: '0px auto' }} />
                                                  </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                  &nbsp;
                                                </Grid>
                                              </Grid>

                                              {/* <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Risk management is the process of identifying, assessing and controlling threats to an organization's capital and earnings. These risks stem from a variety of sources including financial uncertainties, legal liabilities, technology issues, strategic management errors, accidents and natural disasters.
                                                A successful risk management program helps an organization consider the full range of risks it faces. Risk management also examines the relationship between risks and the cascading impact they could have on an organization's strategic goals.
                                                This holistic approach to managing risk is sometimes described as enterprise risk management because of its emphasis on anticipating and understanding risk across an organization. In addition to a focus on internal and external threats, enterprise risk management (ERM) emphasizes the importance of managing positive risk. Positive risks are opportunities that could increase business value or, conversely, damage an organization if not taken. Indeed, the aim of any risk management program is not to eliminate all risk but to preserve and add to enterprise value by making smart risk decisions.
                                                "We don't manage risks so we can have no risk. We manage risks so we know which risks are worth taking, which ones will get us to our goal, which ones have enough of a payout to even take them," said Forrester Research senior analyst Alla Valente, a specialist in governance, risk and compliance.
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thus, a risk management program should be intertwined with organizational strategy. To link them, risk management leaders must first define the organization's risk appetite -- i.e., the amount of risk it is willing to accept to realize its objectives.
                                                The formidable task is to then determine "which risks fit within the organization's risk appetite and which require additional controls and actions before they are acceptable," explained Notre Dame University Senior Director of IT Mike Chapple in his article on risk appetite vs. risk tolerance. Some risks will be accepted with no further action necessary. Others will be mitigated, shared with or transferred to another party, or avoided altogether.
                                                Every organization faces the risk of unexpected, harmful events that can cost it money or cause it to close. Risks untaken can also spell trouble, as the companies disrupted by born-digital powerhouses, such as Amazon and Netflix, will attest. This guide to risk management provides a comprehensive overview of the key concepts, requirements, tools, trends and debates driving this dynamic field. Throughout, hyperlinks connect to other TechTarget articles that deliver in-depth information on the topics covered here, so readers should be sure to click on them to learn more. 
                                            </p> */}
                                            </div>
                                          </div>

                                        }
                                      </>
                                      :
                                      ''
                                    }


                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <Grid container spacing={2} className={classes.pageBodyStyleDB}>
                        <Grid item xs={12} id="topBtnId">

                          <div className='topBtnStyle'>
                            <div style={{ gap: '10px', display: 'flex' }}>
                              <Button
                                id='btnMultiOpen'
                                variant="outlined"
                                size="medium"
                                onClick={handleClick1}
                                className={'tabBtnDB'}
                                aria-controls={open1 ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                              >
                                Manual Multitasking
                              </Button>
                              <Menu
                                anchorEl={anchorEl1}
                                id="account-menu"
                                open={open1}
                                onClose={handleClose1}
                                onClick={handleClose1}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              >
                                <MenuItem onClick={() => setOpenRiskCollectionFuction(!openRiskCollection)}>
                                  Risk Collection (completed)
                                </MenuItem>
                                <MenuItem onClick={setOpenCreateNewCollectionFuction}>
                                  Create New Collection
                                </MenuItem>
                                <MenuItem onClick={setOpenRiskHierarchyFuction}>
                                  Create Risk Hierarchy
                                </MenuItem>
                                <MenuItem onClick={setOpenCustomStandardFuction}>
                                  Custom Standards
                                </MenuItem>
                                <MenuItem onClick={setOpenAddQuestionFuction}>
                                  Create Questions
                                </MenuItem>
                              </Menu>



                              {/**                 
                            <Button 
                              id='btnOpenRiskCollection'
                                variant="outlined" 
                                size="medium" 
                                className={(isActiveBtnOpenRiskCollection ? 'tabBtnDB' : classes.divDisabled)}
                                onClick={()=>setOpenRiskCollectionFuction(!openRiskCollection)} 
                                >
                                Risk Collection (completed)
                            </Button>
                            <Button id='btnOpenCreateNewCollection' variant="outlined" size="medium" 
                              className={(isActiveBtnOpenCreateNewCollection ? 'tabBtnDB' : classes.divDisabled)}
                              onClick={ setOpenCreateNewCollectionFuction}
                              >
                                Create New Collection
                            </Button>
                            <Button id='btnOpenCreateNewCollection' variant="outlined" size="medium" 
                              className={(isActiveBtnOpenCreateNewCollection ? 'tabBtnDB' : classes.divDisabled)}
                              onClick={ setOpenRiskHierarchyFuction}
                              >
                                Create Risk Hierarchy
                            </Button>
                            <Button id='btnOpenCreateNewCollection' variant="outlined" size="medium" 
                              className={(isActiveBtnOpenCreateNewCollection ? 'tabBtnDB' : classes.divDisabled)}
                              onClick={ setOpenAddQuestionFuction}
                              >
                                Create Questions
                            </Button>
                            */}

                              <Button variant="outlined" size="medium"
                                className={(isActiveBtnOpenCreateNewCollection ? 'tabBtnDB' : classes.divDisabled)}
                                onClick={() => setOpenDisplayChoice(true)}
                              >
                                Risk GPT
                              </Button>
                              {/** 
                                <Button id='btnOpenCreateNewCollection' variant="outlined" size="medium" 
                                  className={(isActiveBtnOpenCreateNewCollection ? classes.tabBtnDB : classes.divDisabled)}
                                  onClick={ setOpenRiskRegisterFuction}
                                  >
                                    Create/Edit Risk Register
                                </Button>
                                */}
                              {/*</Link>*/}
                            </div>

                            {
                              isActiveMenuIcon ?
                                <div style={{ marginTop: '-15px' }}>
                                  <React.Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                      <div style={{ gap: '10px', display: 'flex' }}>
                                        <Tooltip title="Multitasking Menu">
                                          <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 4 }}
                                            //style={{marginTop: '-12px'}}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                          >
                                            <Avatar sx={{ width: 32, height: 32 }} style={{ color: '#4C7EE0 ' }}><MoreVertIcon style={{ color: '#4C7EE0' }} /></Avatar>
                                          </IconButton>
                                        </Tooltip>

                                      </div>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                      <div style={{ gap: '10px', display: 'flex' }}>
                                        <div style={{ color: '#F5F5F5 !important' }}>
                                          <Tooltip title="Maximise All Multitasking Menu"><Avatar sx={{ width: 32, height: 32 }} style={{ color: '#4C7EE0 !important', cursor: 'pointer' }} onClick={() => divPlusFuction()}><AddCircleOutlineIcon style={{ color: '#4C7EE0' }} /></Avatar></Tooltip>
                                        </div>
                                        <div>
                                          <Tooltip title="Minimise All Multitasking Menu"><Avatar sx={{ width: 32, height: 32 }} style={{ color: '#4C7EE0 ', cursor: 'pointer' }} onClick={() => divMinusFuction()}><RemoveCircleOutlineIcon style={{ color: '#4C7EE0' }} /></Avatar></Tooltip>
                                        </div>
                                        <div style={{ marginTop: '-5px', marginLeft: '-7px' }}>
                                          <Tooltip title="Reset Multitasking Menu">
                                            <IconButton
                                              onClick={() => refreshLayersFuction()}
                                              size="small"
                                              sx={{ ml: 0 }}
                                              aria-controls={open ? 'account-menu' : undefined}
                                              aria-haspopup="true"
                                              aria-expanded={open ? 'true' : undefined}
                                            >
                                              <Avatar sx={{ width: 32, height: 32 }} style={{ color: '#4C7EE0 ' }}><Refresh style={{ color: '#4C7EE0' }} /></Avatar>
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </div>


                                    </Box>
                                    <Menu
                                      anchorEl={anchorEl}
                                      id="account-menu"
                                      open={open}
                                      onClose={handleClose}
                                      onClick={handleClose}
                                      PaperProps={{
                                        elevation: 0,
                                        sx: {
                                          overflow: 'visible',
                                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                          mt: 1.5,
                                          '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                          },
                                          '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                          },
                                        },
                                      }}
                                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                      {/**    
                               <MenuItem onClick={ setOpenRiskRegisterFuction }>
                                Create/Edit Risk Register
                                </MenuItem>
                               */}
                                      <MenuItem onClick={() => setOpenRiskCollectionFuction(!openRiskCollection)}>
                                        Risk Collection
                                      </MenuItem>
                                      <MenuItem onClick={setOpenCreateNewCollectionFuction}>
                                        Questionnaire Collections
                                      </MenuItem>
                                      <MenuItem onClick={setOpenGlobalStandardFuction}>
                                        Global Standards
                                      </MenuItem>
                                      <MenuItem onClick={setOpenCustomStandardFuction}>
                                        Custom Standards
                                      </MenuItem>
                                      <MenuItem onClick={setOpenRiskHierarchyFuction}>
                                        Risk Hierarchy
                                      </MenuItem>
                                      <MenuItem onClick={setOpenCentreMaintenanceFuction}>
                                        Centre Maintenance
                                      </MenuItem>
                                      <MenuItem onClick={setOpenSliderMethodsFuction}>
                                        Risk Slider Methods
                                      </MenuItem>
                                      <MenuItem onClick={setOpenAddColorFuction}>
                                        Risk Slider Colors
                                      </MenuItem>
                                      <MenuItem onClick={setOpenTransferQuestionnaireFuction}>
                                        Transfer Questionnaire Collection
                                      </MenuItem>
                                      <MenuItem onClick={setOpenAddQuestionFuction}>
                                        Create/Edit Question
                                      </MenuItem>
                                      <MenuItem onClick={setOpenAnswerScaleFuction}>
                                        Create/Edit Answer Scale
                                      </MenuItem>
                                      <MenuItem onClick={setOpenEvaluaterRiskFuction}>
                                        Evaluate Risk
                                      </MenuItem>

                                      {/** 
                                <MenuItem onClick={ setOpenQuestionerFromDashBord }>
                                  Create/Edit Questioners(Temp)
                                </MenuItem>
                                */}
                                    </Menu>



                                  </React.Fragment>







                                  {/* <Box sx={{ minWidth: 320 }} >
                            <FormControl style={{float:'right'}}>
                            <InputLabel id="demo-simple-select-label" >Select Component</InputLabel>
                              <Select
                                style={{width:'200px'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                              >
                                <MenuItem value={1} onClick={ setOpenExistingRiskHierarchyFuction}>Risk Hierarchy</MenuItem>
                                <MenuItem value={1} onClick={ setOpenGlobalStandardFuction}>Global Standards</MenuItem>
                                
                              </Select>
                            </FormControl>
                          </Box> */}
                                </div>
                                :
                                <div >


                                  <React.Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

                                      <Tooltip title="">
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 2 }}
                                          aria-controls={open ? 'account-menu' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={open ? 'true' : undefined}
                                        >
                                          <Avatar sx={{ width: 32, height: 32 }}><MoreVertIcon /></Avatar>
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </React.Fragment>
                                </div>


                            }



                          </div>

                          <div className='topBtnStyle4n'>
                            <Button variant="outlined" size="medium"
                              className={(isActiveBtnOpenCreateNewCollection ? 'tabBtnDB' : classes.divDisabled)}
                              onClick={() => handleDmgGptClick('/questionsforgeneratingriskregister')}
                            >
                              Risk GPT
                            </Button>
                          </div>
                        </Grid>

                        {/* malindu sectiuon */}

                        <Grid item xs={12} className='pageTopPos' >
                          <Breadcrumbs aria-label="breadcrumb" className='breadcrumbStyle' >
                            <Typography color="text.primary">Home</Typography>
                          </Breadcrumbs>
                        </Grid>

                        <Grid item xs={12} id="tabId1">
                        </Grid>

                        <Grid item xs={12} id="divOpenComment" className={isActiveDivOpenComment ? '' : 'divDisabled'} onClick={e => divActiveFuction('divOpenComment')} style={{ marginTop: + `${openCommentPos}` + 'px', zIndex: `${openCommentZIndex}` }}>
                          <Card
                            style={{
                              marginRadius: '10px',
                              width: `${openCommentOriginalWidth}` + 'px',
                              //height: `${openCommentOriginalHeight}` + 'px', 
                              overflowY: 'scroll',
                              marginLeft: `${openCommentOriginalLeft}` + 'px',
                              backgroundColor: `${backgroundclrmain_page}`,
                              border: `1px solid ${borderclrmain_page}`,
                              borderTopLeftRadius: `${radiusTopLeftmain_page}` + 'px',
                              borderTopRightRadius: `${radiusTopRightmain_page}` + 'px',
                              borderBottomLeftRadius: `${radiusBottomLeftmain_page}` + 'px',
                              borderBottomRightRadius: `${rradiusBottomRightmain_page}` + 'px'


                            }}
                            className='openCommentHeight'
                          >
                            <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '10px', padding: '7px', marginTop: '-10px', color: '#57544A' }}>
                              <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenComment')} className='commentPlusMinus' />
                              <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenComment')} className='commentPlusMinus' />
                            </Typography>
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>

                                  {dbContentDisplay ?
                                    <>

                                      {pageTopHtml != '' ?


                                        <Grid container spacing={2}>

                                          {displayTrialingMsg != '' ?
                                            <Grid item xs={12} >
                                              <h3 className={classes.topHeaderText1DB} style={{ color: 'red' }}>{displayTrialingMsg}</h3>
                                            </Grid>
                                            :
                                            ''
                                          }

                                          <Grid item xs={12} >

                                            <div >
                                              {parseHtml(pageTopHtml)}
                                            </div>
                                          </Grid>

                                        </Grid>



                                        :

                                        <>
                                          {displayTrialingMsg != '' ?
                                            <div>
                                              <h3 className={classes.topHeaderText3DB} style={{ color: 'red' }}>{displayTrialingMsg}</h3>
                                            </div>
                                            :
                                            ''
                                          }

                                          <div>
                                            <h3 className='topHeaderText1Move'>What is risk management and why is it important?</h3>

                                          </div>
                                          <div className='mt-4'>
                                            <div >

                                              <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                                                Data Management Group’s (DMG) Risk Assessment system provides a user-friendly approach to risk management.
                                                It allows individuals responsible for managing risks, such as senior management, risk and compliance teams, finance teams, IT teams,
                                                and the board of directors, to easily assess and mitigate risks.
                                              </p>
                                              <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                                                The system allows users to either <span style={{ color: '#4C7EE0', cursor: 'pointer', fontSize: '15px' }} onClick={setOpenAddQuestionFuction}>ask questions</span>  about a specific risk or define a <span style={{ color: '#4C7EE0', cursor: 'pointer', fontSize: '15px' }} onClick={setOpenRiskHierarchyFuction}>risk and its hierarchy,</span>  providing a comprehensive and flexible
                                                solution for organizations of all sizes.
                                              </p>
                                              <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                                                With the DMG system, entities within an organization can fulfill their responsibilities in assessing and managing risks effectively and efficiently.
                                              </p>
                                            </div>
                                            <Grid container spacing={2}>
                                              <Grid item xs={1}>
                                                &nbsp;
                                              </Grid>
                                              <Grid item xs={10}>
                                                <div style={{ marginTop: '20px' }}>
                                                  <img src={imgdash} alt='image' style={{ width: '100%', margin: '0px auto' }} />
                                                </div>
                                              </Grid>
                                              <Grid item xs={2}>
                                                &nbsp;
                                              </Grid>
                                            </Grid>
                                            {/* <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Risk management is the process of identifying, assessing and controlling threats to an organization's capital and earnings. These risks stem from a variety of sources including financial uncertainties, legal liabilities, technology issues, strategic management errors, accidents and natural disasters.
                                                A successful risk management program helps an organization consider the full range of risks it faces. Risk management also examines the relationship between risks and the cascading impact they could have on an organization's strategic goals.
                                                This holistic approach to managing risk is sometimes described as enterprise risk management because of its emphasis on anticipating and understanding risk across an organization. In addition to a focus on internal and external threats, enterprise risk management (ERM) emphasizes the importance of managing positive risk. Positive risks are opportunities that could increase business value or, conversely, damage an organization if not taken. Indeed, the aim of any risk management program is not to eliminate all risk but to preserve and add to enterprise value by making smart risk decisions.
                                                "We don't manage risks so we can have no risk. We manage risks so we know which risks are worth taking, which ones will get us to our goal, which ones have enough of a payout to even take them," said Forrester Research senior analyst Alla Valente, a specialist in governance, risk and compliance.
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thus, a risk management program should be intertwined with organizational strategy. To link them, risk management leaders must first define the organization's risk appetite -- i.e., the amount of risk it is willing to accept to realize its objectives.
                                                The formidable task is to then determine "which risks fit within the organization's risk appetite and which require additional controls and actions before they are acceptable," explained Notre Dame University Senior Director of IT Mike Chapple in his article on risk appetite vs. risk tolerance. Some risks will be accepted with no further action necessary. Others will be mitigated, shared with or transferred to another party, or avoided altogether.
                                                Every organization faces the risk of unexpected, harmful events that can cost it money or cause it to close. Risks untaken can also spell trouble, as the companies disrupted by born-digital powerhouses, such as Amazon and Netflix, will attest. This guide to risk management provides a comprehensive overview of the key concepts, requirements, tools, trends and debates driving this dynamic field. Throughout, hyperlinks connect to other TechTarget articles that deliver in-depth information on the topics covered here, so readers should be sure to click on them to learn more. 
                                            </p> */}
                                          </div>
                                        </>

                                      }
                                    </>
                                    :
                                    ''
                                  }

                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>


                        <Grid item xs={12} id="tabId2" style={{ height: `${openRiskCollectionOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openRiskCollection ?
                            <Draggable>
                              <Grid id='divOpenRiskCollection' className={isActiveDivOpenRiskCollection ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenRiskCollection')} style={{ marginTop: + `${topBtnBottomPos}` + 'px', zIndex: `${openRiskCollectionZIndex}`, position: 'relative' }}>
                                <Card style={{ marginRadius: '10px', width: `${openRiskCollectionOriginalWidth}` + 'px', height: `${openRiskCollectionOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openRiskCollectionOriginalLeft}` + 'px', backgroundColor: `${backgroundclrrisk_collection}`, border: `1px solid ${borderclrrisk_collection}`, borderTopLeftRadius: `${radiusTopLeftrisk_collection}` + 'px', borderTopRightRadius: `${radiusTopRightrisk_collection}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftrisk_collection}` + 'px', borderBottomRightRadius: `${rradiusBottomRightrisk_collection}` + 'px' }}>
                                  <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                    <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenRiskCollection')} style={{ cursor: 'pointer' }} />
                                    <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenRiskCollection')} style={{ cursor: 'pointer' }} />
                                    <HighlightOffIcon onClick={() => setOpenRiskCollectionCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                  </Typography>
                                  <CardContent>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrrisk_collection}` }}>{userLoggedIn ? "My Risk Collection" : "Risk Collection Questionnaires"}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <Tooltip title={helpTextrisk_collection}>
                                            <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                          </Tooltip>

                                        </span></Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Button
                                          variant="contained"
                                          className={classes.leftPageNumberStyle}
                                        >
                                          {openRiskCollectionOrder}
                                        </Button>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Button
                                          variant="contained"
                                          className={classes.rightPageNumberStyle}
                                        >
                                          {openRiskCollectionOrder}
                                        </Button>
                                      </Grid>
                                      <Grid item xs={7}>
                                        <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#460303' }}>Multitasking Menu - {userLoggedIn ? "My Risk Collection" : "Risk Collection Questionnaires"}</Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          onClick={e => reloadAll(e)}
                                          style={{ float: 'right', marginRight: '82px', marginTop: '-34px' }}
                                        >Reload latest Updates
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                          {
                                            allCollectionData.map((collection) => {
                                              return (
                                                <Grid item xs={3}>
                                                  <Card className={classes.collectionCardDB}>
                                                    <CardContent className={classes.collectionCardContentDB}>
                                                      <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.collectionCardTitleDB}>{collection.title}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.collectionCardDetailsDB}>({"Status: " + collection.status + ", Q - " + collection.questions.length})</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} className={classes.alignCenterDB}>
                                                        {/*<Link id={collection.id} to={`/editcollection/${collection.collectionUniqueName}`}>*/}
                                                        <Button variant="outlined"
                                                          className={classes.btnBorderAshDB}
                                                          onClick={e => setOpenCreateNewCollectionFuctionWithEdit(collection.collectionUniqueName)}
                                                        >
                                                          Edit
                                                        </Button>&nbsp;&nbsp;
                                                        {/*</Link>*/}
                                                        <Button variant="outlined" className={classes.btnBorderAshDB}
                                                          onClick={e => setOpenCollectionDataFuction(true, collection.id, collection.title)}
                                                        >
                                                          Evaluate
                                                        </Button>
                                                      </Grid>
                                                      {userLoggedIn ?
                                                        <Grid item xs={12} className={classes.alignCenterDB}>
                                                          <Link id={collection.id} to={`/questionManagementExpert/${collection.collectionUniqueName}_expert`}>
                                                            <Button variant="outlined" className={classes.btnBorderAshDBTop3}
                                                            >
                                                              Expert Evaluation
                                                            </Button>
                                                          </Link>
                                                        </Grid>
                                                        :
                                                        ''
                                                      }
                                                    </CardContent>
                                                  </Card>
                                                </Grid>

                                              )
                                            })
                                          }
                                        </Grid>


                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Draggable>
                            : null
                        }
                        <Grid item xs={12} id="tabId6" style={{ height: `${openCreateNewCollectionOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openCreateNewCollection &&
                          <Draggable>
                            <Grid id='divOpenCreateNewCollection' className={isActiveDivOpenCreateNewCollection ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCreateNewCollection')} style={{ marginTop: + `${openCreateNewCollectionPos}` + 'px', zIndex: `${openCreateNewCollectionZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openCreateNewCollectionOriginalWidth}` + 'px', height: `${openCreateNewCollectionOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openCreateNewCollectionOriginalLeft}` + 'px', backgroundColor: `${backgroundclrrisk_collection}`, border: `1px solid ${borderclrquestionnaire_collections}`, borderTopLeftRadius: `${radiusTopLeftquestionnaire_collections}` + 'px', borderTopRightRadius: `${radiusTopRightquestionnaire_collections}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftquestionnaire_collections}` + 'px', borderBottomRightRadius: `${rradiusBottomRightquestionnaire_collections}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenCreateNewCollection')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenCreateNewCollection')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenCreateNewCollectionCloseFunction(false)} style={{ cursor: 'pointer' }} /></Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrquestionnaire_collections}` }}>Create/Edit Questionnaire Collection                         &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextquestionnaire_collections}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip></span>

                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openCreateNewCollectionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openCreateNewCollectionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#4907dc' }}>Multitasking Menu - Create/Edit Questionnaire Collections</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <CollectionManagement
                                        openCreateNewCollectionWithColId={openCreateNewCollectionWithColId}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }
                        <Grid item xs={12} id="tabId5" style={{ height: `${openGlobalStandardOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openGlobalStandard &&
                          <Draggable>
                            <Grid id='divOpenGlobalStandard' className={isActiveDivOpenGlobalStandard ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenGlobalStandard')} style={{ marginTop: + `${openGlobalStandardPos}` + 'px', zIndex: `${openGlobalStandardZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openGlobalStandardOriginalWidth}` + 'px', height: `${openGlobalStandardOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openGlobalStandardOriginalLeft}` + 'px', backgroundColor: `${backgroundclglobal_standards}`, border: `1px solid ${borderclrglobal_standards}`, borderTopLeftRadius: `${radiusTopLeftglobal_standards}` + 'px', borderTopRightRadius: `${radiusTopRightglobal_standards}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftglobal_standards}` + 'px', borderBottomRightRadius: `${rradiusBottomRightglobal_standards}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenGlobalStandard')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenGlobalStandard')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenGlobalStandardCloseFunction(false)} style={{ cursor: 'pointer' }} /></Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrglobal_standards}` }}>Create/Edit Global Standards
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextglobal_standards}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openGlobalStandardOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openGlobalStandardOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#0742f1' }}>Multitasking Menu - Create/Edit Global Standards</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <GlobalStandard
                                        setFromGlobalStandardPage={setFromGlobalStandardPage}
                                        setMainDivX={setMainDivX}
                                        setMainDivY={setMainDivY}
                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }

                        <Grid item xs={12} id="tabId10" style={{ height: `${openCustomStandardsOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openCustomStandards &&
                          <Draggable>
                            <Grid id='divOpenCustomStandards' className={isActiveDivOpenCustomStandards ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCustomStandards')} style={{ marginTop: + `${customStandardsPos}` + 'px', zIndex: `${openCustomStandardsZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openCustomStandardsOriginalWidth}` + 'px', height: `${openCustomStandardsOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openCustomStandardsOriginalLeft}` + 'px', backgroundColor: `${backgroundclrcustom_standards}`, border: `1px solid ${borderclrcustom_standards}`, borderTopLeftRadius: `${radiusTopLeftcustom_standards}` + 'px', borderTopRightRadius: `${radiusTopRightcustom_standards}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftcustom_standards}` + 'px', borderBottomRightRadius: `${rradiusBottomRightcustom_standards}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenCustomStandards')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenCustomStandards')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenCustomStandardsCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrcustom_standards}` }}>Create/Edit Custom Standards
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextcustom_standards}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openCustomStandardsOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openCustomStandardsOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#f606a3' }}>Multitasking Menu - Create/Edit Custom Standards</Typography>
                                    </Grid>

                                    <Grid item xs={12}>

                                      <CustomStandardsComponent
                                        setFromCustomStandardPage={setFromCustomStandardPage}
                                        setMainDivXC={setMainDivXC}
                                        setMainDivYC={setMainDivYC}
                                      />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>

                        }
                        <Grid item xs={12} id="tabId3" style={{ height: `${openRiskHierarchyOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openRiskHierarchy &&
                          <Draggable>
                            <Grid id='divOpenRiskHierarchy' className={isActiveDivOpenRiskHierarchy ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenRiskHierarchy')} style={{ marginTop: + `${openExistingRiskHierarchyPos}` + 'px', zIndex: `${openRiskHierarchyZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openRiskHierarchyOriginalWidth}` + 'px', height: `${openRiskHierarchyOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openRiskHierarchyOriginalLeft}` + 'px', borderTopRightRadius: `${radiusTopRightrisk_hierarchy}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftrisk_hierarchy}` + 'px', borderBottomRightRadius: `${rradiusBottomRightrisk_hierarchy}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenRiskHierarchy')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenRiskHierarchy')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenRiskHierarchyCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${borderclrrisk_chierarchy}` }}>Create / Edit a Risk Hierarchy
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextrisk_hierarchy}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openRiskHierarchyOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openRiskHierarchyOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#06f6f6' }}>Multitasking Menu - Create / Edit a Risk Hierarchy</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <RiskHierarchyNew />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }
                        <Grid item xs={12} id="tabId7" style={{ height: `${openCentreMaintenanceOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          centreMaintenance &&
                          <Draggable>
                            <Grid id='divOpenCentreMaintenance' className={isActiveDivOpenCentreMaintenance ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCentreMaintenance')} style={{ marginTop: + `${openCentreMaintenancePos}` + 'px', zIndex: `${openCentreMaintenanceZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openCentreMaintenanceOriginalWidth}` + 'px', height: `${openCentreMaintenanceOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openCentreMaintenanceOriginalLeft}` + 'px', backgroundColor: `${backgroundclrcentre_maintenance}`, border: `1px solid ${borderclrcentre_maintenance}`, borderTopLeftRadius: `${radiusTopLeftcentre_maintenance}` + 'px', borderTopRightRadius: `${radiusTopRightcentre_maintenance}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftcentre_maintenance}` + 'px', borderBottomRightRadius: `${rradiusBottomRightcentre_maintenance}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenCentreMaintenance')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenCentreMaintenance')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setCentreMaintenanceCloseFunction(false)} style={{ cursor: 'pointer' }} /></Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrcentre_maintenance}` }}>Centre Maintenance
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextquestionnaire_collections}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openCentreMaintenanceOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openCentreMaintenanceOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#06f664' }}>Multitasking Menu - Centre Maintenance</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <CenterMaintainersComponent />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }

                        <Grid item xs={12} id="tabId8" style={{ height: `${opensliderMethodsOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          opensliderMethods &&
                          <Draggable>
                            <Grid id='divOpenSliderMethods' className={isActiveDivOpenSliderMethods ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenSliderMethods')} style={{ marginTop: + `${opensliderMethodsPos}` + 'px', zIndex: `${openSliderMethodsZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${opensliderMethodsOriginalWidth}` + 'px', height: `${opensliderMethodsOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${opensliderMethodsOriginalLeft}` + 'px', backgroundColor: `${backgroundclrisk_slider_methods}`, border: `1px solid ${borderclrrisk_slider_methods}`, borderTopLeftRadius: `${radiusTopLeftrisk_slider_methods}` + 'px', borderTopRightRadius: `${radiusTopRightrisk_slider_methods}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftrisk_slider_methods}` + 'px', borderBottomRightRadius: `${rradiusBottomRightrisk_slider_methods}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenSliderMethods')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenSliderMethods')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpensliderMethodsCloseFunction(false)} style={{ cursor: 'pointer' }} /></Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrrisk_slider_methods}` }}>Create / Edit Slider Methods
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextrisk_slider_methods}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>

                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {opensliderMethodsOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {opensliderMethodsOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#89f606' }}>Multitasking Menu - Create / Edit Slider Methods</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <SliderMethodsComponent />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }

                        <Grid item xs={12} id="tabId9" style={{ height: `${openAddColorOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openAddColor &&
                          <Draggable>
                            <Grid id='divOpenAddColor' className={isActiveDivOpenAddColor ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAddColor')} style={{ marginTop: + `${opeAddColorPos}` + 'px', zIndex: `${openAddColorZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openAddColorOriginalWidth}` + 'px', height: `${openAddColorOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openAddColorOriginalLeft}` + 'px', backgroundColor: `${backgroundclRSC}`, border: `1px solid ${borderclrRSC}`, borderTopLeftRadius: `${radiusTopLeftRSC}` + 'px', borderTopRightRadius: `${radiusTopRightRSC}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftRSC}` + 'px', borderBottomRightRadius: `${rradiusBottomRightRSC}` + 'px' }}>

                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenAddColor')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenAddColor')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenAddColorCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrRSC}` }}>Create / Edit Slider Colors
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextRSC}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openAddColorOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openAddColorOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#f6c506' }}>Multitasking Menu - Create / Edit Slider Colors</Typography>
                                    </Grid>

                                    <Grid item xs={12}>

                                      <AddColorComponent />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }



                        <Grid item xs={12} id="tabId12" style={{ height: `${openEditCollectionOriginalRelatedHeight}` + 'px' }}>
                        </Grid>
                        {
                          openEditCollection &&
                          <Draggable>
                            <Grid id='divOpenEditCollection' className={isActiveDivOpenEditCollection ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenEditCollection')} style={{ marginTop: + `${openEditCollectionPos}` + 'px', zIndex: `${openEditCollectionZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openEditCollectionOriginalWidth}` + 'px', height: `${openEditCollectionOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openEditCollectionOriginalLeft}` + 'px', backgroundColor: `${backgroundclCEQ}`, border: `1px solid ${borderclrCEQ}`, borderTopLeftRadius: `${radiusTopLeftCEQ}` + 'px', borderTopRightRadius: `${radiusTopRightCEQ}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftCEQ}` + 'px', borderBottomRightRadius: `${rradiusBottomRightCEQ}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenEditCollection')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenEditCollection')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenEditCollectionCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrCEQ}` }}>Edit Questionnaire Collections
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextCEQ}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openEditCollectionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openEditCollectionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#f66b06' }}>Multitasking Menu - Edit Questionnaire Collections</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <CollectionManagementEdit
                                        openCreateNewCollectionWithColIdWhenEdit={openCreateNewCollectionWithColIdWhenEdit}
                                        formDashBord={true}
                                        setFromCollectionManagementEditPage={setFromCollectionManagementEditPage}
                                        setFromEditPageCollectionRiskId={setFromEditPageCollectionRiskId}
                                        setFromEditPageCollectionRiskTypeId={setFromEditPageCollectionRiskTypeId}
                                        setFromEditPageRiskTypeName={setFromEditPageRiskTypeName}
                                        setFromEditPageRiskLevel={setFromEditPageRiskLevel}
                                        setFromEditPageRiskLevelCategoryId={setFromEditPageRiskLevelCategoryId}
                                        setFromEditPageRiskLevelMethodId={setFromEditPageRiskLevelMethodId}
                                        setFromEditPageRiskAppetite={setFromEditPageRiskAppetite}
                                        setFromEditPageRiskAppetiteCategoryId={setFromEditPageRiskAppetiteCategoryId}
                                        setFromEditPageRiskAppetiteMethodId={setFromEditPageRiskAppetiteMethodId}
                                        setFromEditPageQuestionId={setFromEditPageQuestionId}

                                      />
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }

                        <Grid item xs={12} id="tabId14" style={{ height: `${openTransferQuestionnaireOriginalRelatedHeight}` + 'px' }}>
                        </Grid>


                        {
                          openTransferQuestionnaire &&
                          <Draggable>
                            <Grid id='divOpenTransferQuestionnaire' className={isActiveDivOpenTransferQuestionnaire ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenCustomStandards')} style={{ marginTop: + `${openTransferQuestionnairePos}` + 'px', zIndex: `${openTransferQuestionnaireZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openTransferQuestionnaireOriginalWidth}` + 'px', height: `${openTransferQuestionnaireOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openTransferQuestionnaireOriginalLeft}` + 'px', backgroundColor: `${backgroundclTQC}`, border: `1px solid ${borderclrTQC}`, borderTopLeftRadius: `${radiusTopLeftTQC}` + 'px', borderTopRightRadius: `${radiusTopRightTQC}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftTQC}` + 'px', borderBottomRightRadius: `${rradiusBottomRightTQC}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenTransferQuestionnaire')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenTransferQuestionnaire')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenTransferQuestionnaireCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrTQC}` }}>Transfer Questionnaire Collections To end Users
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextTQC}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openTransferQuestionnaireOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openTransferQuestionnaireOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#f61c06' }}>Multitasking Menu - Transfer Questionnaire Collections</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <TransferCollectionComponent />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>

                        }

                        <Grid item xs={12} id="tabId15" style={{ height: `${openAddQuestionOriginalRelatedHeight}` + 'px' }}>
                        </Grid>

                        {
                          openAddQuestion &&
                          <Draggable>
                            <Grid id='divOpenAddQuestion' className={isActiveDivOpenAddQuestion ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAddQuestion')} style={{ marginTop: + `${openAddQuestionPos}` + 'px', zIndex: `${openAddQuestionZIndex}`, position: 'relative' }}>
                              <Card className={classes.cardDivMove} style={{ marginRadius: '10px', width: `${openAddQuestionOriginalWidth}` + 'px', height: `${openAddQuestionOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openAddQuestionOriginalLeft}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenAddQuestion')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenAddQuestion')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenAddQuestionCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px' }}>Create/Edit Question</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openAddQuestionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openAddQuestionOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#f61c06' }}>Multitasking Menu - Create/Edit Question</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <AddQuestionComponent />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }


                        <Grid item xs={12} id="tabId16" style={{ height: `${openAnswerScaleOriginalRelatedHeight}` + 'px' }}>
                        </Grid>

                        {
                          openAnswerScale &&
                          <Draggable>
                            <Grid id='divOpenAnswerScale' className={isActiveDivOpenAnswerScale ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenAnswerScale')} style={{ marginTop: + `${openAnswerScalePos}` + 'px', zIndex: `${openAnswerScaleZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openAnswerScaleOriginalWidth}` + 'px', height: `${openAnswerScaleOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openAnswerScaleOriginalLeft}` + 'px', backgroundColor: `${backgroundclCEAS}`, border: `1px solid ${borderclrCEAS}`, borderTopLeftRadius: `${radiusTopLeftCEAS}` + 'px', borderTopRightRadius: `${radiusTopRightCEAS}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftCEAS}` + 'px', borderBottomRightRadius: `${rradiusBottomRightCEAS}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenAnswerScale')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenAnswerScale')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenAnswerScaleCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrCEAS}` }}>Create/Edit Answer Scale
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextCEAS}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>
                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openAnswerScaleOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openAnswerScaleOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#93180C' }}>Multitasking Menu - Create/Edit AnswerScale</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <AddScaleComponent />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }



                        <Grid item xs={12} id="tabId17" style={{ height: `${openQuestionerFromDashBordOriginalRelatedHeight}` + 'px' }}>
                        </Grid>

                        {
                          openQuestionerFromDashBord &&
                          <Draggable>
                            <Grid id='divOpenQuestionerFromDashBord' className={isActiveDivOpenQuestionerFromDashBord ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenQuestionerFromDashBord')} style={{ marginTop: + `${openQuestionerFromDashBordPos}` + 'px', zIndex: `${openQuestionerFromDashBordZIndex}`, position: 'relative' }}>
                              <Card className={classes.cardDivMove} style={{ marginRadius: '10px', width: `${openQuestionerFromDashBordOriginalWidth}` + 'px', height: `${openQuestionerFromDashBordOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openQuestionerFromDashBordOriginalLeft}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenQuestionerFromDashBord')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenQuestionerFromDashBord')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenQuestionerFromDashBordCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px' }}>Add / Edit Questionnaire</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openQuestionerFromDashBordOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openQuestionerFromDashBordOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#93180C' }}>Multitasking Menu - Add / Edit Questionnaire</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <QuestionerPageFromDashBordComponent
                                        openCreateNewCollectionWithColIdFromDashBord={openCreateNewCollectionWithColIdWhenEdit}
                                        fromEditPageCollectionRiskIdFromDashBord={fromEditPageCollectionRiskIdTmp}
                                        fromEditPageCollectionRiskTypeIdFromDashBord={fromEditPageCollectionRiskTypeIdTmp}
                                        fromEditPageRiskTypeNameFromDashBord={fromEditPageRiskTypeNameTmp}
                                        fromEditPageRiskLevelFromDashBord={fromEditPageRiskLevelTmp}
                                        fromEditPageRiskLevelCategoryIdFromDashBord={fromEditPageRiskLevelCategoryIdTmp}
                                        fromEditPageRiskLevelMethodIdFromDashBord={fromEditPageRiskLevelMethodIdTmp}
                                        fromEditPageRiskAppetiteFromDashBord={fromEditPageRiskAppetiteTmp}
                                        fromEditPageRiskAppetiteCategoryIdFromDashBord={fromEditPageRiskAppetiteCategoryIdTmp}
                                        fromEditPageRiskAppetiteMethodIdFromDashBord={fromEditPageRiskAppetiteMethodIdTmp}
                                        fromEditPageQuestionIdFromDashBord={fromEditPageQuestionIdTmp}
                                      />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }



                        <Grid item xs={12} id="tabId18" style={{ height: `${openEvaluaterRiskOriginalRelatedHeight}` + 'px' }}>
                        </Grid>

                        {
                          openEvaluaterRisk &&
                          <Draggable>
                            <Grid id='divOpenEvaluaterRisk' className={isActiveDivOpenEvaluaterRisk ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenEvaluaterRisk')} style={{ marginTop: + `${openEvaluaterRiskPos}` + 'px', zIndex: `${openEvaluaterRiskZIndex}`, position: 'relative' }}>
                              <Card style={{ marginRadius: '10px', width: `${openEvaluaterRiskOriginalWidth}` + 'px', height: `${openEvaluaterRiskOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openEvaluaterRiskOriginalLeft}` + 'px', backgroundColor: `${backgroundclER}`, border: `1px solid ${borderclrER}`, borderTopLeftRadius: `${radiusTopLeftER}` + 'px', borderTopRightRadius: `${radiusTopRightER}` + 'px', borderBottomLeftRadius: `${radiusBottomLeftER}` + 'px', borderBottomRightRadius: `${rradiusBottomRightER}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenEvaluaterRisk')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenEvaluaterRisk')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenEvaluaterRiskCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px', color: `${headingFClrER}` }}>Evaluate Risk
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Tooltip title={helpTextER}>
                                          <span style={{ fontSize: '10px', color: ' black' }}><InfoIcon /></span>
                                        </Tooltip>

                                      </span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openEvaluaterRiskOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openEvaluaterRiskOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#93180C' }}>Multitasking Menu - Evaluate Risk</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <EvaluateRisk fromMultiTaskin={fromMultiTaskin} />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }

                        <Grid item xs={12} id="tabId19" style={{ height: `${openRiskRegisterOriginalRelatedHeight}` + 'px' }}>
                        </Grid>

                        {
                          openRiskRegister &&
                          <Draggable>
                            <Grid id='divOpenRiskRegister' className={isActiveDivOpenRiskRegister ? '' : classes.divDisabled} onClick={e => divActiveFuction('divOpenRiskRegister')} style={{ marginTop: + `${openRiskRegisterPos}` + 'px', zIndex: `${openRiskRegisterZIndex}`, position: 'relative' }}>
                              <Card className={classes.cardDivMove} style={{ marginRadius: '10px', width: `${openRiskRegisterOriginalWidth}` + 'px', height: `${openRiskRegisterOriginalHeight}` + 'px', overflowY: 'scroll', marginLeft: `${openRiskRegisterOriginalLeft}` + 'px' }}>
                                <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '23px', padding: '7px', marginTop: '-10px', color: 'rgba(87, 84, 74, 0.9);' }}>
                                  <RemoveCircleOutlineIcon onClick={() => divMinusFuction('divOpenRiskRegister')} style={{ cursor: 'pointer' }} />
                                  <AddCircleOutlineIcon onClick={() => divPlusFuction('divOpenRiskRegister')} style={{ cursor: 'pointer' }} />
                                  <HighlightOffIcon onClick={() => setOpenRiskRegisterCloseFunction(false)} style={{ cursor: 'pointer' }} />
                                </Typography>
                                <CardContent>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" className={classes.cardTitleMove} style={{ fontSize: '18px !important', marginTop: '-32px' }}><span style={{ borderStyle: 'groove', padding: '10px' }}>Create/Edit Risk Register</span></Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.leftPageNumberStyle}
                                      >
                                        {openRiskRegisterOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        className={classes.rightPageNumberStyle}
                                      >
                                        {openRiskRegisterOrder}
                                      </Button>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Typography variant="h6" className={classes.mutimenuPageHeadingStyle} style={{ color: '#93180C' }}>Multitasking Menu - Create/Edit Risk Register</Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: '-10px' }}>

                                      <CreateEditRiskRegister fromMultiTaskin={fromMultiTaskin} />


                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Draggable>
                        }




                      </Grid>




                    }



                  </Container>
                </Grid>
              </Grid>

            </div>
            <footer style={{ marginTop: '40px' }}>
              <Footer />
            </footer>
          </div>
        </div>
      </div>



      {/** Question Details Show Dialog */}
      <Dialog
        maxWidth='xs'
        open={questionDetailOpen}
        onClose={questionDetailHandleClickClose}
        scroll={questionDetailScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="h6" gutterBottom component="div">
            Question No - {quesDataCount}
          </Typography>
          <Typography component="th" scope="row" style={{ width: '100%', fontSize: '10px !important' }}>
            <span style={{ fontWeight: '550' }}>Question - </span>{selectedQuestion}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={questionDetailScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container spacing={2}>
              {selectedQuesData.map((data) => {
                var scoreColor = getRiskLvlColor(data.questionScore, data.questionScoreData.riskMethodId)
                return (
                  <Grid item xs={12}>
                    <Box >
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Detail Weightings
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                              <Typography variant="h6" component="div">
                                Score
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                              Questionnaire weighting
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                              <span style={{ color: scoreColor }}>{data.questionScore}</span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {data.questionType === "Closeended" && (
                        <>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                  <Typography variant="h6" gutterBottom component="div">
                                    Answers
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                  &nbsp;
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                                var riskLvlColor = getRiskLvlColorForAnswer(item.riskLevel, item.riskLevelData.riskMethodId, (item.riskLevelData.color != '' ? item.riskLevelData.color : item.riskLevelData.secondaryColor))
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                        <span >{item.answerName}</span>
                                      </TableCell>
                                      <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                        <span style={{ color: riskLvlColor }}>{item.riskLevel}</span>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </>
                      )}
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: '80%' }}>
                              <Typography variant="h6" gutterBottom component="div">
                                Model Answer(s)
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                              &nbsp;
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      {data.questionType === "Closeended" && (
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            {data.answers.answerMethod.name != "" && data.answers.answerList.length > 0 && data.answers.answerList.map((item) => {
                              var riskLvlColor = getRiskLvlColorForAnswer(item.riskLevel, item.riskLevelData.riskMethodId, (item.riskLevelData.color != '' ? item.riskLevelData.color : item.riskLevelData.secondaryColor))
                              if (item.expectedAnswerName != "") {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                        <span>
                                          {item.answerName}
                                        </span>
                                      </TableCell>
                                      <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                        <span style={{ color: riskLvlColor }}>{item.expectedAnswerName === "Yes" || item.expectedAnswerName === "checked" ? item.riskLevel : item.expectedAnswerName}</span>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              }
                            })}
                          </TableBody>
                        </Table>
                      )}
                      {data.questionType === "Openended" && (
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row" style={{ width: '80%' }}>
                                <span>
                                  {data.answers.answer}
                                </span>
                              </TableCell>
                              <TableCell component="th" scope="row" style={{ width: '20%', paddingLeft: '0px !important', paddingRight: '0px !important' }} align="right">
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={questionDetailHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Question Details Show Dialog*/}


      {/** Risk Evaluate Options */}
      <Dialog
        maxWidth='md'
        open={riskEvalTypeOpen}
        onClose={riskEvalTypeHandleClickClose}
        scroll={riskEvalTypeScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Select "All" or other Risk Type to Evaluate Risk <br></br>for {selectedCollectionEvaluateTitle}</DialogTitle>
        <DialogContent dividers={riskEvalTypeScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel id="radio-buttons-group-label">Pick your Risk Type</FormLabel>
              </Grid>
              <Grid item xs={12} style={{ "marginTop": "-17px !important" }}>
                <FormGroup>
                  <FormControlLabel className={classes.marginleftEval} control={<Checkbox id={"chkRiskType0"} />} label="All" />
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <TextField
                        label="Select a Risk Hierarchy"
                        sx={{ width: '100%' }}
                        select
                        value={hierarchyId}
                        onChange={handleChangeHierarchy}
                      >
                        {riskTypeData.map((item) => (
                          <MenuItem
                            value={item.collectionRiskId}
                          >
                            {item.name}
                          </MenuItem>
                        ))}

                      </TextField>
                    </FormControl>
                  </Box>

                  {
                    rsType.map((risktypp) => {
                      return (
                        <RiskEval
                          key={risktypp.collectionRiskTypeId}
                          comment={risktypp}
                        />
                      )
                    })
                  }



                  {/*mainRiskArr.map((item)=>{
                            return(
                                <FormControlLabel control={<Checkbox id={"chkRiskType" + item.riskTypeId}/>} label={item.name} />
                            )
                        })*/}
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  id="riskEvaluateBtn"
                  className={classes.btnBorderRedEval}
                  style={{ float: 'right' }}
                  //onClick={()=>setOpenRiskEvalFuction(!openRiskEval)}
                  onClick={() => setRiskTypeEvalFuction()}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={riskEvalTypeHandleClickClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/** End of Risk Evaluation Options*/}


      {/** Risk eval dialog */}

      <Dialog
        open={openCollectionData}
        onClose={false}
        scroll={openCollectionDataScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        PaperComponent={PaperComponentCollectionData}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>{selectedCollectionEvaluateTitle}
          </Typography>
          <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenCollectionData(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openCollectionDataScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginTop: '-17px', float: 'right' }}
                  onClick={() => {
                    evaluateAll()
                  }}
                >
                  Evaluate All Questions
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>

                  <Box
                    sx={{
                      height: 300,
                      width: '100%',
                      '& .super-app-theme--cell': {
                        whiteSpace: 'normal !important',
                        lineHeight: '1',
                      },
                      '& .super-app-theme--Open': {
                        fontSize: '10px !important',
                      },

                    }}
                  >





                    <div style={{ height: 400, width: '100%', backgroundColor: '#ffffff', fontSize: '9px !important' }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowClassName={`super-app-theme--Open`}
                        disableSelectionOnClick
                      />
                    </div>

                  </Box>
                </Grid>
              </Grid>


            </Grid>





          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCollectionData(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/** Collecton Amend  dialog */}


      <Dialog
        open={openAmendCollectionData}
        onClose={false}
        scroll={openAmendCollectionDataScroll}
        aria-labelledby="collection-amend-data-dialog-title"
        aria-describedby="collection-amend-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
        PaperComponent={PaperComponentCollectionAmendData}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-amend-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Questionnaire Collection</Typography>
          <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
            <Tooltip title="Close"><Close onClick={() => setAmendOpenCollectionData(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openAmendCollectionDataScroll === 'paper'}>
          <DialogContentText id="collection-amend-data-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CollectionManagementEdit
                  openCreateNewCollectionWithColId={openCreateNewCollectionWithColId}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAmendOpenCollectionData(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
















      {/************************ */}

      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={evaluateAllOpen}
        onClose={evaluateAllHandleClickClose}
        scroll={evaluateAllScroll}
        PaperComponent={PaperComponent}
        aria-labelledby="evaluateAll-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="h6" style={{ textAlign: 'right', marginTop: '-22px', marginRight: '-24px' }}><Tooltip title="Close"><Close onClick={() => setEvaluateAllOpen(false)} style={{ cursor: 'pointer' }} /></Tooltip></Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom component="div">
                Evaluation Results
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={questionDetailScroll === 'paper'}>
          <DialogContentText
            id="risk-eval-type-scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper sx={{ overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 370 }}>
                    <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell colSpan={5} style={{ textAlign: 'center', fontSize: '14px', borderBottomColor: 'transparent' }}>Questions</StyledTableCell>
                          <StyledTableCell colSpan={5} style={{ textAlign: 'center', fontSize: '14px' }}>Answers</StyledTableCell>
                        </TableRow>

                        <TableRow>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '4%' }}>No</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '40%' }}>Question</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Weight</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>No of Responses</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Total Weight</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Heighly Negative <br></br>(-5)</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Negative <br></br><br></br>(-2)</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Somewhat Positive <br></br>(1)</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Positive <br></br><br></br>(4)</StyledTableCell>
                          <StyledTableCell style={{ verticalAlign: 'top', width: '7%' }}>Heighly Positive <br></br>(8)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {closeEndedData.map((data, index) => {
                          return (
                            <>
                              <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row" style={{ fontSize: '12px' }}>
                                  {data.id}
                                </StyledTableCell>
                                <StyledTableCell style={{ fontSize: '12px' }}>{data.question}</StyledTableCell>
                                <StyledTableCell style={{ fontSize: '12px' }}>{data.questionWeigth}</StyledTableCell>
                                <StyledTableCell style={{ fontSize: '12px' }}>{data.noOfRespondedUsers}</StyledTableCell>
                                <StyledTableCell style={{ fontSize: '12px' }}>{data.totalQuestionWeigth}</StyledTableCell>
                                {data.answerRiskLevelList.map((item, index1) => {
                                  return (
                                    <>
                                      {index1 === 0 ?
                                        <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (-5 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                        :
                                        <>
                                          {index1 === 1 ?
                                            <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (-2 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                            :
                                            <>
                                              {index1 === 2 ?
                                                <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (1 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                :
                                                <>
                                                  {index1 === 3 ?
                                                    <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (4 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                    :
                                                    <>
                                                      {index1 === 4 ?
                                                        <StyledTableCell style={{ fontSize: '12px' }}>{item.totalRiskLevel === 0 ? '--' : item.totalRiskLevel + '(' + Math.round((100 * item.totalRiskLevel) / (8 * data.noOfRespondedUsers)) + '%)'}</StyledTableCell>
                                                        :
                                                        ''
                                                      }
                                                    </>
                                                  }
                                                </>
                                              }
                                            </>

                                          }
                                        </>
                                      }

                                    </>
                                  )
                                })}


                              </StyledTableRow>


                            </>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <StyledTableRow key={1} style={{ backgroundColor: 'black', color: 'white' }}>
                        <StyledTableCell align='right' colSpan={3} component="th" scope="row" style={{ color: 'white', fontWeight: '550', width: '51%' }}>
                          {'TOTAL'}
                        </StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalUsers}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalQuestionWeight}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer1 === 0 ? '--' : closeEndedTotal.totalAnswer1 + '(' + Math.round((100 * closeEndedTotal.totalAnswer1) / (-5 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer2 === 0 ? '--' : closeEndedTotal.totalAnswer2 + '(' + Math.round((100 * closeEndedTotal.totalAnswer2) / (-2 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer3 === 0 ? '--' : closeEndedTotal.totalAnswer3 + '(' + Math.round((100 * closeEndedTotal.totalAnswer3) / (1 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer4 === 0 ? '--' : closeEndedTotal.totalAnswer4 + '(' + Math.round((100 * closeEndedTotal.totalAnswer4) / (4 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: '550', width: '7%' }}>{closeEndedTotal.totalAnswer5 === 0 ? '--' : closeEndedTotal.totalAnswer5 + '(' + Math.round((100 * closeEndedTotal.totalAnswer5) / (8 * closeEndedTotal.totalUsers)) + '%)'}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>





              </Grid>
            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={evaluateAllHandleClickClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {/** End of Question Details Show Dialog*/}


      {/************************ */}

      {/** Question Amend Amend  dialog */}


      <Dialog
        open={openAmendQuestionFromEval}
        onClose={false}
        scroll={openAmendQuestionFromEvalScroll}
        aria-labelledby="collection-amend-data-dialog-title"
        aria-describedby="collection-amend-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      //PaperComponent={PaperComponentCollectionAmendData}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-amend-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Edit Question - {questionNameFromEval}</Typography>
          <Typography variant="h6" style={{ textAlign: 'right', marginTop: '', marginRight: '-25px', padding: '7px', marginTop: '-50px' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenAmendQuestionFromEvalFunction(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openAmendQuestionFromEvalScroll === 'paper'}>
          <DialogContentText id="collection-amend-data-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <QuestionerPageComponent
                  collectionIdFromEval={collectionIdFromEval}
                  questionIdFromEval={questionIdFromEval}
                  setOpenAmendQuestionFromEvalFunction={setOpenAmendQuestionFromEvalFunction}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAmendQuestionFromEvalFunction(false)}>Close</Button>
        </DialogActions>
      </Dialog>




      {/** Dialog Waiting */}
      <Dialog
        maxWidth={'md'}
        open={waitingOpen}
        PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
      >
        <CircularProgress disableShrink />
      </Dialog>



      <Dialog
        open={loading1}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center' }}>Please Wait ...</span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>


              <ClockLoader
                size={100}
                color={'red'}
                // className={classes.loaderStyle}
                loading={loading1}
              />

            </Grid>
          </Grid>


        </DialogContent>
      </Dialog>



      <Dialog
        open={openImage}
        //onClose={setOpenImage(false)}
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        aria-labelledby="draggable-dialog-title"

      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

        </DialogTitle>
        <DialogContent>
          {parseHtml(imageHtmlData)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseClick()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/** Risk Gpt register create Methods */}
      <Dialog
        open={openDisplayChoice}
        onClose={handleCloseDisplayChoice}>
        <DialogTitle>
          Select the Register/Heirarchy Create Method

        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            How do you want to create the Register/Heirarchy
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                component="label"
                onClick={() => openRiskGptFunction()}
                className='btnFonts'
                style={{ float: 'right' }}
              >
                Research
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                component="label"
                onClick={() => handleDmgGptClick('/questionsforgeneratingriskregister')}
                className='btnFonts'
                style={{ minWidth: '160px' }}
              >
                Create Register/Hierachy
              </Button>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisplayChoice}>Close</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openRiskGpt}
        onClose={false}
        scroll={openRiskGptScroll}
        aria-labelledby="collection-data-dialog-title"
        aria-describedby="collection-data-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle style={{ cursor: 'move' }} id="collection-data-dialog-title">
          <Typography variant="h6" style={{ fontSize: '25px !important' }}>Create Register / Hierachy - Using RiskGpt
          </Typography>
          <Typography variant="h6" style={{ marginRight: '-15px', padding: '7px', marginTop: '-50px', float: 'right' }}>
            <Tooltip title="Close"><Close onClick={() => setOpenRiskGpt(false)} style={{ cursor: 'pointer' }} /></Tooltip>
          </Typography>
        </DialogTitle>
        <DialogContent dividers={openRiskGptScroll === 'paper'}>
          <DialogContentText id="main-risk-scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>

              <Grid item xs={12} style={{ height: '400px', overflowY: 'scroll' }}>
                <Grid container spacing={1}>
                  {/**{gptTextArray.slice().reverse().map((item) => ( */}
                  {gptTextArray.map((item) => (
                    <>
                      <Grid item xs={1}>
                        <BootstrapTooltip title='Risk GPT'>
                          <Avatar sx={{ bgcolor: green[500], height: '25px', width: '25px', fontSize: '1.0rem !important' }} variant="square">
                            <span className={wordstyle}>Q</span>
                          </Avatar>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid item xs={11} style={{ backgroundColor: 'rgba(244,244,244)', borderRadius: '14px' }}>
                        {item.question}

                      </Grid>
                      <Grid item xs={1}>
                        <BootstrapTooltip title='Risk GPT'>
                          <Avatar sx={{ bgcolor: red[500], height: '25px', width: '25px', fontSize: '1.0rem !important' }} variant="square">
                            <span className={wordstyle}>A</span>
                          </Avatar>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid item xs={11}>

                        {/**<span style={{fontSize: '1rem', fontFamily: 'ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol'}}>{parseHtml(item.answer.replaceAll('\n', '<br>'))}</span>**/}
                        <span style={{ fontSize: '1rem', fontFamily: 'ui-sans-serif,-apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica,Apple Color Emoji,Arial,Segoe UI Emoji,Segoe UI Symbol' }}>{formatContent(item.answer)}</span>

                      </Grid>
                    </>
                  ))}



                </Grid>


              </Grid>
              <Grid item xs={11}>
                <Box component="form" sx={{ '& .MuiTextField-root': { width: '100%' }, }} noValidate autoComplete="off">
                  <textarea
                    placeholder="Type Your Message here..."
                    variant="outlined"
                    value={gptText}
                    id='gptText'
                    rows={3}
                    style={{ width: "100%" }}
                    onChange={handleChangeGptText}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}>

                <Button
                  id={'btnSend'}
                  variant="contained"
                  onClick={() => {
                    sentToChatGtp()
                  }}
                  //style={{ height: '55px', width: '40px', borderRadius: '50%', color: 'black', backgroundColor: 'aliceblue'  }}
                  sx={{
                    height: "55px",
                    width: "40px",
                    borderRadius: "50%",
                    color: "black",
                    backgroundColor: "aliceblue",
                    "&:hover": {
                      backgroundColor: "#8a8181",
                      color: "white",
                    },
                  }}
                >
                  <ArrowUpward />
                </Button>
              </Grid>


            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {gptTextArray.length > 0 ?
            <>
            <Button
              onClick={saveTheResult}
            >
              Save the Latest Result in PDF format
            </Button>
            &nbsp;&nbsp;
            <Button
            onClick={saveTheResult1}
          >
            Save the Latest Result in WORD(DOC) format
          </Button>
          </>
            :
            ''
          }
          <Button
            onClick={() => handleDmgGptClickResearch('/researchforgeneratingriskregister')}
          >
            Create Register/Heirarchy Using the Above Information
          </Button>
          <Button onClick={() => setOpenRiskGpt(false)}>Close</Button>


        </DialogActions>
      </Dialog>







    </div>
  )
}

export default Dashboard